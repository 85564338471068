import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '@unbooking/ui-auth';
import './styles.scss';

const NotFoundPage: FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <main className={`not-found-page ${!isAuthenticated ? 'not-found-page--full-height' : ''}`}>
      <div className="not-found-page__textbox">
        <h1>Sorry, you don&apos;t have permission to access this section at this time</h1>
        <hr />
        <p>
          You may want to head back to the homepage. <br /> If you think something is broken,
          contact us. (404)
        </p>
        <div className="buttons-container">
          <Link to="/" className="button-link">
            Go to Homepage
          </Link>
          <a href="mailto:noreply.humanitarianbooking@wfp.org" className="button-link">
            Contact us
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
