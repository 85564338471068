/* eslint-disable jsx-a11y/label-has-associated-control */
// TO DO: AsyncDropdownField should be deleted, use Dropdown
import React, { FC, useEffect } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useField } from 'informed';
import { ReactComponent as ArrowRoundIcon } from '@assets/svg/icon_arrow_round.svg';
import './styles.scss';

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IAsyncDropdownField {
  className?: string;
  field: string;
  icon?: any;
  defaultValue?: IDropdownOption;
  defaultOptions?: IDropdownOption[] | boolean;
  options?: IDropdownOption[];
  disabled?: boolean;
  label?: string;
  labelClassName?: string;
  placeholder?: string;
  noOptionsMessage?: string;
  onChange?: (value: IDropdownOption) => void;
  validate?: (value: string, values: any) => void | string;
  loadOptions?: () => Promise<IDropdownOption[]>;
  formatOptionLabel?: (props: any) => JSX.Element;
}

const AsyncDropdownField: FC<IAsyncDropdownField> = ({
  className,
  field,
  defaultValue,
  defaultOptions,
  options,
  disabled,
  placeholder,
  noOptionsMessage,
  onChange,
  validate,
  loadOptions,
  formatOptionLabel,
}) => {
  const {
    fieldState: { error, value },
    fieldApi: { setValue, setTouched },
    render,
    ref,
  } = useField({ field, validate } as any);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  const DropdownIndicator = (props: any): any => (
    <components.DropdownIndicator {...props}>
      <ArrowRoundIcon type="bottom" />
    </components.DropdownIndicator>
  );

  return render(
    <div className="async-select-wrapper">
      <AsyncSelect
        options={options}
        cacheOptions
        className={`async-select ${className}`}
        classNamePrefix="async-select"
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        ref={ref}
        formatOptionLabel={formatOptionLabel}
        value={(value as any) ?? ''}
        onFocus={() => setTouched(true)}
        onChange={(option: IDropdownOption) => {
          setValue(option);
          onChange?.(option);
        }}
        loadOptions={loadOptions}
        noOptionsMessage={() => noOptionsMessage ?? 'No options'}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
      {error ? <span className="async-select-error">{error as any}</span> : null}
    </div>,
  );
};

export default AsyncDropdownField;
