import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Spin, Button } from 'antd';
import { useAuth } from '@unbooking/ui-auth';
import { useParamsParse } from '@common/hooks';
import './styles.scss';

const SignInPage: FC = () => {
  const navigate = useNavigate();
  const { isParsed, userType } = useParamsParse();
  const { firstLoginAttempt, authorize } = useAuth();
  const [loaderSpinning, setLoaderSpinning] = useState(false);

  useEffect(() => {
    if (window.location.pathname !== '/' && window.location.pathname.includes('/requests/')) {
      localStorage.setItem('SUCCESS_REDIRECT_URL', window.location.pathname);
    }

    if (firstLoginAttempt) {
      setLoaderSpinning(true);

      if (isParsed) {
        authorize?.(userType);
      } else {
        navigate('/redirect');
      }
    }
  }, [authorize, firstLoginAttempt, isParsed, navigate, userType]);

  return (
    <section className="login-container">
      <div className="login-panel">
        <div className="row">
          <span className="login-greeting">
            Welcome to the <br />
            <strong>Freight Management</strong>
          </span>
        </div>
        <Spin className="login-loading" size="large" spinning={loaderSpinning} />
        {!firstLoginAttempt && (
          <Button
            className="login-link"
            type="link"
            onClick={() => {
              setLoaderSpinning(true);
              authorize?.(userType);
            }}
          >
            Sign in
          </Button>
        )}
      </div>
    </section>
  );
};

export default SignInPage;
