import { ICrumb } from '@ui-modules/types';
import { Location } from 'react-router-dom';
import {
  AssignmentsListPage,
  LettersPage,
  BatchDetailsPage,
  BatchListPage,
  EntryPage,
  GenerateLetterPage,
  LettersMenu,
  RequestsDetailsPage,
  RequestsListPage,
  SpotRequestsMenu,
  VendorsListPage,
  VendorDetailsPage,
  RequestsMenu,
  ReportsPage,
} from '@pages';
import backgroundImage from '@assets/img/default/background.jpg';

export default (location: Location, history: string[]) =>
  [
    {
      path: '/',
      Component: EntryPage,
      isBackgroundImage: true,
      backgroundImage,
    },

    // Requests
    {
      path: '/requests',
      Component: RequestsMenu,
      name: 'Freight Requests',
      isNavigation: true,
      isBackgroundImage: true,
      backgroundImage,
    },
    {
      path: '/requests/spot',
      Component: SpotRequestsMenu,
      name: 'Spot Freight requests',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/requests/spot/freight',
      Component: RequestsListPage,
      name: 'Freight Requests',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/requests/:id',
      Component: RequestsDetailsPage,
      name: 'Freight Details',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      customCrumbsCallback: (givenRoute) => {
        // If batch request
        const localStorageBatchId = localStorage.getItem('BATCH_ID_DETAILS_SOURCE');
        if (history[history.length - 2]?.includes('batch') || localStorageBatchId) {
          return [
            {
              path: '/',
              Component: EntryPage,
              isBackgroundImage: true,
              backgroundImage,
            },
            {
              path: '/requests',
              Component: RequestsMenu,
              name: 'Freight Requests',
              isNavigation: true,
              isBackgroundImage: true,
              backgroundImage,
            },
            {
              path: '/requests/batch',
              Component: BatchListPage,
              name: 'Freight dispatch cycles',
              isBackgroundImage: true,
              isNavigation: true,
              backgroundImage,
            },
            {
              path: `/batch/${localStorageBatchId}`,
              Component: BatchDetailsPage,
              name: 'Cycle details',
              isBackgroundImage: true,
              isNavigation: true,
              backgroundImage,
            },
            givenRoute,
          ];
        }

        // Simple request
        return [
          {
            path: '/',
            Component: EntryPage,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/requests',
            Component: RequestsMenu,
            name: 'Freight Requests',
            isNavigation: true,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/requests/spot',
            Component: SpotRequestsMenu,
            name: 'Spot Freight requests',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
          },
          givenRoute,
        ];
      },
    },
    {
      path: '/requests/spot/assignments',
      Component: AssignmentsListPage,
      name: 'Freights Assignment',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/requests/batch',
      Component: BatchListPage,
      name: 'Freight dispatch cycles',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/batch/:id',
      Component: BatchDetailsPage,
      name: 'Cycle details',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      customCrumbsCallback: (givenRoute) => {
        return [
          {
            path: '/',
            Component: EntryPage,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/requests',
            Component: RequestsMenu,
            name: 'Freight Requests',
            isNavigation: true,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/requests/batch',
            Component: BatchListPage,
            name: 'Freight dispatch cycles',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
          },
          // {
          //   path: `/batch/${localStorageBatchId}`,
          //   Component: BatchDetailsPage,
          //   name: 'Cycle details',
          //   isBackgroundImage: true,
          //   isNavigation: true,
          //   backgroundImage,
          // },
          givenRoute,
        ];
      },
    },

    // Letters
    {
      path: '/letters',
      Component: LettersMenu,
      name: 'Letters',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/letters/award-letters',
      Component: LettersPage,
      name: 'Award letters',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      data: { type: 'award' },
    },
    {
      path: '/letters/generate-award',
      Component: GenerateLetterPage,
      name: 'Generate award letters',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      data: { type: 'award' },
      customCrumbsCallback: (givenRoute) => {
        return [
          {
            path: '/',
            Component: EntryPage,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/letters',
            Component: LettersMenu,
            name: 'Letters',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
          },
          {
            path: '/letters/award-letters',
            Component: LettersPage,
            name: 'Award letters',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
            data: { type: 'award' },
          },
          givenRoute,
        ];
      },
    },
    {
      path: '/letters/termination-letters',
      Component: LettersPage,
      name: 'Termination letters',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      data: { type: 'termination' },
    },
    {
      path: '/letters/generate-termination',
      Component: GenerateLetterPage,
      name: 'Generate termination letters',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      data: { type: 'termination' },
      customCrumbsCallback: (givenRoute) => {
        return [
          {
            path: '/',
            Component: EntryPage,
            isBackgroundImage: true,
            backgroundImage,
          },
          {
            path: '/letters',
            Component: LettersMenu,
            name: 'Letters',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
          },
          {
            path: '/letters/termination-letters',
            Component: LettersPage,
            name: 'Termination letters',
            isBackgroundImage: true,
            isNavigation: true,
            backgroundImage,
            data: { type: 'termination' },
          },
          givenRoute,
        ];
      },
    },

    // Vendors
    {
      path: '/vendors',
      Component: VendorsListPage,
      name: 'Vendors management',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },
    {
      path: '/vendors/:id/',
      Component: VendorDetailsPage,
      name: 'Vendor Details',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
    },

    // Dashboard
    {
      path: '/reports',
      Component: ReportsPage,
      name: 'Reporting',
      isBackgroundImage: true,
      isNavigation: true,
      backgroundImage,
      customCrumbsCallback: (givenRoute) => {
        return [
          {
            path: '/',
            Component: EntryPage,
            isBackgroundImage: true,
            backgroundImage,
          },
          givenRoute,
        ];
      },
    },
  ] as ICrumb[];
