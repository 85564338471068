import React, { FC, useState } from 'react';
import { Button, Dropdown, PageTitle, Pagination, Table } from '@ui-modules';
import { Input, Spin } from 'antd';
import { useQuery } from 'react-query';
import { IDropdownOption } from '@ui-modules/types';
import { useRepository } from '@context';
import { IQuotationPayload, IRequestListParams, IVendorsListResponse } from '@common/interfaces';
import './styles.scss';
import { ProgressBar } from '@components';

const { Search } = Input;

export interface IMultipleQuotationPayload extends Omit<IQuotationPayload, 'booking_uuid'> {
  batch_uuid: string;
  all_not_processed: boolean;
  bookings_uuids: string[];
}

export interface IMultipleNotificationPayload {
  all_assigned: boolean;
  bookings_uuids: string[];
}

const columns = [
  {
    dataIndex: 'vendor_id',
    key: 'vendor_id',
    title: 'Vendor number',
    sorter: true,
  },
  {
    dataIndex: 'legal_name',
    key: 'legal_name',
    title: 'Legal name',
  },
  {
    dataIndex: 'contact_email',
    key: 'contact_email',
    title: 'Main contact email',
  },
  {
    dataIndex: 'participation_rate',
    key: 'participation_rate',
    title: 'Tenders participation rate',
    width: 200,
    render: (record: number) => <ProgressBar value={record} />,
  },
  {
    dataIndex: 'is_active',
    key: 'is_active',
    title: 'Status',
    width: 150,
    render: (record: string) => {
      if (record) {
        return <Button text="Active" className="btn active" variant="submit" />;
      }

      return <Button text="Not Active" className="btn closed" variant="submit" />;
    },
  },
  {
    dataIndex: 'uuid',
    key: 'uuid',
    title: 'Details',
    width: 50,
    render: (record: string) => {
      return (
        <Button
          onClick={() => {
            window.location.href = `/vendors/${record}`;
          }}
          className="view-button"
          text="View"
        />
      );
    },
  },
];

const VendorsListPage: FC = () => {
  const country = localStorage.getItem('country');
  const { freightRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption | null>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [columnOrder, setColumnOrder] = useState<string>('');
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');

  const buildParams = (): IRequestListParams => {
    return {
      search: searchFilter || '',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
      is_active: statusFilter?.value,
    };
  };

  const { data: list, isLoading } = useQuery<IVendorsListResponse>(
    ['freight-vendors-list', selectedPage, pageSize, columnOrder, statusFilter, searchFilter],
    () => freightRepository.getVendorsList(facilityId, buildParams()),
  );

  const resetFilter = () => {
    setStatusFilter(null);
    setSearchFilter('');
    setSearchFilterValue('');
  };

  return (
    <Spin spinning={isLoading}>
      <section className="hbh-container vendors-list">
        <PageTitle className="section-title" title={`Vendors management - ${country}`} bottomLine />
        <section className="filters">
          <div className="status-filter-dropdown">
            <div className="input-wrapper">
              <p className="input-label">Search Vendor</p>
              <Search
                className="search-input"
                placeholder="Insert"
                value={searchFilterValue}
                onSearch={() => setSearchFilter(searchFilterValue)}
                onChange={(e) => setSearchFilterValue(e.target.value)}
                id="search-input"
                allowClear
                enterButton
              />
            </div>
            <Dropdown
              className="filter status-filter outline"
              label={<p className="hbh-select-label">Filter by status</p>}
              value={statusFilter}
              placeholder="All statuses"
              // TO DO: Change boolean here to string in options
              options={list?.options && (list?.options.status as any)}
              onChange={(val) => {
                setSelectedPage(1);
                setStatusFilter(val);
              }}
            />
            <span onClick={resetFilter} className="reset">
              Reset filters
            </span>
          </div>
          <div className="action-buttons">
            {/* Note: Need BE */}
            {/* <Button
              onClick={() => null}
              className="add-button"
              text="Add transporter"
              variant="submit"
              disabled
            /> */}
          </div>
        </section>
        <div className="table table-scrolling">
          <Table
            columns={columns}
            data={list?.results || []}
            rowKey="uuid"
            variant="dark"
            isLoading={false}
            onChangeColumnOrder={setColumnOrder}
          />
          <Pagination
            className="pagination"
            selectedPage={selectedPage}
            showJumper
            showPageSize
            totalPages={list?.count || 0}
            variant="dark"
            onPageChange={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      </section>
    </Spin>
  );
};

export default VendorsListPage;
