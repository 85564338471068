/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import * as Yup from 'yup';
import { components, ControlProps } from 'react-select';
import { DateField, TextField } from '@ui-modules/informed';
import moment from 'moment';
import { message } from 'antd';
import { mapValues, merge } from 'lodash';
import {
  GefsLocationsResponse,
  IDestination,
  ILineItem,
  IRecipient,
  PostedLineItem,
} from '@common/interfaces';
import AsyncDropdownField, { IDropdownOption } from '@components/AsyncDropdownField';
import { debounce } from '@common/utils';
import { CroppedText } from '@components';

interface CurrentLineItem extends Partial<ILineItem> {
  isDraft?: boolean;
  order?: number;
}

interface Point {
  destination: IDestination;
  id: string;
  lineItems: Array<CurrentLineItem>;
  recipient: IRecipient;
}

const getDropdownStatusComponentProps = (showIndicator: boolean, status: string) => {
  /* eslint-disable react/jsx-props-no-spreading */
  const Control = ({ children, ...props }: ControlProps) => {
    return (
      <components.Control className={status} {...props}>
        {children}
      </components.Control>
    );
  };

  if (showIndicator) {
    return {
      Control,
      IndicatorSeparator: () => null,
      IndicatorsContainer: () => null,
    };
  }
  return { Control };
};

const statusDropdownCustomStyles = {
  control: () => ({
    color: 'white',
    display: 'inline-flex',
    border: 'none !important',
    borderRadius: '3px !important',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
    margin: '0 20px',
    fontSize: 14,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#fff !important',
    },
  }),
};

const getDateComponent = (record: string, isEditMode: boolean, key: string) => {
  return (
    <DateField
      disabled={!isEditMode}
      defaultValue={record ? moment(new Date(record)).toDate() : undefined}
      name={key}
      dateFormat="dd/MM/yyyy"
      minDate={moment().toDate()}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />
  );
};

const getInputComponent = (record: string | undefined, isEditMode: boolean, key: string) => {
  return isEditMode ? (
    <TextField name={key} initialValue={record} disabled={!isEditMode} />
  ) : (
    record
  );
};

const getLocationComponent = (
  record: string,
  isEditMode: boolean,
  key: string,
  freightRepository: any,
) => {
  const formatOptionLabel = ({
    label,
    location_type,
  }: {
    label: string;
    location_type: string;
  }) => (
    <div className="custom-option">
      <div>{label}</div>
      <div className="custom-option-info">{location_type ? `- ${location_type}` : ''}</div>
    </div>
  );

  const country = localStorage.getItem('country');

  return isEditMode ? (
    <AsyncDropdownField
      field={key}
      defaultValue={{ label: record, value: record }}
      loadOptions={debounce((query: string, callback: (arg: Array<IDropdownOption>) => void) => {
        freightRepository
          .getGefsLocations({
            search: query.trim(),
            country,
          })
          .then((data: GefsLocationsResponse) => callback(data.results));
      }, 500)}
      defaultOptions
      placeholder="Select location..."
      formatOptionLabel={formatOptionLabel}
    />
  ) : (
    <CroppedText text={record} maxLength={35} />
  );
};

const mapLineItems = (items: Array<any>, points: Array<Point>) => {
  const newArray: any = [];

  Object.entries(items).forEach(([key, item]) => {
    const {
      good_category,
      loading_units,
      number_of_units,
      stackable,
      volume,
      weight,
      weight_volume_unit,
      ...values
    } = item;

    // TO DO: Refactor it
    const initialPoint = points.find((point) => point.lineItems.find(({ uuid }) => uuid === key));
    const initialItem = initialPoint?.lineItems.find(({ uuid }) => uuid === key);

    newArray.push({
      ...values,
      good_category_name: good_category ? good_category.value : undefined,
      line_item_id: initialItem?.uuid,
      isDraft: initialItem?.isDraft,
      destination: initialItem?.destination,
      recipient: initialItem?.recipient,
      loading_units_name: loading_units ? loading_units.value : undefined,
      number_of_loading_units: number_of_units ? parseFloat(number_of_units) : undefined,
      stackable: stackable?.value === '1',
      volume: volume ? parseFloat(volume) : undefined,
      weight: weight ? parseFloat(weight) : undefined,
      weight_volume_unit: weight_volume_unit ? weight_volume_unit.value : undefined,
    });
  });

  return newArray;
};

const parseToNestedObject = (values: any, points: Array<Point>) => {
  let nestedObject = {};
  let newLineItems: Array<PostedLineItem> = [];

  Object.keys(values).forEach((key: string) => {
    const splitKeys = key.split(':');
    const parsedObject: any = splitKeys.reduceRight((obj, elem, index) => {
      if (index === splitKeys.length - 1) {
        return { [elem]: values[key] };
      }
      return { [elem]: obj };
    }, {});

    if (key === 'line_items') {
      const mappedLineItems = mapLineItems(parsedObject.line_items, points);
      newLineItems = mappedLineItems
        .filter(({ isDraft }: any) => isDraft)
        .map(({ line_item_id, isDraft, ...vals }: any) => {
          return { ...vals };
        });
      parsedObject.line_items = mappedLineItems.filter(({ isDraft }: any) => !isDraft);
    }

    nestedObject = merge(nestedObject, parsedObject);
  });

  return {
    nestedObject,
    newLineItems,
  };
};

const getValidationSchema = () =>
  Yup.object().shape({
    pickup_date: Yup.string().required('Required'),
    'sender:phone_number': Yup.string().max(15, 'Max 15 characters'),
    'sender:email': Yup.string().required('Required').email('Incorrect email'),
    'sender:organisation': Yup.string().required('Required').max(255, 'Max 255 characters'),
    line_items: Yup.lazy((obj) =>
      Yup.object(
        mapValues(obj, () => {
          return Yup.object().shape({
            good_category: Yup.object().required('Required'),
            loading_units: Yup.object().required('Required'),
            number_of_units: Yup.string().required('Required'),
            short_description: Yup.string().max(70, 'Max 70 characters').required('Required'),
            stackable: Yup.object().required('Required'),
            weight: Yup.string().required('Required'),
            weight_volume_unit: Yup.object().required('Required'),
          });
        }),
      ),
    ),
  });

const onErrorHandler = (error: any) => {
  if (error.response.status === 404) {
    message.error('ID of that service request does not exist');
  } else if (error.response.status === 400) {
    const errorKeys = Object.keys(error.response.data);
    const result = errorKeys
      .map((errorKey) => {
        return error.response.data[errorKey];
      })
      .join(', ');
    message.error(result);
  }
};

export {
  getDropdownStatusComponentProps,
  statusDropdownCustomStyles,
  getDateComponent,
  getInputComponent,
  parseToNestedObject,
  getValidationSchema,
  onErrorHandler,
  getLocationComponent,
};
