/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import { Modal, Spin } from 'antd';
import { Button, Dropdown, PageTitle, Table, Date as DatePicker, Pagination } from '@ui-modules';
import { ReactComponent as Approval } from '@assets/svg/approval.svg';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT, DEFAULT_DECIMAL_FORMAT } from '@common/constants';
import NumberFormat from 'react-number-format';
import { useQuery, useMutation } from 'react-query';
import { useRepository } from '@context';
import loadingGif from '@assets/img/default/loading.gif';
import { onErrorHandler } from '@pages/RequestsDetailsPage/components/RequestsDetails/utils';
import {
  ILetterData,
  ILetterPostData,
  ILetterPostDataSuccess,
  ILetterProviderDataResponse,
  IOptionsForLetterCreation,
} from '@common/interfaces';
import './styles.scss';
import useInterval from '@common/utils/useInterval';
import EmptyBox from '@components/EmptyBox';
import { CroppedText, DropdownGrouped, SwitcherExpanded, Tip } from '@components';
import { Tooltip } from 'react-tooltip';
import { Nullable } from '@common/interfaces/Common';
import { ItemOrdered } from '@components/DropdownGrouped';

export interface IRangeDatePicker {
  from_date: string;
  to_date: string;
}

interface IDropdownOptionReference extends IDropdownOption {
  expiration_date: string;
}

interface IDropdownOptionVendor extends IDropdownOption {
  short_label: string;
}

const MAX_COUNT_OTHER_APPROVERS = 2;

const columns = [
  {
    dataIndex: 'booking_reference_code',
    key: 'booking_reference_code',
    title: 'Freight ID',
  },
  {
    dataIndex: 'booking_pickup_location',
    key: 'booking_pickup_location',
    title: 'Loading location',
    render: (record: string) => <CroppedText text={record} maxLength={30} />,
  },
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Quotation submitted date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'booking_pickup_date',
    key: 'booking_pickup_date',
    title: 'Proposed start loading date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'dropoff_location',
    key: 'dropoff_location',
    title: 'Destination',
    render: (record: string) => <CroppedText text={record} maxLength={30} />,
  },
  {
    dataIndex: 'destination_dropoff_date',
    key: 'destination_dropoff_date',
    title: 'Proposed delivery completion date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'quote_mt',
    key: 'quote_mt',
    title: 'Cost/MT (USD)',
    render: (record: string) => (
      <NumberFormat displayType="text" value={record} {...DEFAULT_DECIMAL_FORMAT} />
    ),
  },
  {
    dataIndex: 'booking_type',
    key: 'booking_type',
    title: 'Freight type',
  },
];

interface IGenerateLetterPage {
  type: 'award' | 'termination';
}

const GenerateLetterPage: FC<IGenerateLetterPage> = ({ type }) => {
  const navigate = useNavigate();
  const { freightRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const defaultValidDate = moment().add(1, 'month').toDate();
  const isAward = type === 'award';
  const lettersListUrl = isAward ? '/letters/award-letters' : '/letters/termination-letters';

  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);

  const [vendor, setVendor] = useState<Nullable<IDropdownOptionVendor>>(null);
  const [reference, setReference] = useState<Nullable<IDropdownOptionReference>>(null);
  const [validDate, setValidDate] = useState<Date | null>(defaultValidDate);

  const [approver, setApprover] = useState<Nullable<IDropdownOption>>(null);
  const [otherApprovers, setOtherApprovers] = useState<Array<ItemOrdered>>([]);
  const [eSignature, setESignature] = useState(true);

  const [referenceOptions, setReferenceOptions] = useState<Array<IDropdownOptionReference>>([]);
  const [freightType, setFreightType] = useState<IDropdownOption | null>(null);
  const [creationDate, setCreationDate] = useState<IDropdownOption | null>(null);

  const [confirmLetterModal, setConfirmLetterModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [isUserWaiting, setIsUserWaiting] = useState(false);
  const [letterId, setLetterId] = useState<string | null>(null);

  const { data: creationOptions, isLoading } = useQuery<IOptionsForLetterCreation>(
    [`providers-list-${type}`],
    () => freightRepository.getOptionsForLetterCreation(facilityId, type),
  );

  const params = {
    freight_type: freightType?.value,
    creation_date: creationDate?.value,
    limit: pageSize,
    offset: (selectedPage - 1) * 10,
    transport_contract: reference?.value || '',
    letter_type: type,
  };

  const { data: providerData, isLoading: isProviderDataLoading } =
    useQuery<ILetterProviderDataResponse>(
      [
        'provider-data',
        vendor?.value,
        freightType,
        reference,
        creationDate,
        pageSize,
        selectedPage,
      ],
      () => freightRepository.getLetterProviderData(vendor?.value || '', params),
      {
        enabled: Boolean(vendor),
      },
    );

  const { data: letterData, refetch } = useQuery<ILetterData>(
    ['letter-data'],
    () => freightRepository.getLetter(letterId || ''),
    {
      enabled: Boolean(letterId),
    },
  );

  useInterval(() => {
    if (letterId) {
      refetch();
    }
  }, 5000);

  const vendorOptions = creationOptions?.providers.map(
    ({ number_of_items_pending, legal_name, uuid, transport_contracts }) => {
      return {
        label: `${legal_name} (${number_of_items_pending} items pending)`,
        short_label: legal_name,
        value: uuid,
        transport_contracts,
      };
    },
  );

  const approversOptions = creationOptions
    ? creationOptions.approvers.map(({ first_name, last_name, uuid }) => {
        return { label: `${first_name} ${last_name}`, value: uuid };
      })
    : [];

  const otherApproversOptions = creationOptions
    ? creationOptions.other_approvers.map(({ first_name, last_name, uuid }) => {
        return { label: `${first_name} ${last_name}`, value: uuid };
      })
    : [];

  const creationDateOptions = [...(providerData?.options.quotation_submission_date || [])].map(
    ({ label, value }) => {
      return { label: moment(label).format(DEFAULT_DATE_FORMAT), value };
    },
  );
  creationDateOptions.unshift({ label: 'All dates', value: 'All dates' });

  const freightTypeOptions = providerData?.options.freight_type || [];

  const isDataFull = Boolean(
    approver && validDate && reference && freightType && (!eSignature || otherApprovers.length > 0),
  );

  const sendLetter = useMutation(
    (data: ILetterPostData) => freightRepository.postLetterData(vendor?.value || '', data, params),
    {
      onSuccess: (data: ILetterPostDataSuccess) => {
        setLetterId(data.uuid);
      },
      onError: (error) => {
        onErrorHandler(error);
        setSuccessModal(false);
      },
    },
  );

  const handleConfirm = () => {
    setConfirmLetterModal(false);
    setSuccessModal(true);

    const firstOtherApprover = otherApprovers.find(({ order }) => order === 1);
    const secondOtherApprover = otherApprovers.find(({ order }) => order === 2);

    sendLetter.mutate({
      approver: approver?.value || '',
      batch: freightType?.value || '',
      provider: vendor?.value || '',
      rates_valid_until: moment(validDate).format('YYYY-MM-DD'),
      should_collect_signatures: eSignature,
      transport_contract: reference?.value || '',
      other_approver1: eSignature && firstOtherApprover ? firstOtherApprover.value : undefined,
      other_approver2: eSignature && secondOtherApprover ? secondOtherApprover.value : undefined,
    });
  };

  const resetFilters = () => {
    setSelectedPage(1);
    setFreightType(null);
    setCreationDate(null);
  };

  const fileUrl = letterData
    ? eSignature
      ? letterData.docusign_file_url
      : letterData.file_url
    : null;

  return (
    <section className="hbh-container generate-letter-page">
      <PageTitle className="section-title" title="Generate letters" bottomLine />
      <div className="container">
        <Spin spinning={isLoading}>
          <div className="head">
            <div className="head-left">
              <Dropdown
                className="dropdown dropdown-vendor"
                label={<p className="hbh-select-label">Select vendor *</p>}
                // TO DO: Dropdown should accept extended IDropdown Type
                onChange={(value: any) => {
                  resetFilters();
                  setVendor(value);
                  const refOptions = value.transport_contracts.map(
                    ({ name, uuid, expiration_date }: any) => {
                      return { label: name, value: uuid, expiration_date };
                    },
                  );
                  setReferenceOptions(refOptions);
                  setReference(null);
                }}
                options={vendorOptions}
                placeholder="Select..."
                value={vendor}
              />
              <Dropdown
                className="dropdown"
                label={<p className="hbh-select-label">Reference T&C *</p>}
                // TO DO: Dropdown should accept extended IDropdown Type
                onChange={(value: any) => setReference(value)}
                options={(referenceOptions as any) || []}
                placeholder="Select..."
                value={reference}
                isDisabled={!vendor}
              />
              <Dropdown
                className="dropdown"
                label={<p className="hbh-select-label">Ultimate Approver * (full signature)</p>}
                onChange={setApprover}
                options={approversOptions}
                placeholder="Select..."
                value={approver}
                isDisabled={!vendor}
              />
              <div className="signature">
                <div className="signature-sign">
                  <p className="hbh-select-label">E-signature for the document</p>
                  <SwitcherExpanded
                    checked={eSignature}
                    onChange={() => setESignature(!eSignature)}
                    defaultChecked
                    icon="sign"
                  />
                </div>
                <div className="signature-approvers">
                  <p className="hbh-select-label">
                    Order for the e-signature * (initials on all pages)
                  </p>
                  <DropdownGrouped
                    options={otherApproversOptions}
                    values={otherApprovers}
                    onChange={(values) => setOtherApprovers(values)}
                    maxCount={MAX_COUNT_OTHER_APPROVERS}
                    disabled={!vendor || !eSignature}
                  />
                </div>
              </div>
            </div>
            <div className="head-right">
              <div>
                <div className="hbh-select-label">T&Cs expiration date</div>
                <div className="value">
                  {reference
                    ? moment(reference.expiration_date).format(DEFAULT_DATE_FORMAT)
                    : '---------'}
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            {vendor ? (
              <>
                <div className="table-navigation">
                  <div>
                    <p className="hbh-select-label">Rates valid until</p>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      dateValue={validDate || undefined}
                      onChange={setValidDate}
                      placeholderText="DD/MM/YYYY"
                      disabled
                    />
                  </div>
                  <div className="filter-block">
                    <button type="button" onClick={() => resetFilters()} className="reset">
                      Reset filters
                    </button>
                    <Dropdown
                      className="dropdown dropdown-date"
                      label={<p className="hbh-select-label">Quotations submission date</p>}
                      onChange={(value) => {
                        setSelectedPage(1);
                        setCreationDate(value);
                      }}
                      options={creationDateOptions}
                      placeholder="Select date..."
                      value={creationDate}
                    />
                    <Dropdown
                      className="dropdown"
                      label={<p className="hbh-select-label">Freight type *</p>}
                      onChange={(value) => {
                        setSelectedPage(1);
                        setFreightType(value);
                      }}
                      options={freightTypeOptions}
                      placeholder="Select type..."
                      value={freightType}
                    />
                  </div>
                </div>
                <Table
                  columns={columns}
                  data={providerData?.results || []}
                  rowKey="uuid"
                  isLoading={isProviderDataLoading}
                  locale={{
                    emptyText: <EmptyBox />,
                  }}
                />
                <Pagination
                  className="pagination"
                  selectedPage={selectedPage}
                  showJumper
                  showPageSize
                  totalPages={providerData?.count || 0}
                  variant="light"
                  onPageChange={setSelectedPage}
                  onPageSizeChange={setPageSize}
                />
                <div className="buttons">
                  <Tip
                    isVisible={!isDataFull}
                    text="Please fill all the mandatory fields to proceed"
                  >
                    <div className="button-confirm">
                      <Button
                        onClick={() => setConfirmLetterModal(true)}
                        icon={<Approval />}
                        text="Confirm and generate"
                        variant="submit"
                        disabled={!isDataFull}
                      />
                    </div>
                  </Tip>
                </div>
              </>
            ) : (
              <div className="empty">Select the vendor to generate the letter</div>
            )}
          </div>
        </Spin>
      </div>
      <Modal
        footer={null}
        title={<div className="modal-title">Confirm letter generation</div>}
        width="33%"
        centered
        visible={confirmLetterModal}
        onCancel={() => setConfirmLetterModal(false)}
      >
        <div className="modal-content-text">
          <p>
            Do you want to confirm the generation of the letter for transport for{' '}
            <b>{vendor?.short_label}</b>?
          </p>
          {eSignature ? (
            <>
              <br />
              <p>
                Digital signature will be requested - in order - to <br />
                <b>{otherApprovers.map(({ label }) => label).join(', ')}</b> and{' '}
                <b>{approver?.label}</b> before being sent to <b>{vendor?.short_label}</b>
              </p>
            </>
          ) : null}
        </div>
        <div className="modal-buttons">
          <Button onClick={() => setConfirmLetterModal(false)} variant="danger" text="Cancel" />
          <Button onClick={handleConfirm} variant="submit" text="Confirm" />
        </div>
      </Modal>
      <Modal
        footer={null}
        title={null}
        width="33%"
        centered
        visible={Boolean(successModal)}
        onCancel={() => {
          setSuccessModal(false);
          navigate(lettersListUrl);
        }}
      >
        <div className="modal-content">
          {fileUrl ? (
            <>
              <Approval className="icon-approval" />
              <p>
                The letter was generated successfully{eSignature ? ' and sent to approvers' : ''}
              </p>
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isUserWaiting ? (
                <>
                  <div className="hbh-loader">
                    <img src={loadingGif} alt="Loading..." />
                  </div>
                  <p>The letter is being generated...</p>
                  <p>You will receive email containing the PDF once ready</p>
                </>
              ) : (
                <>
                  <div className="hbh-loader">
                    <img src={loadingGif} alt="Loading..." />
                  </div>
                  <p>
                    The letter is being generated. As this may take some time, you will receive an
                    email with the PDF as soon as it is ready. You can also wait here as the page
                    will refresh when the PDF is ready.
                  </p>
                </>
              )}
            </>
          )}
        </div>
        <div className="modal-buttons">
          {fileUrl ? (
            <Button
              onClick={() => {
                navigate(lettersListUrl);
                window.open(`${fileUrl}`, '_blank');
              }}
              text="Download letter"
              className="button"
            />
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isUserWaiting ? (
                <Button onClick={() => navigate(lettersListUrl)} text="Close" className="button" />
              ) : (
                <>
                  <Button
                    onClick={() => navigate(lettersListUrl)}
                    text="Close"
                    className="button"
                  />
                  <Button
                    onClick={() => setIsUserWaiting(true)}
                    text="I will wait here"
                    className="button"
                    variant="warning"
                  />
                </>
              )}
            </>
          )}
        </div>
      </Modal>
      <Tooltip id="global-tooltip" />
    </section>
  );
};

export default GenerateLetterPage;
