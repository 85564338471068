/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC } from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import css from './styles.module.scss';

interface LineChartWidgetDataItem {
  title?: string;
  backgroundColor?: string;
  data: Array<{
    label: string;
    value: number;
  }>;
}

interface ILineChartWidget {
  data: Array<LineChartWidgetDataItem>;
  title: string;
}

type MergedData = Record<string, Record<string, any>>;

interface ILine {
  key: string;
  backgroundColor?: string;
  title?: string;
}

const convertData = (data: Array<LineChartWidgetDataItem>) => {
  const mergedData: MergedData = {};
  const lines: Array<ILine> = [];

  data.forEach(({ data, backgroundColor, title }, index) => {
    const key = `value${index + 1}`;

    lines.push({
      backgroundColor,
      key,
      title,
    });

    data.forEach(({ label, value }) => {
      if (!mergedData[label]) {
        mergedData[label] = {};
      }
      mergedData[label][key] = value;

      // Note: add titles for showing in tooltips
      const { titles } = mergedData[label];
      if (titles) {
        mergedData[label].titles = { ...titles, [key]: title };
      } else {
        mergedData[label].titles = { [key]: title };
      }
    });
  });

  const result = {
    values: Object.keys(mergedData).map((label) => ({
      label,
      ...mergedData[label],
    })),
    lines,
  };

  return result;
};

const CustomTooltip = ({ payload }: TooltipProps<number, string>) => {
  const data = payload?.[0]?.payload || {};

  const items = Object.keys(data)
    .filter((key) => key.includes('value'))
    .map((key) => ({ value: data[key], title: data.titles[key] }))
    .sort((a, b) => b.value - a.value)
    .map(({ value, title = 'Value' }) => <div key={title}>{`${title} - ${value}`}</div>);

  return <div className={css.tooltip}>{items}</div>;
};

const LineChartWidget: FC<ILineChartWidget> = ({ title, data }) => {
  const { values, lines } = convertData(data);

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      {lines.length > 1 ? (
        <div className={css.labels}>
          {lines.map(({ title, backgroundColor }, idx) => (
            <div className={css.label} key={idx}>
              <div className={css.dot} style={{ backgroundColor }} />
              <div className={css.text}>{title}</div>
            </div>
          ))}
        </div>
      ) : null}
      <div className={css.content}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={500} height={300} data={values}>
            <XAxis dataKey="label" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {lines.map(({ key, backgroundColor = '#2f80ed' }) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={backgroundColor}
                strokeWidth="4"
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineChartWidget;
