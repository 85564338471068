import React, { FC } from 'react';
import './styles.scss';

const RedirectPage: FC = () => {
  return (
    <div className="redirect-page">
      <div className="redirect-box">
        <h3>
          Ops, seems like you opened this page from the source other than Humanitarian Booking Hub.
          Please, follow the link below and select Freight Management to go to this page.
        </h3>
        <a href={String(process.env.REACT_APP_HBH_DO_URL)}>Click here</a>
      </div>
    </div>
  );
};

export default RedirectPage;
