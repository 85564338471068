import React, { FC } from 'react';
import { Menu } from '@components';
import { DocIcon, TruckIcon } from '@assets/svg';

const LettersMenu: FC = () => {
  const buttons = [
    {
      description: 'Review and process new requests',
      icon: <TruckIcon />,
      id: 'freight',
      label: 'Freight requests',
      link: '/requests/spot/freight',
    },
    {
      description: 'Access quotations, create and manage assignments',
      icon: <DocIcon />,
      id: 'assignment',
      label: 'Freights assignment',
      link: '/requests/spot/assignments',
    },
  ];

  return <Menu buttons={buttons} centered />;
};

export default LettersMenu;
