import React, { FC, ReactElement, ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { PlacesType } from 'react-tooltip';

interface ITip {
  text?: string;
  isVisible?: boolean;
  className?: string;
  content?: ReactElement;
  children?: ReactNode;
  place?: PlacesType;
}

const Tip: FC<ITip> = ({ children, text = '', isVisible = true, className, content, place }) => {
  return (
    <span
      className={className}
      data-tooltip-place={place}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={isVisible ? text : ''}
      data-tooltip-html={content ? renderToStaticMarkup(content) : undefined}
    >
      {children}
    </span>
  );
};

export default Tip;
