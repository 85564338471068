import { Modal, Spin, Tooltip } from 'antd';
import React, { FC } from 'react';
import './styles.scss';
import { Button } from '@ui-modules';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
  isConfirmationVisible: boolean;
  setIsConfirmationVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitHandler: () => void;
  isButtonDisabled: boolean;
  isBatch: boolean;
}

const ConfirmationModal: FC<IProps> = ({
  isConfirmationVisible,
  setIsConfirmationVisible,
  onSubmitHandler,
  isButtonDisabled,
  isBatch,
}) => {
  return (
    <Modal
      footer={null}
      title={<div className="modal-title">Save freight assignments</div>}
      width="60%"
      closeIcon={
        <Tooltip title={isButtonDisabled ? 'Please wait. Emails are being sent.' : ''}>
          <CloseOutlined />
        </Tooltip>
      }
      centered
      visible={isConfirmationVisible}
      onCancel={() => !isButtonDisabled && setIsConfirmationVisible(false)}
    >
      <div className="modal-content-text">
        {!isBatch ? (
          <p>
            The selected assignment will be saved and a notifications will be sent to the awarded
            and not awarded transports
          </p>
        ) : (
          <p>
            The selected assignment will be saved and the status of this freight will be set to
            &quot;Assigned&quot;. To notify all providers use the main action &quot;Send award
            notification&quot; on the list page
          </p>
        )}
      </div>
      <Spin spinning={isButtonDisabled} className="confirmation-loader" />
      <div className={`modal-buttons ${isButtonDisabled && 'hidden'}`}>
        <Button onClick={() => setIsConfirmationVisible(false)} variant="danger" text="Cancel" />
        <Button
          disabled={isButtonDisabled}
          onClick={onSubmitHandler}
          variant="submit"
          text="Confirm"
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
