/* eslint-disable @typescript-eslint/naming-convention */
import { IProviderLineItem, IProvidersQuotationResponse } from '@common/interfaces';
import { ILineItemCurrency } from '@common/interfaces/Common';

export const getCurrencies = (data: IProvidersQuotationResponse): ILineItemCurrency | null => {
  if (data) {
    return data.providers_quotations_overview.reduce((aggregator, element) => {
      return {
        ...aggregator,
        [element.provider_legal_name]: element.currency_iso_code,
      };
    }, {});
  }
  return null;
};

// TO DO: Need refactoring with BE for getting correct data objects

// Get default format for all sums
export const getFormattedMoney = (sum?: number, sign = 'USD') => {
  if (!sum) return '-';
  return `${Number(sum.toFixed(2)).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  })} ${sign}`;
};

// Should be object that describe cell in quotation table
export interface NormalizedQuotation {
  column_order_number: number;
  destination_name: string;
  destination_uuid: string;
  dropoff_date: string;
  line_item_quotation_uuid: string;
  pickup_date: string;
  provider_legal_name: string;
  // TO DO: Maybe we need provider id also - f.e. for groupByProvider
  quote: number;
  quote_mt: number;
  selected: boolean;
  short_description: string;
  trucks: Array<any>;
  uuid: string;
  volume: number;
  weight: number;
}

export interface GroupedQuotations {
  label: string;
  items: Array<NormalizedQuotation>;
}

// This normalizer is for getting correct data.
// Accept data?.quotations_for_line_item (IProviderLineItem)
// from server and produce nice array of NormalizedQuotation
export const getNormalizedQuotations = (data: IProviderLineItem): Array<NormalizedQuotation> => {
  const quotations: Array<NormalizedQuotation> = [];

  Object.keys(data).forEach((key) => {
    const item = data[key];
    Object.keys(item).forEach((provider) => {
      if (provider !== 'line_item_order_number' && provider !== 'line_item_destination') {
        const providerData = item[provider];
        if (providerData.line_item_quotation) {
          quotations.push({
            line_item_quotation_uuid: key,
            column_order_number: item.line_item_order_number,
            destination_name: item.line_item_destination.name,
            destination_uuid: item.line_item_destination.uuid,
            quote_mt: providerData.quote_mt,
            selected: providerData.selected,
            ...providerData.line_item_quotation,
            quote: providerData.quote,
          } as any);
        }
      }
    });
  });

  return quotations;
};

// Function for grouping selected quotations by provider
export const groupByProvider = (data: Array<NormalizedQuotation>) => {
  return Array.from(new Set(data.map((o) => o.provider_legal_name))).map((label) => {
    return {
      label,
      items: data.filter(({ provider_legal_name }) => provider_legal_name === label),
    };
  });
};

// Function for grouping selected quotations by destination
export const groupByDestination = (data: Array<NormalizedQuotation>) => {
  return Array.from(new Set(data.map((o) => o.destination_name))).map((label) => {
    return {
      label,
      items: data.filter(({ destination_name }) => destination_name === label),
    };
  });
};

// Function for adding totals (quote and quote_mt) for grouped quotations
export const getCalculatedTotals = (data: Array<GroupedQuotations>) => {
  return data.map(({ label, items }) => ({
    label,
    items,
    sum: items.reduce((acc, item) => {
      return acc + item.quote;
    }, 0),
    sum_mt:
      items.reduce((acc, item) => {
        return acc + item.quote_mt;
      }, 0) / items.length,
  }));
};
