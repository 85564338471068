import React, { useRef, useState, useEffect, FC } from 'react';
import { DetailsContainer } from '@ui-modules';
import './styles.scss';
import { ReactComponent as Arrow } from '@assets/svg/arrow-right-circle-fill.svg';
import { Tooltip } from 'antd';
import { ReactComponent as ChatIcon } from '@assets/svg/chat.svg';
import { IQuotationOverview } from '@common/interfaces/Common';
import useWindowSize from '@common/hooks/useWindowSize';
import { ReactComponent as EmptyIcon } from '@assets/svg/empty.svg';
import moment from 'moment';
import { DEFAULT_DATE_TIME_FORMAT } from '@common/constants';

interface IOverviewListProps {
  data: IQuotationOverview[];
}

interface IOverviewElementProps {
  data: IQuotationOverview;
  childRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const OverviewElement: FC<IOverviewElementProps> = ({ data, childRef }) => {
  return (
    <div ref={childRef} className="overview-item-container">
      <p className="provider-title">
        <Tooltip title={data.provider_legal_name}>{data.provider_legal_name}</Tooltip>
      </p>
      <div>
        <span className="label">OVERALL COST</span>
        <p>
          {data?.overall_cost
            ? Number(data?.overall_cost.toFixed(2)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })
            : 0}{' '}
          {data.currency_iso_code}
        </p>
        <span className="label">NUMBER OF TRUCKS</span>
        <p>{data.number_of_trucks}</p>
        <span className="label">SUBMISSION DATE</span>
        <p>{moment(data.submission_date).utc().format(DEFAULT_DATE_TIME_FORMAT)}</p>
        {data?.remarks ? (
          <Tooltip title={data?.remarks}>
            <p className="remarks">
              SEE REMARKS <ChatIcon />
            </p>
          </Tooltip>
        ) : (
          <p className="remarks disabled">NO REMARKS</p>
        )}
      </div>
    </div>
  );
};

const OverviewList: FC<IOverviewListProps> = ({ data }) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const lastElementRef = useRef<null | HTMLDivElement>(null);
  const [focusedItem, secFocusedItem] = useState(0);
  const [isOverflow, setIsOverflow] = useState(true);
  const size = useWindowSize();

  const isOverflowActive = (event: HTMLDivElement | null) => {
    if (event === null) return false;
    return event.offsetWidth < event.scrollWidth;
  };

  useEffect(() => {
    if (containerRef.current === null) {
      setIsOverflow(true);
    } else {
      setIsOverflow(isOverflowActive(containerRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastElementRef.current, size]);

  const backButtonClick = (newIndex: number) => {
    if (containerRef.current !== null) {
      const childRef = containerRef.current.childNodes[newIndex] as HTMLDivElement;
      childRef.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
    secFocusedItem(newIndex);
  };

  return (
    <div>
      <div className="quotation-overview-label">
        Quotations overview - <span>{data.length}</span> Quotations received
      </div>
      <div className="overview-list-container">
        <div className={`arrow-buttons-container ${!isOverflow && 'hidden'}`}>
          <Arrow onClick={() => backButtonClick(focusedItem + 1)} className="scroll-button right" />
          <Arrow
            onClick={() => backButtonClick(focusedItem - 1)}
            className={`scroll-button left ${focusedItem === 0 && 'disabled'}`}
          />
        </div>
        {data.length > 0 ? (
          <div className="overview-details-container">
            <DetailsContainer
              containerRef={containerRef}
              items={data.map((item, index) => ({
                label: '',
                content: (
                  <OverviewElement
                    data={item}
                    key={item.provider_legal_name}
                    childRef={index === data.length - 1 ? lastElementRef : undefined}
                  />
                ),
              }))}
            />
          </div>
        ) : (
          <div className="empty-box">
            <EmptyIcon />
            No data
          </div>
        )}
      </div>
    </div>
  );
};

export default OverviewList;
