export const SUPPORT_EMAIL = process.env.REACT_APP_EMAIL_SUPPORT ?? 'global.accomodation@wfp.org';
export const LOCAL_STORAGE_BATCH_ID = localStorage.getItem('BATCH_ID_DETAILS_SOURCE');

export const DEFAULT_SERVER_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const SECONDARY_DATE_FORMAT = 'yyyy-MM-DD';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DEFAULT_DECIMAL_FORMAT = {
  decimalScale: 2,
  decimalSeparator: '.',
  fixedDecimalScale: true,
  max_length: 10,
  thousandSeparator: ',',
};

export const DEFAULT_WEIGHT_FORMAT = {
  decimalScale: 4,
  decimalSeparator: '.',
  fixedDecimalScale: true,
  max_length: 10,
  thousandSeparator: ',',
};
