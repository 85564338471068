/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ArrowIcon } from '../../icons';
import css from './styles.module.scss';

export interface MenuButton {
  label: string;
  id: string;
  description?: string;
  link: string;
  icon: JSX.Element;
  tags?: Array<{
    label: string;
    color: string;
    count: number;
  }>;
}

interface IMenu {
  buttons: Array<MenuButton>;
  centered?: boolean;
}

const Menu: FC<IMenu> = ({ buttons, centered = false }) => {
  return (
    <nav className={`${css.menu} ${centered ? css.centered : ''}`}>
      {buttons.map(({ description, icon, id, label, link, tags }) => (
        <Link to={link} className={css.link} key={id}>
          <div className={css.content}>
            <div className={css.icon}>{icon}</div>
            <div className={css.label}>{label}</div>
            {tags && tags.length > 0 ? (
              <div>
                {tags.map(({ count, label, color }) => (
                  <div
                    key={label}
                    className={`${css.tag} ${css[color]}`}
                  >{`${count} ${label}`}</div>
                ))}
              </div>
            ) : null}
            {description && <div className={css.description}>{description}</div>}
          </div>
          <ArrowIcon className={css.arrow} type="right" />
        </Link>
      ))}
    </nav>
  );
};

export default Menu;
