import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { includes } from 'lodash';
import { NavigateFunction } from 'react-router';
import moment from 'moment/moment';
import { Button } from '@ui-modules';
import { IStatusProperty } from '@common/interfaces/Common';
import { BULK_MODE_MANUALLY } from '@pages/BatchDetailsPage/constants';
import { DEFAULT_DATE_FORMAT } from '@common/constants';
import { IDropdownOption } from '@ui-modules/types';
import { CroppedText } from '@components';
import { TransportContract } from '@common/interfaces';

const getBookingStateBtn = (record: string): JSX.Element => {
  const statusProperties: IStatusProperty = {
    not_processed: {
      className: 'btn not_processed',
      variant: 'submit',
      text: 'Not processed',
    },
    quotes_request_sent: {
      className: 'btn quotes_request_sent',
      variant: 'transparent',
      text: 'Quotes request sent',
    },
    bid_closed: {
      className: 'btn bid_closed',
      variant: 'transparent',
      text: 'Bid closed',
    },
    processed: {
      className: 'btn processed',
      variant: 'transparent',
      text: 'Processed',
    },
    assigned: {
      className: 'btn assigned',
      variant: 'transparent',
      text: 'Assigned',
    },
    assignment_notified: {
      className: 'btn assignment_notified',
      variant: 'submit',
      text: 'Assignment notified',
    },
    cancelled: {
      className: 'btn cancelled',
      variant: 'submit',
      text: 'Cancelled',
    },
    defaultProperty: {
      className: 'btn btn-default',
      variant: 'submit',
    },
  };

  const additionalProperties = statusProperties[record] || statusProperties.defaultProperty;

  /* eslint-disable react/jsx-props-no-spreading */
  return <Button text={record} {...additionalProperties} />;
};

const getColumns = (
  bulkMode: string | undefined,
  selectedFreights: string[],
  setSelectedFreights: React.Dispatch<React.SetStateAction<string[]>>,
  navigate: NavigateFunction,
  batchUuid: string,
  selectedTab: string,
  contractFilter?: IDropdownOption | null,
) => [
  ...(bulkMode === BULK_MODE_MANUALLY
    ? [
        {
          dataIndex: 'bulk_selector',
          key: 'bulk_selector',
          title: '',
          render: (record: string, data: any) => {
            return (
              <Tooltip title={!contractFilter ? 'Select contract filter to select bookings' : ''}>
                <Checkbox
                  checked={Boolean(includes(selectedFreights, data.uuid))}
                  disabled={Boolean(!contractFilter)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedFreights([...selectedFreights, data.uuid]);
                    } else {
                      setSelectedFreights(selectedFreights.filter((item) => item !== data.uuid));
                    }
                  }}
                  className="bulk-checkbox"
                />
              </Tooltip>
            );
          },
        },
      ]
    : []),
  {
    dataIndex: 'reference_code',
    key: 'reference_code',
    title: 'ID',
    width: 90,
    sorter: true,
  },
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Request date',
    sorter: true,
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'transport_contract',
    key: 'transport_contract',
    title: 'Transport contract',
    sorter: true,
    render: (record: TransportContract) =>
      record ? <CroppedText text={record.name} maxLength={30} /> : '-',
  },
  {
    dataIndex: 'pickup_date',
    key: 'pickup_date',
    title: 'Start loading date',
    sorter: true,
    render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
  },
  {
    dataIndex: 'route',
    key: 'route',
    title: 'Route',
    sorter: true,
    render: (record: string) => (record ? <CroppedText text={record} maxLength={30} /> : '-'),
  },
  ...(selectedTab === 'Processed'
    ? [
        {
          dataIndex: 'deadline',
          key: 'deadline',
          title: 'Submission deadline',
          render: (record: string) =>
            record ? moment.utc(record).format(DEFAULT_DATE_FORMAT) : '-',
        },
      ]
    : []),
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: 150,
    render: (record: string) => getBookingStateBtn(record),
  },
  {
    width: 100,
    render: (_record: string, data: any) => (
      <Button
        onClick={() => {
          navigate(`/requests/${data.uuid}`, { state: { batchId: batchUuid } });
        }}
        className="view-button"
        text="View"
      />
    ),
  },
];

export { getColumns };
