/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, createContext, useContext, useState, useMemo } from 'react';
import { IFormattedFacility } from '@common/interfaces';

const initialState = {
  facility: {
    name: '',
    agency: '',
    city: '',
    country: '',
    facilityId: 0,
    email: '',
  },
  setFacility: (_: IFormattedFacility) => {},
};

const GlobalContext = createContext(initialState);

interface GlobalProviderProps {
  children?: React.ReactNode;
}

const GlobalProvider: FC<GlobalProviderProps> = ({ children }) => {
  const [facility, setFacility] = useState<IFormattedFacility>(initialState.facility);

  const contextValue = useMemo(
    () => ({
      facility,
      setFacility,
    }),
    [facility],
  );

  return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

const useGlobalContext = () => useContext(GlobalContext);

export { GlobalProvider, useGlobalContext };
