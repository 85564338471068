/* eslint-disable @typescript-eslint/no-shadow */
import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { DataItem } from '@common/interfaces/Common';
import { useOutsideClick } from '@common/hooks';
import cn from 'classnames';
import { ArrowRoundIcon } from '../../icons';
import css from './styles.module.scss';

interface IDropdownGrouped {
  disabled?: boolean;
  maxCount?: number;
  onChange: (values: Array<ItemOrdered>) => void;
  options: Array<DataItem>;
  values: Array<ItemOrdered>;
}

export interface ItemOrdered extends DataItem {
  order: number;
}

interface IOrderVocab {
  [key: number]: string;
}

const ORDER_VOCAB: IOrderVocab = {
  1: '1st',
  2: '2nd',
};

const DropdownGrouped: FC<IDropdownGrouped> = ({
  disabled = false,
  maxCount,
  onChange,
  options,
  values,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [innerValues, setInnerValues] = useState(values);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  const handleChange = (e: ChangeEvent<HTMLInputElement>, option: DataItem) => {
    if (e.target.checked) {
      const newValues = [...innerValues];
      newValues.push({ ...option, order: innerValues.length + 1 });
      setInnerValues(newValues);
      onChange(newValues);
    } else {
      // TO DO: Need expand logic for correct ordering in cases, when we have >2 items. Use sort by order and map for reordering
      const newValues = [...innerValues]
        .filter(({ value }) => value !== option.value)
        .map((option, idx) => {
          return { ...option, order: idx + 1 };
        });
      setInnerValues(newValues);
      onChange(newValues);
    }
  };

  const fieldData =
    innerValues.length > 0 ? (
      innerValues.map(({ label }) => label).join(', ')
    ) : (
      <div className={css.placeholder}>Select...</div>
    );

  return (
    <div className={cn(css.container, { [css.disabled]: disabled })} ref={wrapperRef}>
      <div className={css.field} onClick={() => setIsOpen(!isOpen)}>
        {fieldData}
        <ArrowRoundIcon className={css.icon} type={isOpen ? 'top' : 'bottom'} />
      </div>
      {isOpen && (
        <div className={css.menu}>
          {options.map((option) => {
            const { label, value } = option;
            const fieldId = `field-${value}`;
            const checkedItem = innerValues.find(({ value }) => value === option.value);
            const order = checkedItem ? `(${ORDER_VOCAB[checkedItem.order]})` : null;
            const disabledSelecting = Boolean(
              !checkedItem && maxCount && innerValues.length >= maxCount,
            );

            return (
              <div className={cn(css.item, { [css.disabled]: disabledSelecting })} key={value}>
                <input
                  checked={Boolean(checkedItem)}
                  className={css.input}
                  id={fieldId}
                  onChange={(e) => handleChange(e, option)}
                  type="checkbox"
                  disabled={disabledSelecting}
                />
                <label className={css.label} htmlFor={fieldId}>
                  {label}
                  {order ? <div className={css.order}>{order}</div> : null}
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropdownGrouped;
