import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type UserType = 'azure' | 'b2c';

export default function useParamsParse() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const facility = query.get('facility_id');
  const country = query.get('facility_country') || '';
  const isB2CUser = query.get('is_b2c_user');

  let userType: UserType = 'azure';

  if (isB2CUser === 'True') userType = 'b2c';

  useEffect(() => {
    if (facility) {
      localStorage.setItem('facility', facility);
      localStorage.setItem('country', country);
    }
  }, [facility, country]);

  return {
    isParsed: !!facility,
    userType,
  };
}
