import { useQuery } from 'react-query';
import { Spin } from 'antd';
import { MaintenanceBanner, useBanner } from '@unbooking/ui-kit';
import { useParamsParse } from '@common/hooks';
import { useRepository } from '@context';
import { DoDataResponse, Switches } from '@common/interfaces';
import {
  LetterIcon,
  BoxIcon,
  CalendarIcon,
  CheckIcon,
  GraphIcon,
  PolicyIcon,
  VendorIcon,
} from '@assets/svg';
import { Menu } from '@components';
import css from './styles.module.scss';

const EntryPage = () => {
  useParamsParse();
  const { freightRepository } = useRepository();
  const facilityId = localStorage.getItem('facility')!;
  const { bannerMessage, closeBanner } = useBanner({
    switchName: 'maintenance_notice_freight',
    switchesUrl: `${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`,
    monolithApiKey: String(process.env.REACT_APP_UNBH_API_KEY),
  });

  const { data: switches } = useQuery<Switches>(['switches'], () =>
    freightRepository.getSwitches(),
  );

  const { data, isLoading } = useQuery<DoDataResponse>(['do-data', facilityId], () =>
    freightRepository.getDOData(facilityId),
  );

  const totalNotProcessedRequests = data
    ? data.no_of_not_processed_bookings.SPOT + data.no_of_not_processed_bookings.BATCH
    : 0;

  const totalBidClosedRequests = data
    ? data.no_of_bid_closed_bookings.SPOT + data.no_of_bid_closed_bookings.BATCH
    : 0;

  const buttons = [
    {
      label: 'Freight requests',
      id: 'requests',
      description: 'Manage requests of transports',
      link: '/requests',
      icon: <CalendarIcon />,
      tags: [
        ...(totalNotProcessedRequests
          ? [
              {
                label: 'not processed',
                color: 'red',
                count: totalNotProcessedRequests,
              },
            ]
          : []),
        ...(totalBidClosedRequests
          ? [
              {
                label: 'bid closed',
                color: 'limeade',
                count: totalBidClosedRequests,
              },
            ]
          : []),
      ],
    },
    {
      label: 'Letters',
      id: 'letters',
      description: 'Create and track awards and termination letters',
      link: '/letters',
      icon: <LetterIcon />,
    },
    ...(switches?.vendors_management
      ? [
          {
            label: 'Vendor management',
            id: 'vendors',
            description: 'View vendors data, related contracts and  analytics',
            link: '/vendors',
            icon: <VendorIcon />,
          },
        ]
      : []),
    {
      label: 'Business dashboard and reports',
      id: 'reports',
      description: 'Analyze data related to quotations',
      link: '/reports',
      icon: <GraphIcon />,
    },
  ];

  return (
    <div className={css.container}>
      {bannerMessage ? <MaintenanceBanner message={bannerMessage} onClose={closeBanner} /> : null}
      <div className={css.title}>
        Welcome to your Freight Management <br /> Digital Office
      </div>
      <div className={css.country}>{data?.country_name}</div>
      <div className={css.notes}>Last 30 days</div>
      <Spin spinning={isLoading}>
        <div className={css.content}>
          <div className={css.widget}>
            <div className={css.box}>
              <div className={css.label}>
                <BoxIcon /> Total requests
              </div>
              <div className={css.count}>{data?.no_of_request_in_last_30_days}</div>
            </div>
            <div className={css.box}>
              <div className={css.label}>
                <CheckIcon /> MT assigned
              </div>
              <div className={css.count}>{data?.mt_selected_in_last_30_days}</div>
            </div>
            <div className={css.box}>
              <div className={css.label}>
                <PolicyIcon /> Quotations received
              </div>
              <div className={css.count}>{data?.quotations_received_in_last_30_days}</div>
            </div>
          </div>
          <Menu buttons={buttons} />
        </div>
      </Spin>
    </div>
  );
};

export default EntryPage;
