/* eslint-disable default-case */
import React from 'react';

interface IArrowRoundIcon {
  type: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
}

const ArrowRoundIcon = ({ type, className }: IArrowRoundIcon): JSX.Element => {
  let transform;

  switch (type) {
    case 'top':
      transform = 'rotateZ(-180deg)';
      break;
    case 'bottom':
      transform = 'none';
      break;
    case 'right':
      transform = 'rotateZ(-90deg)';
      break;
    case 'left':
      transform = 'rotateZ(90deg)';
      break;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.242"
      height="16.242"
      viewBox="0 0 16.242 16.242"
      style={{ transform }}
      className={className}
    >
      <path
        id="Tracciato_47"
        data-name="Tracciato 47"
        d="M10.2,14.637a.757.757,0,0,0,0,1.066l3.737,3.725a.753.753,0,0,0,1.038.023l3.682-3.67A.752.752,0,1,0,17.6,14.716l-3.162,3.116-3.166-3.2a.754.754,0,0,0-1.066,0Z"
        transform="translate(-6.314 -8.284)"
      />
      <path
        id="Tracciato_48"
        data-name="Tracciato 48"
        d="M3.375,11.5A8.121,8.121,0,1,0,11.5,3.375,8.12,8.12,0,0,0,3.375,11.5ZM16.353,6.639a6.869,6.869,0,1,1-9.714,9.714,6.869,6.869,0,1,1,9.714-9.714Z"
        transform="translate(-3.375 -3.375)"
      />
    </svg>
  );
};

export default ArrowRoundIcon;
