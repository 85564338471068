/* istanbul ignore file */

import React, { FC, useState } from 'react';
import { DatePicker, message, Modal, Spin } from 'antd';
import { Button } from '@ui-modules';
import moment from 'moment';
import { useMutation } from 'react-query';
import FileSaver from 'file-saver';
import { ReactComponent as Calendar } from '@assets/svg/calendar.svg';
import { IListParams } from '@common/interfaces';
import { useRepository } from '@context';
import './styles.scss';
import { SwitcherExpanded } from '@components';

const { RangePicker } = DatePicker;

interface IRangeDatePicker {
  from_date: string;
  to_date: string;
}

interface IBatchReportProps {
  facilityId: number;
  isModalVisible: boolean;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  batchId: string;
}

const BatchReport: FC<IBatchReportProps> = ({
  facilityId,
  isModalVisible,
  setIsModalVisible,
  batchId,
}) => {
  const [isAwarded, setIsAwarded] = useState(true);
  const [filterDates, setFilterDates] = useState<IRangeDatePicker | null>(null);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const { freightRepository } = useRepository();

  const getExportFileMutation = useMutation(
    (params: IListParams) =>
      isAwarded
        ? freightRepository.getSelectedBatchesReportFile(facilityId, batchId, params)
        : freightRepository.getAllBatchesReportFile(facilityId, batchId, params),
    {
      onSuccess: (data: string) => {
        try {
          FileSaver.saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
            `award_report_${filterDates?.from_date}-${filterDates?.to_date}.xlsx`,
          );
          setIsModalVisible(false);
          setFilterDates(null);
          setIsAwarded(true);
        } catch (error) {
          message.error('Parsing file error');
        }
      },
      onError: () => {
        message.error('Request failed, please try again.');
      },
      onSettled: () => {
        setIsRequesting(false);
      },
    },
  );

  return (
    <Modal
      footer={null}
      title={
        <div className="modal-title">
          <p>Export cycles report</p>
        </div>
      }
      width="33%"
      onCancel={() => {
        setIsModalVisible(false);
        setIsAwarded(true);
        setFilterDates(null);
      }}
      centered
      destroyOnClose
      visible={isModalVisible}
    >
      <div className="batch-report">
        <div className="award-toggle">
          <SwitcherExpanded
            checked={isAwarded}
            onChange={() => setIsAwarded(!isAwarded)}
            disabled={isRequesting}
            defaultChecked
            icon="award"
          />
          <p>
            Export <strong>only</strong> awarded cycles
          </p>
        </div>
        <p>Filter by award day range (date on which award was created)</p>
        <div>
          <span className="range-label">Range</span>
          <RangePicker
            format="DD-MM-YYYY"
            disabled={isRequesting}
            onChange={(value) => {
              if (value === null) return setFilterDates(null);
              return setFilterDates({
                from_date: value[0]?.format('YYYY-MM-DD') || '',
                to_date: value[1]?.format('YYYY-MM-DD') || '',
              });
            }}
            disabledDate={(current) => current && current > moment().endOf('day')}
            separator="to"
            suffixIcon={<Calendar className="calendar-icon" />}
          />
        </div>
        {isRequesting ? (
          <div className="loader-info">
            <Spin spinning />
            <p>Award batch report is being generated...</p>
          </div>
        ) : (
          <Button
            onClick={() => {
              getExportFileMutation.mutate({
                ...filterDates,
              });
              setIsRequesting(true);
            }}
            className="export-button"
            variant="submit"
            disabled={!filterDates}
            text="Confirm"
          />
        )}
      </div>
    </Modal>
  );
};

export default BatchReport;
