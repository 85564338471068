/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, FC } from 'react';
import { message, Modal, Spin } from 'antd';
import { Button, Dropdown } from '@ui-modules';
import OverviewList from '@pages/RequestsDetailsPage/components/QuotationManagement/components/OverviewList';
import { useRepository } from '@context/repository.context';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  IDetailsRequest,
  IProvidersQuotationResponse,
  ISendProvidersQuotationPayload,
} from '@common/interfaces';
import { IDetailsUpdate } from '@common/interfaces/Common';
import ConfirmationModal from '@pages/RequestsDetailsPage/components/QuotationManagement/components/ConfirmationModal';
import {
  NormalizedQuotation,
  getCurrencies,
  getNormalizedQuotations,
} from '@pages/RequestsDetailsPage/components/QuotationManagement/utils';
import AssignmentDetails from '@pages/RequestsDetailsPage/components/QuotationManagement/components/AssignmentDetails';
import {
  getDropdownStatusComponentProps,
  onErrorHandler,
  statusDropdownCustomStyles,
} from '@pages/RequestsDetailsPage/components/RequestsDetails/utils';
import { Tooltip } from 'react-tooltip';
import DestinationsBlock from './components/DestinationsBlock';
import AssignmentList from './components/AssignmentList';
import AssignmentOverview from './components/AssignmentOverview';

interface IQuotationManagement {
  details: IDetailsRequest;
}

const isMultipleSelection = (quotations: NormalizedQuotation[]) => {
  const selectedQuotations = quotations.filter(({ selected }) => selected);
  const set = new Set();
  // eslint-disable-next-line no-restricted-syntax
  for (const obj of selectedQuotations) {
    if (set.has(obj.line_item_quotation_uuid)) {
      return true;
    }
    set.add(obj.line_item_quotation_uuid);
  }
  return false;
};

const getMultiplyUuids = (items: NormalizedQuotation[]) => {
  const groupedItems = items.reduce((result, item) => {
    const key = item.line_item_quotation_uuid;
    if (!result[key]) {
      result[key] = { key, items: [] };
    }
    result[key].items.push(item.uuid);
    return result;
  }, {} as Record<string, { key: string; items: string[] }>);

  return Object.values(groupedItems)
    .filter((group) => group.items.length > 1)
    .reduce((result, item) => {
      result.push(...item.items);
      return result;
    }, [] as Array<string>);
};

const QuotationManagement: FC<IQuotationManagement> = ({ details }) => {
  const { freightRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const { id: uuid } = useParams<{ id: string }>();

  const [quotations, setQuotations] = useState<NormalizedQuotation[]>([]);
  const [defaultQuotations, setDefaultQuotations] = useState<NormalizedQuotation[]>([]);

  const [isAssignmentFromAdminPossible, setIsAssignmentFromAdminPossible] = useState(false);
  const [isAssignmentPossible, setIsAssignmentPossible] = useState(false);
  const [isLumpSum, setIsLumpSum] = useState(true);

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isQuotationProcessing, setIsQuotationProcessing] = useState(false);

  const [isConfirmMultipleOpen, setIsConfirmMultipleOpen] = useState(false);
  const [multipleSelectionReason, setMultipleSelectionReason] = useState('');
  const [multipleSelection, setMultipleSelection] = useState(false);

  const { data, isLoading, refetch } = useQuery<IProvidersQuotationResponse>(
    [`quotation-management-${facilityId}-${uuid}`],
    () => freightRepository.getProvidersQuotation(facilityId, uuid ?? ''),
  );

  const selectedQuotations = quotations.filter(({ selected }) => selected);
  const isButtonActive = quotations.some(({ selected }) => selected);
  const currencies = data && getCurrencies(data);

  useEffect(() => {
    if (data) {
      const quotations = getNormalizedQuotations(data.quotations_for_line_item);

      const isMultiple = isMultipleSelection(quotations);
      if (isMultiple) {
        setIsLumpSum(false);
        setMultipleSelection(true);
      }

      setQuotations(quotations);
      setDefaultQuotations(quotations);
      setIsAssignmentPossible(data.is_assignment_possible);
      setIsAssignmentFromAdminPossible(data.is_assignment_possible);
    }
  }, [data]);

  // Note: check quotations for calculation of multiple selection
  const [prevQuotations, setPrevQuotations] = useState<NormalizedQuotation[]>([]);
  const [firstLoading, setFirstLoading] = useState(true);
  useEffect(() => {
    if (!firstLoading) {
      const isMultiple = isMultipleSelection(quotations);
      if (isMultiple && !multipleSelection) {
        setIsConfirmMultipleOpen(true);
      }
      if (!isMultiple) {
        setMultipleSelection(false);
        setMultipleSelectionReason('');
        setPrevQuotations(quotations);
      }
    } else {
      setFirstLoading(false);
      setPrevQuotations(quotations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotations]);

  const sendProvidersQuotation = useMutation(
    (payload: ISendProvidersQuotationPayload) =>
      freightRepository.sendProvidersQuotation(facilityId, uuid || '', payload),
    {
      onSuccess: () => {
        setIsConfirmationVisible(false);
        message.success(
          data?.is_batch
            ? 'Assignment saved successfully'
            : 'Email notification has been send to the selected providers',
        );
        refetch();
      },
      onError: () => {
        message.error('Request failed, please try again.');
      },
      onSettled: () => {
        setIsQuotationProcessing(false);
      },
    },
  );

  const onSubmit = () => {
    setIsQuotationProcessing(true);
    const selectedUuids = selectedQuotations.map(({ uuid }) => uuid);
    const multipleUuids = getMultiplyUuids(selectedQuotations);
    sendProvidersQuotation.mutate({
      uuid: selectedUuids,
      multiple_object_selection_reason: multipleSelectionReason || undefined,
      multiple_objects_selection_uuids: multipleUuids.length > 1 ? multipleUuids : undefined,
    });
  };

  const updateRequestMutation = useMutation(
    (detailsUpdated: IDetailsUpdate) =>
      freightRepository.updateRequestDetails(facilityId, uuid as string, detailsUpdated),
    {
      onSuccess: () => {
        refetch();
      },
      onError: onErrorHandler,
    },
  );

  const statusChangeHandler = (value: string) => {
    updateRequestMutation.mutate({ status: value });
  };

  const multipleSelectionModalCancelHandler = () => {
    setQuotations(prevQuotations);
    setIsConfirmMultipleOpen(false);
  };

  const multipleSelectionModalConfirmHandler = () => {
    setIsConfirmMultipleOpen(false);
    setIsLumpSum(false);
    setMultipleSelection(true);
  };

  return (
    <Spin spinning={isLoading}>
      <div className="booking-details-container hbh-container">
        <div className="details-header">
          <div className="dropdown-container">
            <Dropdown
              styles={statusDropdownCustomStyles}
              components={getDropdownStatusComponentProps(
                !details?.allowed_transitions?.length,
                details?.status ?? '',
              )}
              isSearchable={false}
              onChange={(option) => {
                statusChangeHandler(option.value);
              }}
              options={details?.allowed_transitions as any}
              value={{
                label: details?.status_name,
                value: details?.status,
              }}
            />
          </div>
        </div>

        <OverviewList data={data?.providers_quotations_overview ?? []} />

        {quotations.length !== 0 ? (
          <>
            <AssignmentList
              currencies={currencies ?? {}}
              isAssignmentFromAdminPossible={isAssignmentFromAdminPossible}
              isAssignmentPossible={isAssignmentPossible}
              isLumpSum={isLumpSum}
              multipleSelection={multipleSelection}
              quotations={quotations}
              referenceCode={details.reference_code}
              refetch={refetch}
              resetSelectedItemsToDefault={() => setQuotations(defaultQuotations)}
              setIsAssignmentPossible={setIsAssignmentPossible}
              setIsLumpSum={setIsLumpSum}
              setQuotations={setQuotations}
              status={details.status}
            />

            {selectedQuotations.length !== 0 ? (
              <>
                <AssignmentOverview quotations={selectedQuotations} currencies={currencies ?? {}} />
                <DestinationsBlock quotations={selectedQuotations} />
                <AssignmentDetails quotations={selectedQuotations} />
              </>
            ) : null}

            {isAssignmentFromAdminPossible && (
              <Button
                onClick={() => setIsConfirmationVisible(true)}
                variant="submit"
                disabled={!isButtonActive}
                className="quotation-button"
                text="CONFIRM FREIGHT ASSIGNMENT"
              />
            )}
          </>
        ) : null}
      </div>
      <ConfirmationModal
        isConfirmationVisible={isConfirmationVisible}
        setIsConfirmationVisible={setIsConfirmationVisible}
        onSubmitHandler={onSubmit}
        isButtonDisabled={isQuotationProcessing}
        isBatch={data?.is_batch || false}
      />
      <Modal
        footer={null}
        title={
          <div className="modal-title">
            <p>Multiple selection confirmation</p>
          </div>
        }
        width="32%"
        centered
        visible={isConfirmMultipleOpen}
        onCancel={multipleSelectionModalCancelHandler}
      >
        <p className="modal-text">Do you want to assign multiple transporter for the selection?</p>
        <p className="modal-text">Please add the note for the records.</p>
        <textarea
          value={multipleSelectionReason}
          placeholder="Insert here..."
          className="textarea-multiple-selection-reason"
          onChange={(e) => setMultipleSelectionReason(e.target.value)}
        />
        <div className="modal-buttons">
          <Button variant="danger" onClick={multipleSelectionModalCancelHandler} text="Cancel" />
          <Button variant="submit" onClick={multipleSelectionModalConfirmHandler} text="Confirm" />
        </div>
      </Modal>
      <Tooltip id="global-tooltip" />
    </Spin>
  );
};

export default QuotationManagement;
