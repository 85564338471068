import React, { FC } from 'react';
import { Menu, Radio } from 'antd';
import {
  BULK_MODE_ALL,
  BULK_MODE_CONTRACTS,
  BULK_MODE_MANUALLY,
} from '@pages/BatchDetailsPage/constants';
import './styles.scss';

interface IMenuItemProps {
  bulkMode: string | undefined;
  setBulkMode: React.Dispatch<React.SetStateAction<string | undefined>>;
  freightType: string;
  isMultipleContracts: boolean;
}

const MenuList: FC<IMenuItemProps> = ({
  freightType,
  bulkMode,
  setBulkMode,
  isMultipleContracts,
}) => {
  if (bulkMode === BULK_MODE_MANUALLY) return null;

  return (
    <Menu>
      <Menu.Item
        key={BULK_MODE_MANUALLY}
        onClick={() => setBulkMode(BULK_MODE_MANUALLY)}
        className="bulk-menu-item"
      >
        <Radio checked={bulkMode === BULK_MODE_MANUALLY} className="bulk-radio" />
        <span>Select freights manually</span>
      </Menu.Item>
      {isMultipleContracts ? (
        <Menu.Item
          key={BULK_MODE_CONTRACTS}
          onClick={() => setBulkMode(BULK_MODE_CONTRACTS)}
          className="bulk-menu-item"
        >
          <Radio checked={bulkMode === BULK_MODE_CONTRACTS} className="bulk-radio" />
          <span>Select freights related to transport contract</span>
        </Menu.Item>
      ) : (
        <Menu.Item
          key={BULK_MODE_ALL}
          onClick={() => setBulkMode(BULK_MODE_ALL)}
          className="bulk-menu-item"
        >
          <Radio checked={bulkMode === BULK_MODE_ALL} className="bulk-radio" />
          <span>Send for all the &apos;{freightType}&apos; freights</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default MenuList;
