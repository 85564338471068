/* istanbul ignore file */

import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router';

type IScrollToTop = {
  children: ReactElement<any, any>;
};

const ScrollToTop = ({ children }: IScrollToTop) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

export default ScrollToTop;
