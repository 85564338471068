import React, { FC } from 'react';
import './styles.scss';

interface IProgressBar {
  value?: number;
  customValue?: string;
  fraction?: number;
}

const ProgressBar: FC<IProgressBar> = ({ value = 0, customValue, fraction }) => {
  const part = fraction && customValue ? fraction * 100 : value;

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-line" style={{ width: `${part}%` }} />
      <div className="progress-bar-count">{customValue || `${value}%`}</div>
    </div>
  );
};

export default ProgressBar;
