import { IServerSwitchesResponse } from '@common/interfaces';

const convertSwitches = (list?: IServerSwitchesResponse) => {
  const switches: any = {};

  if (list) {
    list.results.forEach(({ active, name }) => {
      switches[name] = active;
    });
  }

  return switches;
};

export default convertSwitches;
