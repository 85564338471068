/* eslint-disable @typescript-eslint/no-shadow */
import { DetailsContainer } from '@ui-modules';
import {
  NormalizedQuotation,
  getCalculatedTotals,
  getFormattedMoney,
  groupByDestination,
  groupByProvider,
} from '@pages/RequestsDetailsPage/components/QuotationManagement/utils';
import { FC } from 'react';
import './styles.scss';

interface IDestinationsBlock {
  quotations: Array<NormalizedQuotation>;
}

const DestinationsBlock: FC<IDestinationsBlock> = ({ quotations }) => {
  const groupedQuotations = getCalculatedTotals(groupByDestination(quotations));

  return (
    <div className="destinations-container">
      {groupedQuotations.map(({ label, items, sum, sum_mt }) => {
        const groupedQuotationsByProvider = getCalculatedTotals(groupByProvider(items));

        const details = [
          {
            label: 'OVERALL COST',
            content: (
              <>
                <div className="total-overall">{getFormattedMoney(sum)}</div>
                <div className="total-overall">{getFormattedMoney(sum_mt, 'USD/MT')}</div>
              </>
            ),
          },
          ...(groupedQuotationsByProvider.map(({ label, sum, sum_mt }) => ({
            label,
            content: (
              <>
                <div>{getFormattedMoney(sum)}</div>
                <div>{getFormattedMoney(sum_mt, 'USD/MT')}</div>
              </>
            ),
          })) || []),
        ];

        return (
          <div key={label}>
            <h3 className="hbh-details-title">Destination - {label}</h3>
            <DetailsContainer items={details} />
          </div>
        );
      })}
    </div>
  );
};

export default DestinationsBlock;
