/* eslint-disable @typescript-eslint/naming-convention */
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Modal } from 'antd';
import { Table } from '@ui-modules';
import './styles.scss';
import { ILineItemCurrency, ILineItemsTruck } from '@common/interfaces/Common';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { DEFAULT_DATE_FORMAT, DEFAULT_WEIGHT_FORMAT } from '@common/constants';
import EmptyBox from '@components/EmptyBox';
import {
  NormalizedQuotation,
  getFormattedMoney,
} from '@pages/RequestsDetailsPage/components/QuotationManagement/utils';

export interface ILineItemInfoModal {
  data: NormalizedQuotation;
  index: number;
}

interface ILineItemModal {
  lineItemInfoSelected: ILineItemInfoModal;
  setLineItemInfoSelected: Dispatch<SetStateAction<ILineItemInfoModal | null>>;
  currencies: ILineItemCurrency;
}

const columns = () => [
  {
    title: 'Truck',
    dataIndex: 'truck_number',
    key: 'truck_number',
  },
  {
    title: 'Truck type',
    dataIndex: 'truck_type',
    key: 'truck_type',
  },
  {
    title: 'Plate number',
    dataIndex: 'plate_number',
    key: 'plate_number',
  },
  {
    title: 'Driver name',
    dataIndex: 'driver_name',
    key: 'driver_name',
  },
  {
    title: 'Driver surname',
    dataIndex: 'driver_surname',
    key: 'driver_surname',
  },
];

const LineItemModal: FC<ILineItemModal> = ({
  lineItemInfoSelected,
  setLineItemInfoSelected,
  currencies,
}) => {
  const {
    index,
    data: { trucks, volume, provider_legal_name, weight, quote, pickup_date, dropoff_date },
  } = lineItemInfoSelected;

  const getTrucksData = () =>
    trucks.map(
      ({ driver_name, driver_surname, plate_number, truck_type }: ILineItemsTruck, key: number) => {
        return {
          driver_name: driver_name || '- - - - -',
          driver_surname: driver_surname || '- - - - -',
          plate_number: plate_number || '- - - - -',
          truck_type: truck_type || '- - - - -',
          truck_number: key + 1,
          id: key,
        };
      },
    );

  return (
    <Modal
      footer={null}
      title={
        <div className="modal-title">
          <p>
            Line item {index + 1}
            {' - '}
            <span>
              {volume}
              {' m3 - '}
              <NumberFormat displayType="text" value={weight} {...DEFAULT_WEIGHT_FORMAT} /> MT
            </span>
            {' - '}
            {provider_legal_name}
          </p>
        </div>
      }
      width="60%"
      centered
      visible={Boolean(lineItemInfoSelected)}
      onCancel={() => setLineItemInfoSelected(null)}
    >
      <div className="overall-info">
        <div className="overall-block">
          Overall cost: <span>{getFormattedMoney(quote, currencies[provider_legal_name])}</span>
        </div>
        <div className="overall-block">
          Start loading date:{' '}
          <span>{pickup_date ? moment(pickup_date).format(DEFAULT_DATE_FORMAT) : '- - - - -'}</span>
        </div>
        <div className="overall-block">
          Delivery completion due date:{' '}
          <span>
            {dropoff_date ? moment(dropoff_date).format(DEFAULT_DATE_FORMAT) : '- - - - -'}
          </span>
        </div>
      </div>
      <div className="overall-table">
        <Table
          variant="dark"
          data={getTrucksData()}
          columns={columns()}
          locale={{
            emptyText: <EmptyBox />,
          }}
        />
      </div>
    </Modal>
  );
};

export default LineItemModal;
