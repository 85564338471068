/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { useRepository } from '@context/repository.context';
import { Spin, message, Modal, Tooltip as TooltipAntd } from 'antd';
import { Form, FormStateAccessor } from 'informed';
import moment from 'moment';
import { DetailsContainer, Dropdown, Button } from '@ui-modules';
import './styles.scss';
import QuotationModal from '@components/QuotationModal';
import {
  IContractsListResponse,
  IDestination,
  IDetailsRequest,
  IGoodCategoriesData,
  IGoodCategoriesResponse,
  ILineItemDestination,
  ILoadingUnitsData,
  ILoadingUnitsResponse,
  IProviders,
  IQuotationPayload,
  PostedLineItem,
} from '@common/interfaces';
import {
  IDetailsItem,
  DetailsConfigItem,
  IDetailsUpdate,
  IQuotationSubmissionDate,
  IRequestCancelPatch,
} from '@common/interfaces/Common';
import { detailsConfig } from '@pages/RequestsDetailsPage/components/RequestsDetails/detailsConfig';
import {
  getDropdownStatusComponentProps,
  statusDropdownCustomStyles,
  getInputComponent,
  parseToNestedObject,
  getValidationSchema,
  onErrorHandler,
} from '@pages/RequestsDetailsPage/components/RequestsDetails/utils';

import { SECONDARY_DATE_FORMAT } from '@common/constants';
import { IDropdownOption } from '@components/AsyncDropdownField';
import { Tooltip } from 'react-tooltip';
import { AsyncDropdownField, CroppedText } from '@components';
import { CalendarIcon, DocIcon } from '@assets/svg';
import PointBlock, { Point } from './components/PointBlock';

interface IProps {
  setPageTitle: React.Dispatch<
    React.SetStateAction<{
      reference_code: string;
      volume: number;
      weight: number;
    }>
  >;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  setDetails: React.Dispatch<React.SetStateAction<IDetailsRequest>>;
  setSubmissionDate: React.Dispatch<React.SetStateAction<IQuotationSubmissionDate | null>>;
}

const convertToPoints = (items: Array<ILineItemDestination>) => {
  return items.map(({ destination, line_items, recipient }) => {
    return {
      destination,
      lineItems: line_items,
      id: destination.uuid,
      recipient,
    };
  });
};

const pointsOrdering = (points: Array<Point>) => {
  let orderNumber = 1;
  return points.map((point) => {
    const current = point;
    current.lineItems = [...point.lineItems].map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.order = orderNumber;
      orderNumber += 1;
      return item;
    });
    return current;
  });
};

const getTitle = (requestDetails: IDetailsRequest) => {
  const { reference_code, destinations_and_line_items } = requestDetails;

  const totals = destinations_and_line_items.reduce(
    (prev, current) => {
      return {
        total_weight:
          prev.total_weight +
          current.line_items.reduce((prev_item, current_item) => {
            const tempWeight = current_item.weight || 0;
            const tempUnits = current_item.number_of_loading_units || 0;
            const totalValue =
              current_item.weight_volume_unit === 'Loading unit'
                ? tempWeight * tempUnits
                : tempWeight;
            return prev_item + totalValue;
          }, 0),
        total_volume:
          prev.total_volume +
          current.line_items.reduce((prev_item, current_item) => {
            const tempVolume = current_item.volume || 0;
            const tempUnits = current_item.number_of_loading_units || 0;
            const totalValue =
              current_item.weight_volume_unit === 'Loading unit'
                ? tempVolume * tempUnits
                : tempVolume;
            return prev_item + totalValue;
          }, 0),
      };
    },
    { total_weight: 0, total_volume: 0 },
  );

  return {
    reference_code,
    volume: totals.total_volume,
    weight: totals.total_weight,
  };
};

const RequestDetails = ({ setPageTitle, setStatus, setDetails, setSubmissionDate }: IProps) => {
  const facilityId = +localStorage.getItem('facility')!;
  const { freightRepository } = useRepository();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [deletingItems, setDeletingItems] = useState<Array<string>>([]);
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const [points, setPoints] = useState<Array<Point>>([]);
  const [cancellationRemarks, setCancellationRemarks] = useState('');

  const { id: requestId = '' } = useParams<{ id: string }>();

  const {
    data: requestDetails,
    isLoading,
    refetch: detailsRefetch,
  } = useQuery<IDetailsRequest>(
    `request-${requestId}`,
    () => freightRepository.getRequestDetails(facilityId, requestId || ''),
    {
      retry: 2,
      onError: (err: any) => {
        if (err.response.status === 404) {
          message.error('Request with such id was not found');

          setTimeout(() => {
            navigate('/requests');
          }, 1000);
        }
      },
    },
  );

  useEffect(() => {
    if (requestDetails) {
      setPageTitle(getTitle(requestDetails));
      setStatus(requestDetails.status);
      setPoints(convertToPoints(requestDetails?.destinations_and_line_items ?? []));
      setSubmissionDate(requestDetails?.quotations_submission_date);
      setDetails(requestDetails);
    }

    localStorage.removeItem('SUCCESS_REDIRECT_URL');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDetails, setPageTitle, setDetails, setStatus]);

  const mapDetailItems = (source: IDetailsRequest, items: DetailsConfigItem[]): IDetailsItem[] => {
    return items.map((item) => ({
      label: item.label,
      content: item.content
        ? item.content(source[item.key], isEditMode)
        : (source[item.key] as string),
      className: item.className ?? '',
    }));
  };

  const renderDetailElements = (source: IDetailsRequest): JSX.Element[] | null => {
    if (!source) return null;

    return detailsConfig(isEditMode, freightRepository).map((section) => {
      return (
        <DetailsContainer
          key={section.section_title}
          title={section.section_title}
          className={section.className ?? ''}
          items={mapDetailItems(source, section.items)}
        />
      );
    });
  };

  const updateRequestMutation = useMutation(
    (data: IDetailsUpdate) => freightRepository.updateRequestDetails(facilityId, requestId, data),
    {
      onSuccess: () => {
        window.location.reload();
      },
      onError: onErrorHandler,
    },
  );

  const cancelRequestMutation = useMutation(
    (data: IRequestCancelPatch) => freightRepository.cancelRequest(facilityId, requestId, data),
    {
      onSuccess: () => {
        updateRequestMutation.mutate({ status: 'cancelled' });
      },
      onError: onErrorHandler,
    },
  );

  const sendQuotation = useMutation(
    (data: IQuotationPayload) =>
      freightRepository.sendQuotationRequest(facilityId, requestId as string, data),
    {
      onSuccess: () => {
        setIsModalOpen(false);
        message.success('The quotation request has been saved successfully.');
        detailsRefetch().then(() => false);
      },
      onError: onErrorHandler,
    },
  );

  const deleteLineItemMutation = useMutation(
    (lineItemUuid: string) => freightRepository.deleteLineItems(facilityId, lineItemUuid),
    {
      onError: onErrorHandler,
    },
  );

  const quotationSendHandler = (data: {
    deadline: Date;
    providers: IProviders[];
    transport_contract: string;
  }) => {
    if (!data.providers.length) {
      message.error('Enter at least 1 address email');
      return false;
    }
    if (!data.deadline) {
      message.error('Enter deadline date');
      return false;
    }

    const result: IQuotationPayload = {
      ...data,
      booking_uuid: requestId ?? '',
      deadline: moment(data.deadline).format('YYYY-MM-DDTHH:mm'),
      transport_contract: data.transport_contract,
    };

    return sendQuotation.mutate(result);
  };

  const statusChangeHandler = (option: IDropdownOption) => {
    if (
      option.value === 'cancelled' &&
      requestDetails &&
      requestDetails.status === 'quotes_request_sent' &&
      !requestDetails.quotations_submission_date?.deadline_reached
    ) {
      setIsStatusModalOpen(true);
    } else {
      updateRequestMutation.mutate({ status: option.value });
    }
  };

  const statusCancelHandler = () => {
    setIsStatusModalOpen(false);
    cancelRequestMutation.mutate({ cancellation_remarks: cancellationRemarks });
  };

  const formUpdateHandler = (values: any) => {
    if (isEditMode) {
      const {
        nestedObject,
        newLineItems,
      }: { nestedObject: any; newLineItems: Array<PostedLineItem> } = parseToNestedObject(
        values.modified,
        points,
      );
      const result: Partial<IDetailsRequest> = {};

      if (nestedObject.pickup_date) {
        const date = moment(nestedObject.pickup_date).format(SECONDARY_DATE_FORMAT);
        if (date !== requestDetails?.pickup_date) {
          result.pickup_date = date;
        }
        delete nestedObject.pickup_date;
      }

      if (nestedObject.dropoff_date) {
        const date = moment(nestedObject.dropoff_date).format(SECONDARY_DATE_FORMAT);
        if (date !== requestDetails?.dropoff_date) {
          result.dropoff_date = date;
        }
        delete nestedObject.dropoff_date;
      }

      if (nestedObject.pickup_location) {
        const location = nestedObject.pickup_location.label;
        if (location !== requestDetails?.pickup_location) {
          result.pickup_location = location;
        }
        delete nestedObject.pickup_location;
      }

      if (nestedObject.transport_contract) {
        if (nestedObject.transport_contract.value !== requestDetails?.transport_contract?.uuid) {
          result.transport_contract = nestedObject.transport_contract.value;
        }
        delete nestedObject.transport_contract;
      }

      const { destination = {}, recipient = {}, ...other } = nestedObject;

      const destinationRequests = Object.keys(destination).map((key) => {
        const { dropoff_date, dropoff_location, ...otherValues } = destination[key];
        return freightRepository.updateDestination(facilityId, key, {
          dropoff_location: dropoff_location.label,
          dropoff_date: moment(dropoff_date).format(SECONDARY_DATE_FORMAT),
          ...otherValues,
        });
      });

      const recipientRequests = Object.keys(recipient).map((key) => {
        return freightRepository.updateRecipient(facilityId, key, recipient[key]);
      });

      const lineItemsRequests = newLineItems.map((item) => {
        return freightRepository.sendLineItemRequest(facilityId, requestId as string, item);
      });

      const lineItemsDeletingRequests = deletingItems.map((item) => {
        return deleteLineItemMutation.mutate(item);
      });

      Promise.all(lineItemsRequests)
        .then(() => Promise.all(lineItemsDeletingRequests))
        .then(() => Promise.all(destinationRequests))
        .then(() => Promise.all(recipientRequests))
        .then(() => {
          updateRequestMutation.mutate({ ...result, ...other });
        });
    }
  };

  const updateDestinationMutation = useMutation(
    ({ uuid, payload }: { uuid: string; payload: Partial<IDestination> }) =>
      freightRepository.updateDestination(facilityId, uuid, payload),
  );

  const { data: goodCategoriesList } = useQuery<IGoodCategoriesResponse>('good-categories', () =>
    freightRepository.getGoodCategoriesList(facilityId),
  );

  const goodCategoriesOptions = goodCategoriesList
    ? goodCategoriesList.results.map((item: IGoodCategoriesData) => {
        return { label: item.name, value: item.name };
      })
    : [];

  const { data: loadingUnitsList } = useQuery<ILoadingUnitsResponse>('loading-units', () =>
    freightRepository.getLoadingUnitsList(facilityId),
  );

  const loadingUnitsOptions = loadingUnitsList
    ? loadingUnitsList.results.map((item: ILoadingUnitsData) => {
        return { label: item.name, value: item.name };
      })
    : [];

  const { data: wvUnitsList } = useQuery<Array<Array<string>>>('weight-volume-units', () =>
    freightRepository.getWvUnitsList(facilityId),
  );

  const wvUnitsOptions = wvUnitsList
    ? wvUnitsList.map((item: Array<string>) => {
        return { label: item[1], value: item[0] };
      })
    : [];

  const { data: contractsList } = useQuery<IContractsListResponse>('contracts-options', () =>
    freightRepository.getContractsList({
      facility: facilityId,
    }),
  );

  const contractsOptions = contractsList
    ? contractsList.results.map((item) => {
        return { label: item.name, value: item.uuid };
      })
    : [];

  const stackableOptions = [
    { label: 'No', value: '0' },
    { label: 'Yes', value: '1' },
  ];

  const orderedPoints = pointsOrdering(points);

  const formatOptionLabel = ({ label }: { label: string }) => (
    <div className="contract-option">{label}</div>
  );

  return (
    <Spin spinning={isLoading}>
      <div className="booking-details-container hbh-container last-row-bolded">
        {requestDetails && (
          <QuotationModal
            cityName={requestDetails.city_name}
            deadline={requestDetails.current_time}
            type="booking"
            isModalOpen={isModalOpen}
            mutation={sendQuotation}
            persistedContract={
              requestDetails?.transport_contract
                ? {
                    label: requestDetails.transport_contract.name,
                    value: requestDetails.transport_contract.uuid,
                  }
                : undefined
            }
            onButtonClick={quotationSendHandler}
            setIsModalOpen={setIsModalOpen}
            title="QUOTATION REQUEST"
          />
        )}
        <Form id="form" yupSchema={getValidationSchema()} onSubmit={formUpdateHandler}>
          <div className="request-details-header">
            <DetailsContainer
              items={[
                ...(requestDetails?.quotations_submission_date
                  ? [
                      {
                        content: (
                          <div className="submission-container">
                            <div className="date-info">
                              <span
                                className={`indicator ${
                                  !requestDetails?.quotations_submission_date?.deadline_reached &&
                                  'green'
                                }`}
                              />
                              <div>
                                <p>{requestDetails?.quotations_submission_date?.deadline}</p>
                                <p className="subtext">
                                  {requestDetails?.quotations_submission_date?.deadline_reached
                                    ? 'deadline reached'
                                    : `${requestDetails?.quotations_submission_date?.time_left}`}
                                </p>
                              </div>
                            </div>
                            <CalendarIcon className="calendar-icon" />
                          </div>
                        ),
                        label: 'QUOTATIONS SUBMISSION DEADLINE',
                      },
                    ]
                  : []),
                ...(requestDetails?.transport_contract
                  ? [
                      {
                        content: (
                          <div className="submission-container">
                            {isEditMode ? (
                              <AsyncDropdownField
                                formatOptionLabel={formatOptionLabel}
                                field="transport_contract"
                                defaultOptions={contractsOptions}
                                defaultValue={contractsOptions.find(
                                  ({ value }) => requestDetails?.transport_contract?.uuid === value,
                                )}
                              />
                            ) : (
                              <>
                                <CroppedText
                                  text={requestDetails?.transport_contract.name}
                                  maxLength={27}
                                />
                                <DocIcon className="document-icon" />
                              </>
                            )}
                          </div>
                        ),
                        label: 'TRANSPORT REFERENCE CONTRACT',
                      },
                    ]
                  : []),
              ]}
            />

            <div className="dropdown-container">
              <Dropdown
                styles={statusDropdownCustomStyles}
                components={getDropdownStatusComponentProps(
                  !requestDetails?.allowed_transitions?.length,
                  requestDetails?.status ?? '',
                )}
                isSearchable={false}
                onChange={statusChangeHandler}
                options={requestDetails?.allowed_transitions as any}
                value={{
                  label: requestDetails?.status_name,
                  value: requestDetails?.status,
                }}
              />
            </div>
          </div>

          <div className="general-information">
            {requestDetails && renderDetailElements(requestDetails)}
          </div>

          {orderedPoints.map((point, index) => {
            return (
              <PointBlock
                goodCategoriesOptions={goodCategoriesOptions}
                isEditMode={isEditMode}
                key={point.id}
                loadingUnitsOptions={loadingUnitsOptions}
                order={index + 1}
                point={point}
                setDeletingItems={setDeletingItems}
                stackableOptions={stackableOptions}
                wvUnitsOptions={wvUnitsOptions}
                setPoints={setPoints}
                updateDestinationMutation={updateDestinationMutation}
              />
            );
          })}
          <div className="hbh-details-container">
            <div className="hbh-details-item grid-full-column">
              <div className="hbh-details-item-label">Remarks</div>
              <div className="hbh-details-item-value remarks-item-value">
                {getInputComponent(
                  requestDetails?.additional_remarks,
                  isEditMode,
                  'additional_remarks',
                )}
              </div>
            </div>
          </div>
          <div className="details-buttons-container">
            {requestDetails?.is_editable && (
              <Button
                onClick={() => {
                  if (isEditMode) {
                    setIsEditMode(false);
                    setPoints((prevPoints) => {
                      const newArray = [...prevPoints];
                      newArray.forEach((point) => {
                        // eslint-disable-next-line no-param-reassign
                        point.lineItems = point.lineItems.filter((item) => !item.isDraft);
                      });
                      return newArray;
                    });
                  } else {
                    setIsEditMode(true);
                  }
                }}
                text={isEditMode ? 'CANCEL' : 'EDIT'}
                className={`edit-button ${isEditMode && 'apply'}`}
              />
            )}
            <FormStateAccessor>
              {({ values: { line_items } }) => (
                <TooltipAntd
                  title={
                    !line_items && isEditMode
                      ? 'To apply changes, add at least one line item on the load section'
                      : ''
                  }
                >
                  <div>
                    <Button
                      onClick={() => {
                        if (!isEditMode) {
                          setIsModalOpen(true);
                        }
                      }}
                      text={isEditMode ? 'APPLY' : 'REQUEST QUOTATION'}
                      disabled={
                        (!requestDetails?.is_quotation_possible || (isEditMode && !line_items)) ??
                        false
                      }
                      className="quotation-button"
                      type="submit"
                    />
                  </div>
                </TooltipAntd>
              )}
            </FormStateAccessor>
          </div>
        </Form>
      </div>
      <Modal
        footer={null}
        destroyOnClose
        title={<div className="modal-title">Confirm status changing and bid closing</div>}
        width="33%"
        centered
        visible={isStatusModalOpen}
        onCancel={() => setIsStatusModalOpen(false)}
      >
        <div className="modal-content-text">
          <p>
            {`The change of status to "To be processed" will notify to all Suppliers that the ${requestDetails?.reference_code} has been cancelled. Any already submitted quotation will be deleted.`}
          </p>
          Insert below the note that will be shared with all suppliers
        </div>
        <textarea
          value={cancellationRemarks}
          placeholder="Insert here..."
          className="modal-textarea"
          onChange={(e) => setCancellationRemarks(e.target.value)}
        />
        <div className="modal-buttons">
          <Button onClick={() => setIsStatusModalOpen(false)} variant="danger" text="Cancel" />
          <Button onClick={statusCancelHandler} variant="submit" text="Confirm" />
        </div>
      </Modal>
      <Tooltip id="global-tooltip" />
    </Spin>
  );
};

export default RequestDetails;
