import React, { FC } from 'react';
import Tip from '../Tip';

interface ICroppedText {
  text?: string;
  maxLength?: number;
}

const CroppedText: FC<ICroppedText> = ({ text, maxLength = 20 }) => {
  if (!text) return <> - </>;

  const isTrim = text.length > maxLength;

  return (
    <Tip isVisible={isTrim} text={text}>
      {isTrim ? `${text.substring(0, maxLength)}...` : text}
    </Tip>
  );
};

export default CroppedText;
