import { FC, useEffect, useState } from 'react';
import { Routes, Route, useMatch, useNavigate, useLocation, Location } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Footer, Header, NavigationPage } from '@ui-modules';
import { ICrumb } from '@ui-modules/types';
import { useAuth } from '@unbooking/ui-auth';
import { GlobalProvider, RepositoryProvider } from '@context';
import { useParamsParse } from '@common/hooks';
import ScrollToTop from '@components/ScrollToTop';
import pcjson from '../package.json';
import {
  CallbackPage,
  ErrorPage,
  NoAccessPage,
  NotFoundPage,
  RedirectPage,
  SignInPage,
} from './pages';
import routes from './routes';
import { ReactComponent as HeaderTitle } from './assets/svg/header.svg';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

interface IRouterElement {
  route: ICrumb;
  location: Location;
  history: string[];
}

const RouterElement: FC<IRouterElement> = ({ route, location, history }) => {
  const match = useMatch(route.path);
  return (
    <NavigationPage
      allRoutes={routes(location, history)}
      givenRoute={route}
      routePath={match?.pattern.path ?? ''}
      routeParams={match?.params}
    />
  );
};

function App() {
  const navigate = useNavigate();
  const { isAuthenticated, userName, axios, logout } = useAuth();
  const hasDoParams =
    localStorage.getItem('country') !== null && localStorage.getItem('facility') !== null;
  const { isParsed } = useParamsParse();
  const location = useLocation();

  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory([...history, location.pathname]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!hasDoParams) {
      if (isParsed) {
        navigate('/', {
          state: {
            isRedirected: true,
          },
        });
      } else {
        navigate('redirect', {
          state: {
            isRedirected: true,
          },
        });
      }
    }
  }, [hasDoParams, isParsed, navigate]);

  const logoutHandler = () => {
    logout?.();
    navigate('/', { replace: true });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <RepositoryProvider axios={axios}>
        <GlobalProvider>
          {isAuthenticated ? (
            <Header
              username={userName ?? ''}
              serviceName="Freight Management"
              logout={logoutHandler}
              logo={null}
              name={<HeaderTitle className="header-name" />}
            />
          ) : null}
          <ScrollToTop>
            <Routes>
              {isAuthenticated ? (
                <>
                  {routes(location, history).map((route) => (
                    <Route
                      path={route.path}
                      key={route.path}
                      element={
                        <RouterElement route={route} location={location} history={history} />
                      }
                    />
                  ))}
                  <Route path="callback/*" element={<CallbackPage />} />
                  <Route path="redirect" element={<RedirectPage />} />
                </>
              ) : (
                <>
                  <Route index element={<SignInPage />} />
                  <Route path="callback/*" element={<CallbackPage />} />
                  <Route path="forbidden" element={<NoAccessPage />} />
                  <Route path="error" element={<ErrorPage />} />
                  <Route path="redirect" element={<RedirectPage />} />
                  <Route path="requests/*" element={<SignInPage />} />
                </>
              )}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ScrollToTop>
          {isAuthenticated ? <Footer releaseVersion={pcjson.version} /> : null}
        </GlobalProvider>
      </RepositoryProvider>
    </QueryClientProvider>
  );
}

export default App;
