import React, { useState, useEffect, FC } from 'react';
import { Button, Table, PageTitle, Pagination, Tabs, Dropdown } from '@ui-modules';
import { useQuery } from 'react-query';
import './styles.scss';
import moment from 'moment';
import { useRepository } from '@context/repository.context';
import { IRequestListParams, TransportContract } from '@common/interfaces/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { IStatusProperty } from '@common/interfaces/Common';
import { Input } from 'antd';
import { IDropdownOption } from '@ui-modules/types';
import { DEFAULT_DATE_FORMAT } from '@common/constants';
import EmptyBox from '@components/EmptyBox';
import { CroppedText } from '@components';
import { Tooltip } from 'react-tooltip';

const { Search } = Input;

const processedFilterDropdownOptions = [
  { label: 'Quotes request sent', value: 'quotes_request_sent' },
  { label: 'Assignment notified', value: 'assignment_notified' },
  { label: 'Bid closed', value: 'bid_closed' },
  { label: 'Cancelled', value: 'cancelled' },
];

const getBookingStateBtn = (record: string): JSX.Element => {
  const statusProperties: IStatusProperty = {
    not_processed: {
      className: 'btn not_processed',
      variant: 'submit',
      text: 'Not processed',
    },
    quotes_request_sent: {
      className: 'btn quotes_request_sent',
      variant: 'transparent',
      text: 'Quotes request sent',
    },
    bid_closed: {
      className: 'btn bid_closed',
      variant: 'transparent',
      text: 'Bid closed',
    },
    processed: {
      className: 'btn processed',
      variant: 'transparent',
      text: 'Processed',
    },
    assigned: {
      className: 'btn assigned',
      variant: 'transparent',
      text: 'Assigned',
    },
    assignment_notified: {
      className: 'btn assignment_notified',
      variant: 'submit',
      text: 'Assignment notified',
    },
    cancelled: {
      className: 'btn cancelled',
      variant: 'submit',
      text: 'Cancelled',
    },
    defaultProperty: {
      className: 'btn btn-default',
      variant: 'submit',
    },
  };

  const additionalProperties = statusProperties[record] || statusProperties.defaultProperty;

  /* eslint-disable react/jsx-props-no-spreading */
  return <Button text={record} {...additionalProperties} />;
};

const tabsList = ['Not processed', 'Processed'];

const RequestsListPage: FC = () => {
  const tabs = tabsList;
  const navigate = useNavigate();
  const { id: itemUuid } = useParams<{ id: string }>();

  const initialSearchKey = sessionStorage.getItem('requestListSearchKey') || '';
  const initialTab = sessionStorage.getItem('requestListTab') || tabs[0];
  const initialSelectedPage = parseFloat(sessionStorage.getItem('requestListPage') ?? '1');
  const initialPageSize = parseFloat(sessionStorage.getItem('requestListPerPage') ?? '10');
  const savedStatusFilter = sessionStorage.getItem('requestListStatusFilter');
  const initialStatusFilter = processedFilterDropdownOptions.find(
    ({ value }) => value === savedStatusFilter,
  );

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);
  const [selectedPage, setSelectedPage] = useState<number>(initialSelectedPage);
  const [searchFilter, setSearchFilter] = useState<string>(initialSearchKey);
  const [searchFilterValue, setSearchFilterValue] = useState<string>(initialSearchKey);
  const [statusFilter, setStatusFilter] = useState<IDropdownOption | null>(
    initialStatusFilter || null,
  );
  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage.getItem('country');

  const { freightRepository } = useRepository();

  const columns = [
    {
      dataIndex: 'reference_code',
      key: 'reference_code',
      title: 'ID',
      width: 90,
      sorter: true,
    },
    {
      dataIndex: 'created',
      key: 'created',
      title: 'Request date',
      sorter: true,
      render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
    },
    {
      dataIndex: 'transport_contract',
      key: 'transport_contract',
      title: 'Transport contract',
      sorter: true,
      render: (record: TransportContract) =>
        record ? <CroppedText text={record.name} maxLength={30} /> : '-',
    },
    {
      dataIndex: 'pickup_date',
      key: 'pickup_date',
      title: 'Start loading date',
      sorter: true,
      render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
    },
    {
      dataIndex: 'route',
      key: 'route',
      title: 'Route',
      sorter: true,
      render: (record: string) => (record ? <CroppedText text={record} maxLength={30} /> : '-'),
    },
    ...(selectedTab === 'Processed'
      ? [
          {
            dataIndex: 'deadline',
            key: 'deadline',
            title: 'Submission deadline',
            sorter: true,
            render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
          },
        ]
      : []),
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 150,
      render: (record: string) => getBookingStateBtn(record),
    },
    {
      width: 100,
      render: (_record: string, data: any) => (
        <Button
          onClick={() => {
            navigate(`/requests/${data.uuid}`, { state: { batchId: itemUuid } });
          }}
          className="view-button"
          text="View"
        />
      ),
    },
  ];

  const clearPagination = () => {
    setSelectedPage(1);
    setPageSize(10);
    sessionStorage.setItem('requestListPage', '1');
    sessionStorage.setItem('requestListPerPage', '10');
  };

  const clearSearch = () => {
    setSearchFilterValue('');
    setSearchFilter('');
  };

  const clearParams = () => {
    setStatusFilter(null);
    sessionStorage.removeItem('requestListStatusFilter');
    clearSearch();
    clearPagination();
  };

  useEffect(() => {
    if (!searchFilterValue) {
      setSearchFilter('');
    }
    sessionStorage.setItem('requestListSearchKey', searchFilterValue);
  }, [searchFilterValue]);

  const buildParams = (): IRequestListParams => {
    return {
      show: selectedTab === 'Processed' ? 'processed' : 'not_processed',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
      filter_query: searchFilter || null,
      status: statusFilter?.value || null,
    };
  };

  const { data: list, isLoading } = useQuery(
    [
      'freight-requests-list',
      selectedTab,
      selectedPage,
      pageSize,
      columnOrder,
      searchFilter,
      statusFilter,
    ],
    () => freightRepository.getRequestsList(facilityId, buildParams()),
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <section className="hbh-container booking-list">
      <PageTitle className="section-title" title={`Freight requests - ${country}`} bottomLine />
      <Tabs
        className="tabs"
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={(index) => {
          setSelectedTab(index);
          sessionStorage.setItem('requestListTab', index);
          clearParams();
        }}
      />
      <section className="filters">
        <div className="input-wrapper">
          <p className="input-label">Search Freight</p>
          <Search
            className="search-input"
            placeholder="Insert"
            value={searchFilterValue}
            onSearch={() => setSearchFilter(searchFilterValue)}
            onChange={(e) => {
              setSearchFilterValue(e.target.value);
              clearPagination();
            }}
            id="search-input"
            allowClear
            enterButton
          />
        </div>
        <div className="status-filter-dropdown">
          {selectedTab === 'Processed' && (
            <Dropdown
              className="filter status-filter outline"
              label={<p className="hbh-select-label">Filter by statuses</p>}
              value={statusFilter}
              placeholder="All statuses"
              options={processedFilterDropdownOptions}
              onChange={(status) => {
                setStatusFilter(status);
                sessionStorage.setItem('requestListStatusFilter', status.value);
                clearSearch();
                clearPagination();
              }}
            />
          )}
          <button type="button" onClick={clearParams} className="reset">
            Reset filters
          </button>
        </div>
      </section>
      <div className="table table-requests table-scrolling">
        <Table
          columns={columns}
          data={list?.results}
          rowKey="uuid"
          variant="dark"
          isLoading={isLoading}
          onChangeColumnOrder={setColumnOrder}
          locale={{
            emptyText: <EmptyBox />,
          }}
        />
      </div>
      <Pagination
        className="pagination"
        selectedPage={selectedPage}
        showJumper
        showPageSize
        totalPages={list?.count}
        variant="dark"
        onPageChange={(page) => {
          setSelectedPage(page);
          sessionStorage.setItem('requestListPage', page.toString());
        }}
        onPageSizeChange={(perPage) => {
          setPageSize(perPage);
          sessionStorage.setItem('requestListPerPage', perPage.toString());
        }}
      />
      <Tooltip id="global-tooltip" />
    </section>
  );
};

export default RequestsListPage;
