import { Chart as ChartJS, ArcElement, Tooltip, TooltipItem } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import './styles.scss';
import { DetailsContainer } from '@ui-modules';
import { ILineItemCurrency } from '@common/interfaces/Common';
import {
  NormalizedQuotation,
  getCalculatedTotals,
  getFormattedMoney,
  groupByProvider,
} from '@pages/RequestsDetailsPage/components/QuotationManagement/utils';
import { stringToColour } from '@common/utils';
import { FC } from 'react';

ChartJS.register(ArcElement, Tooltip);

interface IAssignmentOverview {
  quotations: Array<NormalizedQuotation>;
  currencies: ILineItemCurrency;
}

const AssignmentOverview: FC<IAssignmentOverview> = ({ quotations, currencies }) => {
  const groupedQuotations = getCalculatedTotals(groupByProvider(quotations));

  const labels = groupedQuotations.map(({ label }) => label);

  const hexColors = groupedQuotations.map(({ label }) => stringToColour(label));

  const chartProps = {
    options: {
      plugins: {
        tooltip: {
          padding: 10,
          bodyFont: {
            size: 14,
          },
          callbacks: {
            title(tooltipData: TooltipItem<'doughnut'>[]) {
              return tooltipData[0].label;
            },
            label(tooltipData: TooltipItem<'doughnut'>) {
              return getFormattedMoney(tooltipData.parsed, currencies[tooltipData.label]);
            },
          },
        },
      },
      elements: {
        arc: {
          borderWidth: 10,
          borderRadius: 1,
        },
      },
    },
    data: {
      labels,
      datasets: [
        {
          data: groupedQuotations.map(({ sum }) => sum),
          borderWidth: 1,
          backgroundColor: hexColors,
          hoverBorderColor: 'white',
          cutout: '80%',
          spacing: labels.length === 1 ? 0 : 5,
          weight: 1,
        },
      ],
    },
  };

  const total = quotations.reduce((acc, item) => {
    return acc + item.quote;
  }, 0);

  const totalMt =
    quotations.reduce((acc, item) => {
      return acc + item.quote_mt;
    }, 0) / quotations.length;

  const details = [
    {
      label: 'OVERALL COST',
      content: (
        <>
          <div className="total-overall">{getFormattedMoney(total)}</div>
          <div className="total-overall">{getFormattedMoney(totalMt, 'USD/MT')}</div>
        </>
      ),
    },
    ...(groupedQuotations.map(({ label, sum, sum_mt }) => ({
      label,
      content: (
        <>
          <div>{getFormattedMoney(sum, currencies[label])}</div>
          <div>{getFormattedMoney(sum_mt, `${currencies[label]}/MT`)}</div>
        </>
      ),
    })) || []),
  ];

  return (
    <div>
      <div className="qm-title">Assignment cost overview</div>
      <div className="assignment-overview-wrapper">
        <div className="cost-overview">
          <div className="chart-wrapper">
            <div className="chart-doughnut">
              <Doughnut {...chartProps} />
            </div>
            <div className="chart-labels">
              {labels.map((label, index) => (
                <div className="chart-label" key={label}>
                  <div
                    className="chart-mark"
                    style={{
                      background: hexColors[index],
                    }}
                  />
                  <div className="chart-text">{label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="overall-details">
          <p>Overall cost breakdown</p>
          <DetailsContainer className="overall-details-list" items={details} />
        </div>
      </div>
    </div>
  );
};

export default AssignmentOverview;
