/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg';
import { Form } from 'informed';
import { Modal, Tooltip, message } from 'antd';
import { TextField } from '@ui-modules/informed';
import * as Yup from 'yup';
import {
  Button,
  DetailsContainer,
  Date as DatePicker,
  Table,
  Dropdown,
} from '@unbooking/ui-modules';
import { ProgressBar } from '@components';
import './styles.scss';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from 'react-query';
import { useRepository } from '@context';
import { useParams } from 'react-router';
import moment from 'moment';
import {
  IAddContractData,
  IContractsListResponse,
  IVendorDetails,
  IVendorDetailsPatch,
} from '@common/interfaces';
import { DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT } from '@common/constants';
import { Nullable } from '@common/interfaces/Common';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import Switcher from '@components/Switcher';

interface IVendorInformation {
  details?: IVendorDetails;
  detailsRefetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IVendorDetails, unknown>>;
}

const yupSchema = Yup.object().shape({
  signature_email: Yup.string().required('Required').email('Incorrect email'),
  main_email: Yup.string().required('Required').email('Incorrect email'),
});

const columns = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Contract',
  },
  {
    dataIndex: 'expiration_date',
    key: 'expiration_date',
    title: 'Expiration date',
    render: (record: string) => {
      const expDate = moment(record);
      const isExpired = moment().isAfter(expDate);
      return (
        <div className="date-cell">
          <span className={`exp-dot${isExpired ? ' expired' : ''}`} />
          {expDate.format(DEFAULT_DATE_FORMAT)}
        </div>
      );
    },
  },
];

const VendorInformation: FC<IVendorInformation> = ({ details: data, detailsRefetch }) => {
  const { freightRepository } = useRepository();
  const facilityId = localStorage.getItem('facility')!;
  const { id: vendorId = '' } = useParams<{ id: string }>();

  const [editMode, setEditMode] = useState(false);
  const [modalStatusChanging, setModalStatusChanging] = useState(false);
  const [modalContractAdding, setModalContractAdding] = useState(false);

  const [active, setActive] = useState(false);
  const [eSignEmail, setESignEmail] = useState('-');
  const [mainEmail, setMainEmail] = useState('-');

  const [contract, setContract] = useState<Nullable<IDropdownOption>>(null);
  const [expDate, setExpDate] = useState<Nullable<Date>>(null);

  const resetContractAdding = () => {
    setModalContractAdding(false);
    setContract(null);
    setExpDate(null);
  };

  const { data: contracts, isLoading: isLoadingList } = useQuery<IContractsListResponse>(
    ['freight-vendor-contracts'],
    () =>
      freightRepository.getContractsList({
        options_for_provider: vendorId,
        facility: facilityId,
      }),
  );

  const patchVendorDetails = useMutation(
    (patchData: IVendorDetailsPatch) =>
      freightRepository.patchVendorDetails(facilityId, vendorId, patchData),
    {
      onSuccess: () => {
        message.success('Vendor was updated successfully');
      },
      onError: (error) => {
        message.error(String(error));
      },
    },
  );

  const addContract = useMutation(
    (patchData: IAddContractData) =>
      freightRepository.addContract(patchData, contract?.value || ''),
    {
      onSuccess: () => {
        resetContractAdding();
        detailsRefetch?.().then(() => false);
        message.success('Contract was added successfully');
      },
      onError: (error) => {
        message.error(String(error));
      },
    },
  );

  const onSubmit = () => {
    setEditMode(false);
    patchVendorDetails.mutate({
      contact_email: mainEmail,
      signature_email: eSignEmail,
      is_active: active,
    });
  };

  const handleChangeStatus = (e: any) => {
    const status = e.target.checked;

    if (status) {
      setActive(status);
    } else {
      setModalStatusChanging(true);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    if (data) {
      setActive(data.is_active);
      setMainEmail(data.contact_email);
      setESignEmail(data.signature_email);
    }
  };

  const handleContractAdding = () => {
    if (!expDate) {
      message.error('Enter expiration date');
      return;
    }

    if (!contract) {
      message.error('Enter contract name');
      return;
    }

    addContract.mutate({
      provider_addition_date: expDate ? moment(expDate).format(SECONDARY_DATE_FORMAT) : '',
      provider: vendorId,
    });
  };

  useEffect(() => {
    if (data) {
      setActive(data.is_active);
      setMainEmail(data.contact_email);
      setESignEmail(data.signature_email);
    }
  }, [data]);

  const contractsOptions =
    contracts?.results.map(({ uuid, name }) => ({ label: name, value: uuid })) || [];

  return (
    <div className="vendor-information-page">
      {data && (
        <Form onSubmit={onSubmit} yupSchema={yupSchema}>
          <DetailsContainer
            className="vendor-details"
            items={[
              {
                content: <Tooltip title={data.legal_name}>{data.legal_name}</Tooltip>,
                label: 'Vendor legal name',
              },
              { content: data.vendor_id, label: 'Wings vendor number' },
              {
                content: <ProgressBar value={data.participation_rate} />,
                label: 'Participation Rate',
              },
              {
                content: <ProgressBar value={data.withdrawal_rate} />,
                label: 'Withdrawal Rate',
              },
              {
                content: editMode ? (
                  <TextField
                    name="signature_email"
                    initialValue={eSignEmail}
                    onChange={({ value }) => setESignEmail(value as string)}
                  />
                ) : (
                  eSignEmail || '-'
                ),
                label: 'E-signature email',
              },
              {
                content: editMode ? (
                  <TextField
                    name="main_email"
                    initialValue={mainEmail}
                    onChange={({ value }) => setMainEmail(value as string)}
                  />
                ) : (
                  mainEmail || '-'
                ),
                label: 'Main contact email',
              },
              {
                content: (
                  <Switcher
                    checked={active}
                    disabled={!editMode}
                    leftText="Not Active"
                    onChange={handleChangeStatus}
                    rightText="Active"
                  />
                ),
                label: (
                  <div className="vendor-status-info">
                    Vendor status
                    <Tooltip title="Active: it is possible to request quotations to vendor based on the related contracts. Not Active: the vendor will not receive any request for quotation">
                      <InfoIcon />
                    </Tooltip>
                  </div>
                ),
              },
            ]}
          />
          <div className="vendor-info-block">
            <Table
              columns={columns}
              data={data?.transport_contracts || []}
              rowKey="uuid"
              loading={isLoadingList}
              variant="dark"
            />
            <Button
              className="button-add-contract"
              onClick={() => setModalContractAdding(true)}
              text="Add new contract"
              variant="submit"
            />
          </div>
          <div className="buttons">
            {editMode ? (
              <>
                <Button
                  className="button-vendor-edit"
                  onClick={handleCancelEdit}
                  text="CANCEL"
                  variant="danger"
                />
                <Button
                  className="button-vendor-edit"
                  type="submit"
                  text="APPLY"
                  variant="submit"
                />
              </>
            ) : (
              <Button
                onClick={() => setEditMode(true)}
                className="button-vendor-edit"
                text="EDIT"
              />
            )}
          </div>
        </Form>
      )}
      <Modal
        footer={null}
        title={<div className="modal-title">Add new contract</div>}
        width="33%"
        centered
        visible={modalContractAdding}
        onCancel={resetContractAdding}
      >
        <div className="add-new-contract-form">
          <Dropdown
            label={<p className="contract-select-label">Contract</p>}
            value={contract}
            placeholder="Contract..."
            options={contractsOptions}
            onChange={(option) => setContract(option)}
          />
          <label>Vendor contract signing date</label>
          <DatePicker
            placeholderText="DD/MM/YYYY"
            dateValue={expDate || undefined}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            onChange={setExpDate}
          />
        </div>
        <div className="modal-buttons">
          <Button variant="danger" onClick={resetContractAdding} text="Cancel" />
          <Button onClick={handleContractAdding} text="Ok" />
        </div>
      </Modal>
      <Modal
        footer={null}
        title={<div className="modal-title">Changing status</div>}
        width="33%"
        centered
        visible={modalStatusChanging}
        onCancel={() => setModalStatusChanging(false)}
      >
        <div className="modal-content-text centered">
          <p>
            If selected the vendor will not be able to receive communications related to tenders.
            <br /> Do you approve status changing?
          </p>
        </div>
        <div className="modal-buttons">
          <Button variant="danger" onClick={() => setModalStatusChanging(false)} text="Cancel" />
          <Button
            onClick={() => {
              setModalStatusChanging(false);
              setActive(false);
            }}
            text="Ok"
          />
        </div>
      </Modal>
    </div>
  );
};

export default VendorInformation;
