import React, { FC } from 'react';
import { Menu } from '@components';
import { CalendarIcon, TruckIcon } from '@assets/svg';
import { DoDataResponse } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { Spin } from 'antd';

const LettersMenu: FC = () => {
  const facilityId = localStorage.getItem('facility')!;
  const { freightRepository } = useRepository();

  const { data, isLoading } = useQuery<DoDataResponse>(['do-data'], () =>
    freightRepository.getDOData(facilityId),
  );

  const buttons = [
    {
      label: 'Spot freight requests',
      id: 'termination',
      link: '/requests/spot',
      icon: <TruckIcon />,
      tags: [
        ...(data?.no_of_not_processed_bookings.SPOT
          ? [
              {
                label: 'not processed',
                color: 'red',
                count: data.no_of_not_processed_bookings.SPOT,
              },
            ]
          : []),
        ...(data?.no_of_bid_closed_bookings.SPOT
          ? [
              {
                label: 'bid closed',
                color: 'limeade',
                count: data.no_of_bid_closed_bookings.SPOT,
              },
            ]
          : []),
      ],
    },
    {
      label: 'Freight dispatch cycles',
      id: 'batch',
      link: '/requests/batch',
      icon: <CalendarIcon />,
      tags: [
        ...(data?.no_of_not_processed_bookings.BATCH
          ? [
              {
                label: 'not processed',
                color: 'red',
                count: data.no_of_not_processed_bookings.BATCH,
              },
            ]
          : []),
        ...(data?.no_of_bid_closed_bookings.BATCH
          ? [
              {
                label: 'bid closed',
                color: 'limeade',
                count: data.no_of_bid_closed_bookings.BATCH,
              },
            ]
          : []),
      ],
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Menu buttons={buttons} centered />
    </Spin>
  );
};

export default LettersMenu;
