/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useState } from 'react';
import { Table, Pagination, DetailsContainer, Dropdown, Button } from '@ui-modules';
import { useMutation, useQuery } from 'react-query';
import { useRepository } from '@context';
import { ReactComponent as Calendar } from '@assets/svg/calendar.svg';
import {
  IListParams,
  IVendorAnalysis,
  IVendorDetails,
  IVendorQuotation,
  IVendorQuotations,
} from '@common/interfaces';

import './styles.scss';
import NumberFormat from 'react-number-format';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DECIMAL_FORMAT,
  DEFAULT_SERVER_FORMAT,
} from '@common/constants';
import { useParams } from 'react-router';
import { DatePicker, Modal, Spin, message } from 'antd';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import { Nullable } from '@common/interfaces/Common';
import { LineChartWidget, ProgressBar } from '@components';
import FileSaver from 'file-saver';
import moment, { Moment } from 'moment';

const { RangePicker } = DatePicker;

interface IQuotationsAnalysis {
  details?: IVendorDetails;
}

export interface IRangeDatePicker {
  start_date: Moment;
  end_date: Moment;
}

const columns = [
  {
    dataIndex: 'booking_id',
    key: 'booking_id',
    title: 'Freight ID',
  },
  {
    dataIndex: 'line_item_short_description',
    key: 'line_item_short_description',
    title: 'Line item',
    render: (record: string, { order_number }: IVendorQuotation) =>
      `${order_number || ''} - ${record}`,
  },
  {
    dataIndex: 'quote',
    key: 'quote',
    title: 'Lump sum (USD)',
    render: (record: string) => (
      <NumberFormat displayType="text" value={record} {...DEFAULT_DECIMAL_FORMAT} />
    ),
  },
  {
    dataIndex: 'quote_mt',
    key: 'quote_mt',
    title: 'Cost/MT (USD)',
    render: (record: string) => (
      <NumberFormat displayType="text" value={record} {...DEFAULT_DECIMAL_FORMAT} />
    ),
  },
  {
    dataIndex: 'selected',
    key: 'selected',
    title: 'Awarded',
    render: (record: string) => {
      return record ? (
        <div className="block-plate bp-yes">YES</div>
      ) : (
        <div className="block-plate bp-no">NO</div>
      );
    },
  },
];

const QuotationsAnalysis: FC<IQuotationsAnalysis> = ({ details }) => {
  const { freightRepository } = useRepository();
  const { id: vendorId = '' } = useParams<{ id: string }>();
  const facilityId = localStorage.getItem('facility')!;

  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedOrigin, setSelectedOrigin] = useState<Nullable<IDropdownOption>>(null);
  const [selectedDestination, setSelectedDestination] = useState<Nullable<IDropdownOption>>(null);
  const [selectedFreightType, setSelectedFreightType] = useState<Nullable<IDropdownOption>>(null);
  const [selectedDates, setSelectedDates] = useState<Nullable<IRangeDatePicker>>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const { data: list, isLoading: isLoadingList } = useQuery<IVendorQuotations>(
    [
      'freight-vendor-quotations',
      selectedPage,
      pageSize,
      selectedOrigin,
      selectedDestination,
      selectedFreightType,
    ],
    () =>
      freightRepository.getVendorQuotations(facilityId, vendorId, {
        offset: (selectedPage - 1) * 10,
        limit: pageSize,
        origin: selectedOrigin?.value,
        destination: selectedDestination?.value,
        freight_type: selectedFreightType?.value,
      }),
  );

  const { data, isLoading } = useQuery<IVendorAnalysis>(
    ['freight-vendor-analysis', selectedOrigin, selectedDestination, selectedFreightType],
    () =>
      freightRepository.getVendorAnalysis(facilityId, vendorId, {
        origin: selectedOrigin?.value,
        destination: selectedDestination?.value,
        freight_type: selectedFreightType?.value,
      }),
  );

  const resetModal = () => {
    setIsModalVisible(false);
    setSelectedDates(null);
    setIsRequesting(false);
  };

  const getVendorQuotationsReportFile = useMutation(
    (params: IListParams) =>
      freightRepository.getVendorQuotationsReportFile(facilityId, vendorId, params),
    {
      onSuccess: (data: string) => {
        const fileTitle = `${details?.legal_name}_quotations-${moment(
          selectedDates?.start_date,
        ).format(DEFAULT_DATE_FORMAT)}-${moment(selectedDates?.end_date).format(
          DEFAULT_DATE_FORMAT,
        )}.xlsx`;

        try {
          FileSaver.saveAs(
            new Blob([data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
            }),
            fileTitle,
          );
          resetModal();
        } catch (error) {
          message.error('Parsing file error');
        }
      },
      onError: () => {
        message.error('Request failed, please try again.');
        resetModal();
      },
    },
  );

  const resetFilters = () => {
    setSelectedOrigin(null);
    setSelectedDestination(null);
    setSelectedFreightType(null);
  };

  const handleExport = () => {
    getVendorQuotationsReportFile.mutate({
      start_date: selectedDates?.start_date.format(DEFAULT_SERVER_FORMAT),
      end_date: selectedDates?.end_date.format(DEFAULT_SERVER_FORMAT),
    });
    setIsRequesting(true);
  };

  const costMtData = data
    ? [
        {
          data: Object.keys(data.average_cost_mt_history).map((key) => ({
            label: key,
            value: data.average_cost_mt_history[key],
          })),
        },
      ]
    : [];

  return (
    <Spin spinning={isLoading}>
      <div className="quotation-analysis-page">
        {data && (
          <>
            <div>
              <DetailsContainer
                items={[
                  {
                    content: (
                      <Dropdown
                        value={selectedFreightType}
                        placeholder="All..."
                        options={data.options.freight_types}
                        onChange={setSelectedFreightType}
                      />
                    ),
                    label: (
                      <div className="filter-title">
                        Freight Type
                        {selectedFreightType && (
                          <div
                            onClick={() => setSelectedFreightType(null)}
                            className="filter-reset"
                          >
                            Reset filter
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    content: (
                      <Dropdown
                        value={selectedOrigin}
                        placeholder="All..."
                        options={data.options.origins}
                        onChange={setSelectedOrigin}
                      />
                    ),
                    label: (
                      <div className="filter-title">
                        Origin
                        {selectedOrigin && (
                          <div onClick={() => setSelectedOrigin(null)} className="filter-reset">
                            Reset filter
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    content: (
                      <Dropdown
                        value={selectedDestination}
                        placeholder="All..."
                        options={data.options.destinations}
                        onChange={setSelectedDestination}
                      />
                    ),
                    label: (
                      <div className="filter-title">
                        Destination
                        {selectedDestination && (
                          <div
                            onClick={() => setSelectedDestination(null)}
                            className="filter-reset"
                          >
                            Reset filter
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    content: (
                      <NumberFormat
                        displayType="text"
                        value={data.average_cost_mt}
                        {...DEFAULT_DECIMAL_FORMAT}
                      />
                    ),
                    label: 'Average COST/MT (USD)',
                  },
                  { content: `${data.line_item_quotations_count}`, label: 'Line items quotations' },
                  {
                    content: <ProgressBar value={data.participation_rate} />,
                    label: 'Participation Rate',
                  },
                ]}
              />
              {(selectedDestination || selectedOrigin || selectedFreightType) && (
                <div onClick={resetFilters} className="filter-reset filter-reset-general">
                  Reset filters
                </div>
              )}
            </div>
            <div className="quotation-analysis-info">
              <LineChartWidget data={costMtData} title="Average Cost/MT history - last 6 months" />
              <div className="table table-scrolling">
                <Table
                  columns={columns}
                  data={list?.results || []}
                  rowKey="uuid"
                  loading={isLoadingList}
                  variant="dark"
                />
                <div className="table-bottom">
                  <Button
                    onClick={() => setIsModalVisible(true)}
                    className="button-export"
                    text="Export data"
                  />
                  <Pagination
                    className="pagination"
                    selectedPage={selectedPage}
                    showJumper
                    showPageSize
                    totalPages={list?.count || 0}
                    variant="dark"
                    onPageChange={setSelectedPage}
                    onPageSizeChange={setPageSize}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        footer={null}
        title={<div className="modal-title">Export quotations</div>}
        width="33%"
        centered
        onCancel={resetModal}
        destroyOnClose
        visible={isModalVisible}
      >
        <div className="batch-report">
          <p>Filter by quotation date range</p>
          <div>
            <span className="range-label">Range</span>
            <RangePicker
              format={DEFAULT_DATE_FORMAT}
              disabled={isRequesting}
              onChange={(value) => {
                if (value && value[0] && value[1]) {
                  setSelectedDates({
                    start_date: value[0],
                    end_date: value[1],
                  });
                } else {
                  setSelectedDates(null);
                }
              }}
              disabledDate={(current) => current && current > moment().endOf('day')}
              separator="to"
              suffixIcon={<Calendar className="calendar-icon" />}
            />
          </div>
          {isRequesting ? (
            <div className="loader-info">
              <Spin spinning />
              <p>Quotation report is being generated...</p>
            </div>
          ) : (
            <Button
              onClick={handleExport}
              className="export-button"
              variant="submit"
              disabled={!selectedDates}
              text="Confirm"
            />
          )}
        </div>
      </Modal>
    </Spin>
  );
};

export default QuotationsAnalysis;
