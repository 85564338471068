/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ArrowRoundIcon } from '../../icons';

import css from './styles.module.scss';

export interface IDropdownOption {
  label: string;
  value: string;
}

export interface IDropdown {
  className?: string;
  value?: IDropdownOption | null;
  defaultOptions?: IDropdownOption[] | boolean;
  options?: IDropdownOption[];
  disabled?: boolean;
  placeholder?: string;
  noOptionsMessage?: string;
  onChange?: (value: IDropdownOption) => void;
  loadOptions?: () => Promise<IDropdownOption[]>;
  formatOptionLabel?: (props: any) => JSX.Element;
}

const DropdownIndicator = (props: any): any => (
  <components.DropdownIndicator {...props}>
    <ArrowRoundIcon type="bottom" />
  </components.DropdownIndicator>
);

const Dropdown: FC<IDropdown> = ({
  className,
  value,
  defaultOptions,
  options,
  disabled,
  placeholder,
  noOptionsMessage,
  onChange,
  loadOptions,
  formatOptionLabel,
}) => {
  return (
    <div className={css.wrapper}>
      <AsyncSelect
        options={options}
        cacheOptions
        className={`async-select ${className}`}
        classNamePrefix="async-select"
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        defaultOptions={defaultOptions}
        isDisabled={disabled}
        placeholder={placeholder}
        formatOptionLabel={formatOptionLabel}
        value={(value as any) ?? ''}
        onChange={(option: IDropdownOption) => onChange?.(option)}
        loadOptions={loadOptions}
        noOptionsMessage={() => noOptionsMessage ?? 'No options'}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    </div>
  );
};

export default Dropdown;
