import React, { FC, useEffect, useState } from 'react';
import { Tabs } from '@ui-modules';
import RequestDetails from '@pages/RequestsDetailsPage/components/RequestsDetails';
import { useLocation } from 'react-router-dom';
import QuotationManagement from '@pages/RequestsDetailsPage/components/QuotationManagement';
import { IDetailsRequest } from '@common/interfaces';
import { ReactComponent as VolumeIcon } from '@assets/svg/volume.svg';
import { ReactComponent as MtIcon } from '@assets/svg/mt.svg';
import NumberFormat from 'react-number-format';
import { IQuotationSubmissionDate } from '@common/interfaces/Common';
import './styles.scss';
import { DEFAULT_DECIMAL_FORMAT, DEFAULT_WEIGHT_FORMAT } from '@common/constants';

const DETAILS_PAGE_TAB = 'Request Details';
const QUOTATION_PAGE_TAB = 'Quotation Management';

const tabTooltip = {
  [QUOTATION_PAGE_TAB]:
    'The bid still in progress, quotations will be visible after the deadline is reached',
};

const RequestsDetailsPage: FC = () => {
  const [selectedTab, setSelectedTab] = useState(DETAILS_PAGE_TAB);
  const [status, setStatus] = useState('');
  const [details, setDetails] = useState<IDetailsRequest>();
  const [submissionDate, setSubmissionDate] = useState<IQuotationSubmissionDate | null>(null);
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState({
    reference_code: '',
    volume: 0,
    weight: 0,
  });

  useEffect(() => {
    const state = location.state as { batchId: string };
    if (state?.batchId) {
      localStorage.setItem('BATCH_ID_DETAILS_SOURCE', state.batchId);
    } else {
      localStorage.removeItem('BATCH_ID_DETAILS_SOURCE');
    }
  }, [location]);

  const tabs = [
    DETAILS_PAGE_TAB,
    ...(status && status !== 'not_processed' ? [QUOTATION_PAGE_TAB] : []),
  ];

  return (
    <div>
      <section className="hbh-page-title">
        <h1 className="title">
          Freight Request, {pageTitle.reference_code}
          <span className="title-divider"> - </span>
          {pageTitle.volume !== 0 && (
            <div className="title-box">
              <VolumeIcon />
              <span>Total volume:</span>
              <NumberFormat
                displayType="text"
                value={pageTitle.volume}
                {...DEFAULT_DECIMAL_FORMAT}
              />
              <span>m³</span>
            </div>
          )}
          {pageTitle.weight !== 0 && (
            <div className="title-box">
              <MtIcon className="icon-mt" />
              <span>Total weight:</span>
              <NumberFormat
                displayType="text"
                value={pageTitle.weight}
                {...DEFAULT_WEIGHT_FORMAT}
              />
              <span>MT</span>
            </div>
          )}
        </h1>
      </section>
      <Tabs
        className="tabs"
        tabs={tabs}
        disabledTabs={!submissionDate?.deadline_reached ? [QUOTATION_PAGE_TAB] : undefined}
        tabTooltip={!submissionDate?.deadline_reached ? tabTooltip : undefined}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={setSelectedTab}
      />
      {selectedTab === DETAILS_PAGE_TAB && (
        <RequestDetails
          setStatus={setStatus}
          setDetails={setDetails as any}
          setSubmissionDate={setSubmissionDate}
          setPageTitle={setPageTitle}
        />
      )}
      {selectedTab === QUOTATION_PAGE_TAB && details && <QuotationManagement details={details} />}
    </div>
  );
};

export default RequestsDetailsPage;
