import React, { FC, useState } from 'react';
import { Button, Dropdown, PageTitle, Pagination, Table, Tabs } from '@ui-modules';
import { Input, Dropdown as AntdDropdown, Spin, Modal, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { IDropdownOption } from '@ui-modules/types';
import { CloseOutlined, MailFilled } from '@ant-design/icons';
import QuotationModal from '@components/QuotationModal';
import MenuList from '@pages/BatchDetailsPage/components/MenuList';
import BatchReport from '@pages/BatchDetailsPage/components/BatchReport';
import HeaderSummary from '@pages/BatchDetailsPage/components/HeaderSummary';
import moment from 'moment/moment';
import { getColumns } from '@pages/BatchDetailsPage/utils';
import {
  BULK_ASSIGNED,
  BULK_MODE_ALL,
  BULK_MODE_CONTRACTS,
  BULK_MODE_MANUALLY,
  BULK_NOT_PROCESSED,
} from '@pages/BatchDetailsPage/constants';
import { useRepository } from '@context';
import {
  IBatchDetailsResponse,
  IProviders,
  IQuotationPayload,
  IRequestListParams,
} from '@common/interfaces';
import './styles.scss';
import EmptyBox from '@components/EmptyBox';
import { Tooltip } from 'react-tooltip';
import { Tip } from '@components';

const { Search } = Input;

const tabs = ['Not processed', 'Processed'];

export interface IMultipleQuotationPayload extends Omit<IQuotationPayload, 'booking_uuid'> {
  batch_uuid: string;
  all_not_processed: boolean;
  bookings_uuids: string[];
  transport_contract: string;
}

export interface IMultipleNotificationPayload {
  all_assigned: boolean;
  bookings_uuids: string[];
}

const BatchDetailsPage: FC = () => {
  const initialTab = sessionStorage.getItem('batchDetailsTab') || tabs[0];
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [selectedTab, setSelectedTab] = useState<string>(initialTab);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [contractFilter, setContractFilter] = React.useState<IDropdownOption | null>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [columnOrder, setColumnOrder] = useState<string>('');
  const [searchFilterValue, setSearchFilterValue] = useState<string>('');
  const [bulkMode, setBulkMode] = useState<string | undefined>(undefined);
  const [selectedFreights, setSelectedFreights] = useState<string[]>([]);
  const [isBulkModalVisible, setIsBulkModalVisible] = useState<boolean>(false);
  const [isAwardModalVisible, setIsAwardModalVisible] = useState<boolean>(false);
  const [isNotificationModalDisabled, setIsNotificationModalDisabled] = useState<boolean>(false);
  const [isQuotationModalDisabled, setIsQuotationModalDisabled] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const facilityId = +localStorage.getItem('facility')!;
  const navigate = useNavigate();
  const bulkType = selectedTab === 'Processed' ? 'assigned' : 'not_processed';

  const { freightRepository } = useRepository();

  const buildParams = (): IRequestListParams => {
    return {
      show: selectedTab === 'Processed' ? 'processed' : 'not_processed',
      search: searchFilter || '',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
      transport_contract: contractFilter?.value || undefined,
    };
  };

  const {
    data: batchData,
    isLoading,
    refetch,
  } = useQuery<IBatchDetailsResponse>(
    [
      'freight-requests-list',
      selectedPage,
      pageSize,
      columnOrder,
      contractFilter,
      searchFilter,
      selectedTab,
    ],
    () => freightRepository.getBatchDetail(facilityId, id || '', buildParams()),
  );

  const resetManualFreights = () => {
    setBulkMode(undefined);
    setSelectedFreights([]);
    setContractFilter(null);
    setSelectedPage(1);
  };

  const resetFilter = () => {
    setContractFilter(null);
    setSearchFilter('');
    setSearchFilterValue('');
  };

  const quotationMutation = useMutation(
    (data: IMultipleQuotationPayload) =>
      freightRepository.sendMultipleQuotation(facilityId, id as string, data),
    {
      onSuccess: (data) => {
        message.success(data.message);
        setIsQuotationModalDisabled(false);
        setIsBulkModalVisible(false);
        resetManualFreights();
        refetch();
      },
      onError: (error) => {
        message.error(String(error));
        setIsQuotationModalDisabled(false);
      },
    },
  );

  const notificationMutation = useMutation(
    (data: IMultipleNotificationPayload) =>
      freightRepository.sendMultipleNotification(facilityId, id as string, data),
    {
      onSuccess: () => {
        message.success('Assignment sent successfully');
        setIsBulkModalVisible(false);
        setIsNotificationModalDisabled(false);
        resetManualFreights();
        refetch();
      },
      onError: (error) => {
        message.error(String(error));
        setIsNotificationModalDisabled(false);
      },
    },
  );

  const isBulkButtonDisabled = () => {
    if (!bulkMode) return true;

    if (bulkMode === BULK_MODE_MANUALLY) {
      return selectedFreights.length === 0;
    }

    return false;
  };

  const quotationHandler = (data: {
    deadline: Date;
    providers: IProviders[];
    transport_contract: string;
  }) => {
    if (!data.providers.length) {
      message.error('Enter at least 1 address email');
      return false;
    }
    if (!data.deadline) {
      message.error('Enter deadline date');
      return false;
    }

    const result: IMultipleQuotationPayload = {
      ...data,
      batch_uuid: id ?? '',
      deadline: moment(data.deadline).format('YYYY-MM-DDTHH:mm'),
      all_not_processed: bulkMode === BULK_MODE_ALL || bulkMode === BULK_MODE_CONTRACTS,
      bookings_uuids: selectedFreights,
      transport_contract: data.transport_contract,
    };

    setIsQuotationModalDisabled(true);
    return quotationMutation.mutate(result);
  };

  const notificationHandler = () => {
    setIsNotificationModalDisabled(true);
    notificationMutation.mutate({
      all_assigned: bulkMode === BULK_MODE_ALL,
      bookings_uuids: selectedFreights,
    });
  };

  const isNotProcessed = bulkType === BULK_NOT_PROCESSED;
  const isRequestButtonDisabled = isNotProcessed
    ? !batchData?.send_quotation_request_button_enabled
    : !batchData?.send_award_notification_button_enabled;
  const tooltipTextForDisabled = isNotProcessed
    ? 'No freights for this cycle are pending for quotation request'
    : 'No freights for this cycle are pending assignment notification';

  const tableData = batchData?.results || [];
  const tableFilteredData =
    bulkMode === BULK_MODE_MANUALLY && bulkType === BULK_ASSIGNED
      ? tableData.filter(({ status }) => status === BULK_ASSIGNED)
      : tableData;

  return (
    <Spin spinning={isLoading}>
      <section className="hbh-container booking-list batch-details">
        <QuotationModal
          isModalOpen={isBulkModalVisible && bulkType === BULK_NOT_PROCESSED}
          setIsModalOpen={setIsBulkModalVisible}
          title="Open bid for freights"
          type="batch"
          resetOnClose
          cityName={batchData?.city_name}
          onButtonClick={quotationHandler}
          mutation={quotationMutation}
          disabled={isQuotationModalDisabled}
          persistedContract={
            bulkMode === BULK_MODE_MANUALLY && contractFilter ? contractFilter : undefined
          }
          deadline={batchData?.current_time}
        />
        <BatchReport
          isModalVisible={isAwardModalVisible}
          batchId={id || ''}
          setIsModalVisible={setIsAwardModalVisible}
          facilityId={facilityId}
        />
        <Modal
          footer={null}
          title={
            <div className="modal-title">
              <p>Confirm award notification</p>
            </div>
          }
          closeIcon={
            <Tip text={isNotificationModalDisabled ? 'Please wait. Emails are being sent.' : ''}>
              <CloseOutlined />
            </Tip>
          }
          width="50%"
          visible={isBulkModalVisible && bulkType === BULK_ASSIGNED}
          onCancel={() => !isNotificationModalDisabled && setIsBulkModalVisible(false)}
        >
          <div className="assigned-notification-modal">
            <Spin spinning={isNotificationModalDisabled}>
              <p className="label">
                An email to the selected providers will be sent and status will be changed
                to&quot;Assignment notified&quot;
              </p>
            </Spin>
            <div className="buttons">
              <Button
                onClick={() => setIsBulkModalVisible(false)}
                text="Cancel"
                variant="submit"
                className="cancel-button"
                disabled={isNotificationModalDisabled}
              />
              <Tip text={isNotificationModalDisabled ? 'Please wait. Emails are being sent.' : ''}>
                <div>
                  <Button
                    onClick={notificationHandler}
                    text="Confirm & Send"
                    variant="submit"
                    className="send-button"
                    disabled={isNotificationModalDisabled}
                  />
                </div>
              </Tip>
            </div>
          </div>
        </Modal>
        <PageTitle
          filters={
            <HeaderSummary
              status={batchData?.batch_status || ''}
              freightsNumber={batchData?.total_number_of_freights || 0}
            />
          }
          className="section-title"
          // @ts-ignore
          title={
            <p>
              CYCLE DETAILS - <strong>{batchData?.period}</strong>
            </p>
          }
          bottomLine
        />
        <Tabs
          className="tabs"
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={(index) => {
            setSelectedTab(index);
            sessionStorage.setItem('batchDetailsTab', index);
            resetManualFreights();
          }}
        />
        <section className="filters">
          <div className="status-filter-dropdown">
            <div className="input-wrapper">
              <p className="input-label">Search Freight</p>
              <Search
                className="search-input"
                placeholder="Insert"
                value={searchFilterValue}
                onSearch={() => setSearchFilter(searchFilterValue)}
                onChange={(e) => setSearchFilterValue(e.target.value)}
                id="search-input"
                allowClear
                enterButton
              />
            </div>
            <Dropdown
              className="filter status-filter outline"
              label={<p className="hbh-select-label">Filter by transport contract</p>}
              value={contractFilter}
              placeholder="All contracts"
              options={
                selectedTab === 'Processed'
                  ? batchData?.options?.processed_transport_contracts
                  : batchData?.options.not_processed_transport_contracts
              }
              onChange={(val) => {
                setSelectedFreights([]);
                setSelectedPage(1);
                setContractFilter(val);
              }}
            />
            <span
              style={{ display: bulkMode === BULK_MODE_MANUALLY ? 'none' : 'block' }}
              onClick={resetFilter}
              className="reset"
            >
              Reset filters
            </span>
          </div>
          <div className="action-buttons">
            {selectedTab === 'Processed' && (
              <Button
                onClick={() => setIsAwardModalVisible(true)}
                className="export-button"
                text="Export award"
                variant="submit"
              />
            )}
            <Tip text={isRequestButtonDisabled ? tooltipTextForDisabled : ''}>
              <AntdDropdown
                overlayClassName="bulk-dropdown"
                disabled={isRequestButtonDisabled}
                overlay={
                  <MenuList
                    freightType={isNotProcessed ? 'To be processed' : 'Assigned'}
                    bulkMode={bulkMode}
                    isMultipleContracts={Boolean(
                      (batchData?.options?.not_processed_transport_contracts?.length || 0) > 1,
                    )}
                    setBulkMode={setBulkMode}
                  />
                }
              >
                <div className="bulk-request-wrapper">
                  <Button
                    className="bulk-request-button"
                    icon={<MailFilled />}
                    text={
                      isNotProcessed ? 'SEND BULK REQUEST FOR QUOTATION' : 'SEND AWARD NOTIFICATION'
                    }
                    onClick={() => setIsBulkModalVisible(true)}
                    disabled={isBulkButtonDisabled()}
                    variant="submit"
                  />

                  {bulkMode === BULK_MODE_MANUALLY && (
                    <div>
                      {selectedFreights.length} selected{' '}
                      <span onClick={resetManualFreights} className="reset">
                        Reset
                      </span>
                    </div>
                  )}
                </div>
              </AntdDropdown>
            </Tip>
          </div>
        </section>
        <div className="table table-batch-requests table-scrolling">
          <Table
            columns={getColumns(
              bulkMode,
              selectedFreights,
              setSelectedFreights,
              navigate,
              id || '',
              selectedTab,
              contractFilter,
            )}
            data={tableFilteredData}
            rowKey="uuid"
            variant="dark"
            isLoading={false}
            onChangeColumnOrder={setColumnOrder}
            locale={{
              emptyText: <EmptyBox />,
            }}
          />
          <Pagination
            className="pagination"
            selectedPage={selectedPage}
            showJumper
            showPageSize
            totalPages={batchData?.count || 0}
            variant="dark"
            onPageChange={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        </div>
      </section>
      <Tooltip id="global-tooltip" />
    </Spin>
  );
};

export default BatchDetailsPage;
