/* istanbul ignore file */

import React, { FC, useState } from 'react';
import './styles.scss';
import { DetailsContainer, Table, Pagination, Button } from '@unbooking/ui-modules';
import { useRepository } from '@context';
import { IRequestListParams, IVendorLetters } from '@common/interfaces';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import { DEFAULT_DATE_FORMAT } from '@common/constants';
import moment from 'moment';

const columns = [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Generated at',
    sorter: true,
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'number_of_line_items',
    key: 'number_of_line_items',
    title: 'Line items',
  },
  {
    dataIndex: 'transport_contract',
    key: 'transport_contract',
    title: 'Reference T&C',
    sorter: true,
  },
  {
    dataIndex: 'type',
    key: 'type',
    title: 'Type',
    sorter: true,
  },
  {
    dataIndex: 'file_url',
    key: 'file_url',
    title: '',
    render: (record: string) =>
      record ? <Button onClick={() => window.open(record, '_blank')} text="Open" /> : '-',
  },
];

const Letters: FC = () => {
  const { freightRepository } = useRepository();
  const facilityId = localStorage.getItem('facility')!;
  const { id: vendorId = '' } = useParams<{ id: string }>();

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [columnOrder, setColumnOrder] = useState<string>('');

  const buildParams = (): IRequestListParams => {
    return {
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
    };
  };

  const { data, isLoading } = useQuery<IVendorLetters>(
    ['freight-vendor-letters', selectedPage, pageSize, columnOrder],
    () => freightRepository.getVendorLetters(facilityId, vendorId, buildParams()),
  );

  return (
    <Spin spinning={isLoading}>
      <div className="letters-page">
        {data && (
          <>
            <DetailsContainer
              items={[
                {
                  content: `${data.number_of_award_letters}`,
                  label: 'Award Letters',
                },
                {
                  content: `${data.number_of_termination_letters}`,
                  label: 'Termination Letters',
                },
              ]}
            />
            <div className="table table-scrolling">
              <Table
                columns={columns}
                data={data.results || []}
                rowKey="uuid"
                loading={isLoading}
                variant="dark"
                isLoading={false}
                onChangeColumnOrder={setColumnOrder}
              />
              <Pagination
                className="pagination"
                selectedPage={selectedPage}
                showJumper
                showPageSize
                totalPages={data.count || 0}
                variant="dark"
                onPageChange={setSelectedPage}
                onPageSizeChange={setPageSize}
              />
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default Letters;
