import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { Spin } from 'antd';
import { useRepository } from '@context';
import { Menu } from '@components';
import { DocIcon, LetterIcon } from '@assets/svg';
import { Switches } from '@common/interfaces';

const LettersMenu: FC = () => {
  const { freightRepository } = useRepository();

  const { data: switches, isLoading } = useQuery<Switches>(['switches'], () =>
    freightRepository.getSwitches(),
  );

  const buttons = [
    {
      description: 'Create and track award letters for selected quotations',
      icon: <LetterIcon />,
      id: 'award',
      label: 'Award letters',
      link: '/letters/award-letters',
    },
    ...(switches?.termination_letters
      ? [
          {
            description: 'Manage termination letters for transport rejections',
            icon: <DocIcon />,
            id: 'termination',
            label: 'Termination letters',
            link: '/letters/termination-letters',
          },
        ]
      : []),
  ];

  return (
    <Spin spinning={isLoading}>
      <Menu buttons={buttons} centered />
    </Spin>
  );
};

export default LettersMenu;
