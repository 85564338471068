import { useField } from 'informed';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import React, { useEffect } from 'react';

import './styles.scss';

interface Values {
  [key: string]: Record<string, unknown>;
}

interface IDecimalOptions extends NumberFormatProps {
  max_length?: number;
}

export interface IWFPNumberFormatTextField {
  className?: string;
  decimalOptions: IDecimalOptions;
  disabled?: boolean;
  errorClassName?: string;
  field: string;
  initialValue?: string;
  label?: string | JSX.Element;
  labelClassName?: string;
  onBlur?: (value: React.ChangeEvent<HTMLInputElement>) => unknown;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  validate?: (value: string, values: Values) => void | string;
  validateOnBlur?: boolean;
}

const NumberFormatField = (props: IWFPNumberFormatTextField): JSX.Element => {
  const { fieldApi, fieldState, ref, render } = useField(props as any);
  const { setValue } = fieldApi;
  const { value: fieldValue, error } = fieldState as any;
  const { initialValue, decimalOptions } = props;

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue, setValue]);

  return render(
    <>
      <NumberFormat
        {...decimalOptions}
        ref={ref}
        className="format-number-field"
        value={!fieldValue && fieldValue !== 0 ? '' : fieldValue}
        isAllowed={(values) => {
          if (decimalOptions?.max_length) {
            return values.value.length <= decimalOptions?.max_length;
          }
          return true;
        }}
        onValueChange={({ value }: { value: string }) => {
          setValue(value);
        }}
        onChange={() => null}
      />
      {error && <small>{error}</small>}
    </>,
  );
};

export default NumberFormatField;
