import React, { FC } from 'react';
import './styles.scss';

interface IProps {
  status: string;
  freightsNumber: number;
}

const BatchHeaderSummary: FC<IProps> = ({ status, freightsNumber }) => {
  return (
    <div className="batch-summary">
      <div>
        <span className={`status-dot ${status === 'In Progress' && 'active'}`} />
        <span className="status">
          Cycle status: <span className="value">{status}</span>
        </span>
      </div>
      <span className="breaking-line" />
      <div>
        <div className="status-dot" />
        <span className="status">
          Total Freights: <span className="value">{freightsNumber}</span>
        </span>
      </div>
    </div>
  );
};

export default BatchHeaderSummary;
