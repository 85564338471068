import React, { FC } from 'react';

import { Switch } from 'antd';
import css from './styles.module.scss';

interface ISwitcherExpanded {
  checked: boolean;
  checkedChildren?: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange: () => void;
  unCheckedChildren?: string;
  icon?: 'sign' | 'award';
}

const SwitcherExpanded: FC<ISwitcherExpanded> = ({
  checked,
  checkedChildren = 'YES',
  defaultChecked = false,
  disabled = false,
  onChange,
  unCheckedChildren = 'NO',
  icon,
}) => {
  return (
    <div className={css.wrapper}>
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${css.switch} ${css[`${icon}Icon`]}`}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        disabled={disabled}
        defaultChecked={defaultChecked}
      />
    </div>
  );
};

export default SwitcherExpanded;
