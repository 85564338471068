import React, { FC, ChangeEvent } from 'react';
import './styles.scss';

interface ISwitcher {
  checked?: boolean;
  label?: string;
  leftText: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  rightText: string;
  disabled?: boolean;
}

const Switcher: FC<ISwitcher> = ({
  checked,
  label,
  leftText,
  onChange,
  rightText,
  disabled = false,
}) => {
  return (
    <div className={`switcher-wrapper ${disabled ? 'disabled' : ''}`}>
      {label && <div className="switcher-label">{label}</div>}
      <div className={`switcher-text st-left ${!checked ? 'switcher-text-active' : ''}`}>
        {leftText}
      </div>
      <label htmlFor="switcher">
        <input
          checked={checked}
          className="switcher-input"
          id="switcher"
          onChange={onChange}
          type="checkbox"
        />
        <div className="switcher" />
      </label>
      <div className={`switcher-text st-right ${checked ? 'switcher-text-active' : ''}`}>
        {rightText}
      </div>
    </div>
  );
};

export default Switcher;
