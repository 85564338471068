import React, { FC } from 'react';
import { Table } from '@ui-modules';
import './styles.scss';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@common/constants';
import EmptyBox from '@components/EmptyBox';
import { NormalizedQuotation, groupByProvider } from '../../utils';

interface IAssignmentDetails {
  quotations: Array<NormalizedQuotation>;
}

const columns = [
  {
    title: 'Line item',
    dataIndex: 'column_order_number',
    key: 'column_order_number',
  },
  {
    title: 'Start loading date',
    dataIndex: 'pickup_date',
    key: 'pickup_date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    title: 'Delivery completion due date',
    dataIndex: 'dropoff_date',
    key: 'dropoff_date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    title: 'Destination name',
    dataIndex: 'destination_name',
    key: 'destination_name',
    render: (record: string) => record || '-',
  },
  {
    title: 'Truck type',
    dataIndex: 'truck_type',
    key: 'truck_type',
    render: () => 'n.a.',
  },
  {
    title: 'Truck Plate number',
    dataIndex: 'plate_number',
    key: 'plate_number',
    render: () => 'n.a.',
  },
  {
    title: 'Driver name',
    dataIndex: 'driver_name',
    key: 'driver_name',
    render: () => 'n.a.',
  },
  {
    title: 'Driver surname',
    dataIndex: 'driver_surname',
    key: 'driver_surname',
    render: () => 'n.a.',
  },
];

const AssignmentDetails: FC<IAssignmentDetails> = ({ quotations }) => {
  const groupedProviders = groupByProvider(quotations);

  return (
    <div className="assignment-details-container">
      <div className="qm-title">Assignment details recap</div>
      {groupedProviders.map(({ items, label }) => {
        return (
          <div key={label}>
            <span className="provider-title">{label}</span>
            <Table
              variant="dark"
              data={items}
              rowKey="uuid"
              columns={columns}
              locale={{
                emptyText: <EmptyBox />,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default AssignmentDetails;
