import React from 'react';
import { ReactComponent as EmptyBoxIcon } from '@assets/svg/empty_box.svg';
import './styles.scss';

const EmptyBox = (): JSX.Element => {
  return (
    <div className="empty-box-content">
      <EmptyBoxIcon />
      <p>No data to see here</p>
    </div>
  );
};

export default EmptyBox;
