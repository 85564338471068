/* eslint-disable @typescript-eslint/no-explicit-any */

// Update one item in react state array by id (new item merge with old, ID will be saved)
export const stateItemUpdate = (prevArray: Array<any>, item: any): Array<any> => {
  const array = [...prevArray];
  const currentItemIndex = array.findIndex((i) => i.uuid === item.uuid);
  if (currentItemIndex >= 0) {
    const oldItem = array[currentItemIndex];
    array[currentItemIndex] = { ...oldItem, ...item };
  }
  return array;
};
