/* eslint-disable @typescript-eslint/no-shadow */
import React, { FC, useState } from 'react';
import { DatePicker, Spin } from 'antd';
import { PageTitle, Table } from '@ui-modules';
import moment from 'moment';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_SERVER_FORMAT,
  DEFAULT_WEIGHT_FORMAT,
} from '@common/constants';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import {
  GefsLocationsResponse,
  IContractsListResponse,
  ReportsDataResponse,
  ReportsDataVendorStatsItem,
} from '@common/interfaces';
import { Dropdown, LineChartWidget, LineWidget, SimpleBlock, Button, BarWidget } from '@components';
import { Tooltip } from 'react-tooltip';
import { Nullable } from '@common/interfaces/Common';
import { CalendarIcon } from '@assets/svg';
import { IDropdownOption } from '@components/Dropdown';
import { debounce } from '@common/utils';
import NumberFormat from 'react-number-format';
import css from './styles.module.scss';

interface IRangeDatePicker {
  from_date: string;
  to_date: string;
}

interface IRangeDatePickerExtended extends IRangeDatePicker {
  isDefault?: boolean;
}

const { RangePicker } = DatePicker;

const columns = [
  {
    dataIndex: 'vendor_name',
    key: 'vendor_name',
    title: 'Vendor name',
    sorter: (a: ReportsDataVendorStatsItem, b: ReportsDataVendorStatsItem) => {
      if (a.vendor_name < b.vendor_name) return -1;
      if (a.vendor_name > b.vendor_name) return 1;
      return 0;
    },
  },
  {
    dataIndex: 'participation_rate',
    key: 'participation_rate',
    title: 'Participation',
    render: (record: number) => `${record} %`,
    sorter: (a: ReportsDataVendorStatsItem, b: ReportsDataVendorStatsItem) =>
      a.participation_rate - b.participation_rate,
  },
  {
    dataIndex: 'award_rate',
    key: 'award_rate',
    title: 'Award rate',
    render: (record: string) => `${record} %`,
    sorter: (a: ReportsDataVendorStatsItem, b: ReportsDataVendorStatsItem) =>
      a.award_rate - b.award_rate,
  },
  {
    dataIndex: 'termination_rate',
    key: 'termination_rate',
    title: 'Cancellation/Termination rate',
    render: (record: string) => `${record} %`,
    sorter: (a: ReportsDataVendorStatsItem, b: ReportsDataVendorStatsItem) =>
      a.termination_rate - b.termination_rate,
  },
];

const typesOptions = [
  { label: 'Spot', value: 'spot' },
  { label: 'Batch', value: 'batch' },
];

const ReportsPage: FC = () => {
  const { freightRepository } = useRepository();
  const facilityId = localStorage.getItem('facility')!;
  const country = localStorage.getItem('country')!;

  const defaultDates = {
    from_date: moment().subtract(6, 'months').format(DEFAULT_SERVER_FORMAT),
    to_date: moment().format(DEFAULT_SERVER_FORMAT),
    isDefault: true,
  };

  const [datesFilter, setDatesFilter] = useState<IRangeDatePickerExtended>(defaultDates);
  const [contractFilter, setContractFilter] = useState<Nullable<IDropdownOption>>(null);
  const [typeFilter, setTypeFilter] = useState<Nullable<IDropdownOption>>(null);
  const [originFilter, setOriginFilter] = useState<Nullable<IDropdownOption>>(null);
  const [destinationFilter, setDestinationFilter] = useState<Nullable<IDropdownOption>>(null);

  const { data, isLoading } = useQuery<ReportsDataResponse>(
    [`reports-data`, datesFilter, contractFilter, originFilter, destinationFilter, typeFilter],
    () =>
      freightRepository.getReportsData(facilityId, {
        booking_type: typeFilter?.value,
        contracts: contractFilter?.value,
        destination_location: destinationFilter?.label,
        from_date: datesFilter?.from_date,
        loading_location: originFilter?.label,
        to_date: datesFilter?.to_date,
      }),
  );

  const { data: contractsList } = useQuery<IContractsListResponse>('contracts-options', () =>
    freightRepository.getContractsList({
      facility: facilityId,
    }),
  );

  const contractsOptions = contractsList
    ? contractsList.results.map((item) => {
        return { label: item.name, value: item.uuid };
      })
    : [];

  const { data: gefsData } = useQuery<GefsLocationsResponse>('gefs-options', () =>
    freightRepository.getGefsLocations({
      search: '',
      country,
    }),
  );

  const gefsOptions = gefsData?.results || [];

  const loadOptions = (query: string, callback: (arg: Array<IDropdownOption>) => void) => {
    freightRepository
      .getGefsLocations({
        search: query.trim(),
        country,
      })
      .then((data: GefsLocationsResponse) => callback(data.results));
  };

  const formatContractOptionLabel = ({ label }: { label: string }) => (
    <div className={css.contractOption}>{label}</div>
  );

  const formatLocationOptionLabel = ({
    label,
    location_type,
  }: {
    label: string;
    location_type: string;
  }) => (
    <div className={css.locationOption}>
      <div>{label}</div>
      <div className={css.info}>{location_type ? `${location_type}` : ''}</div>
    </div>
  );

  const freightTypesData = data
    ? [
        {
          label: 'Dispatch cycle',
          value: data.freight_types.dispatch_cycle_mt,
          backgroundColor: '#045d53',
        },
        {
          label: 'Spot',
          value: data.freight_types.spot_mt,
          backgroundColor: '#0ac9a9',
        },
      ]
    : [];

  const costMtData = data
    ? [
        {
          title: 'Awarded - Cost/MT history',
          backgroundColor: '#2f80ed',
          data: data.cost_for_weight.awarded,
        },
        {
          title: 'All quotations - Cost/MT history',
          backgroundColor: '#d57000',
          data: data.cost_for_weight.all,
        },
      ]
    : [];

  return (
    <div className={css.container}>
      <PageTitle
        className="section-title"
        title="Freight contracting overview - only awarded freights"
        bottomLine
      />
      <Spin spinning={isLoading}>
        <div className={css.content}>
          <div className={css.filter}>
            <div className={css.field}>
              <div className={css.label}>Filter by dates</div>
              <RangePicker
                format={DEFAULT_DATE_FORMAT}
                value={
                  datesFilter
                    ? [moment(datesFilter.from_date), moment(datesFilter.to_date)]
                    : undefined
                }
                onChange={(value) => {
                  if (value === null) return setDatesFilter(defaultDates);
                  return setDatesFilter({
                    from_date: value[0]?.format(DEFAULT_SERVER_FORMAT) || '',
                    to_date: value[1]?.format(DEFAULT_SERVER_FORMAT) || '',
                  });
                }}
                disabledDate={(current) => current && current > moment().endOf('day')}
                separator="to"
                suffixIcon={<CalendarIcon className="calendar-icon" />}
              />
              {!datesFilter.isDefault && (
                <Button
                  text="Reset to default filter"
                  variant="text"
                  className={css.resetButton}
                  onClick={() => setDatesFilter(defaultDates)}
                />
              )}
            </div>
            <div className={css.field}>
              <div className={css.label}>Filter by contract</div>
              <Dropdown
                defaultOptions={contractsOptions}
                formatOptionLabel={formatContractOptionLabel}
                onChange={setContractFilter}
                placeholder="All contracts"
                value={contractFilter}
              />
              {contractFilter && (
                <Button
                  text="Reset filter"
                  variant="text"
                  className={css.resetButton}
                  onClick={() => setContractFilter(null)}
                />
              )}
            </div>
            <div className={css.field}>
              <div className={css.label}>Filter by origin</div>
              <Dropdown
                defaultOptions={gefsOptions}
                formatOptionLabel={formatLocationOptionLabel}
                loadOptions={debounce(loadOptions, 500)}
                onChange={setOriginFilter}
                placeholder="All locations"
                value={originFilter}
              />
              {originFilter && (
                <Button
                  text="Reset filter"
                  variant="text"
                  className={css.resetButton}
                  onClick={() => setOriginFilter(null)}
                />
              )}
            </div>
            <div className={css.field}>
              <div className={css.label}>Filter by destination</div>
              <Dropdown
                defaultOptions={gefsOptions}
                formatOptionLabel={formatLocationOptionLabel}
                loadOptions={debounce(loadOptions, 500)}
                onChange={setDestinationFilter}
                placeholder="All locations"
                value={destinationFilter}
              />
              {destinationFilter && (
                <Button
                  text="Reset filter"
                  variant="text"
                  className={css.resetButton}
                  onClick={() => setDestinationFilter(null)}
                />
              )}
            </div>
            <div className={css.field}>
              <div className={css.label}>Filter by type</div>
              <Dropdown
                defaultOptions={typesOptions}
                onChange={setTypeFilter}
                placeholder="All types"
                value={typeFilter}
              />
              {typeFilter && (
                <Button
                  text="Reset filter"
                  variant="text"
                  className={css.resetButton}
                  onClick={() => setTypeFilter(null)}
                />
              )}
            </div>
          </div>

          {data && (
            <>
              <div className={css.row}>
                <SimpleBlock label="Total freights" value={data.summary.total_bookings} />
                <SimpleBlock label="Total line items" value={data.summary.total_line_items} />
                <SimpleBlock
                  label="MT assigned"
                  value={
                    <NumberFormat
                      displayType="text"
                      value={data.summary.mt_assigned}
                      {...DEFAULT_WEIGHT_FORMAT}
                    />
                  }
                />
                <SimpleBlock label="Quotations received" value={data.summary.quotations_received} />
                <SimpleBlock
                  label="Vendors participation (avg)"
                  value={data.summary.vendors_participation}
                />
              </div>
              <LineWidget data={freightTypesData} title="Freight types (by MT)" />
              <LineChartWidget
                data={costMtData}
                title={
                  datesFilter.isDefault ? `Average cost/MT - last 6 months` : 'Average cost/MT'
                }
              />
              <div className={css.row}>
                <div className={css.block}>
                  <Table
                    columns={columns}
                    data={data.vendor_stats}
                    rowKey="vendor_name"
                    variant="dark"
                  />
                </div>
                <div className={css.block}>
                  <BarWidget title="MT assignment breakdown" data={data.weight_assignment} />
                </div>
              </div>
            </>
          )}
        </div>
      </Spin>
      <Tooltip id="global-tooltip" />
    </div>
  );
};

export default ReportsPage;
