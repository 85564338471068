import React from 'react';
import { Spin } from 'antd';
import './styles.scss';

const CallbackPage: React.FC = () => {
  return (
    <section className="login-container">
      <div className="login-panel callback-panel">
        <Spin size="large" spinning />
      </div>
    </section>
  );
};

export default CallbackPage;
