import { AxiosInstance } from 'axios';
import { axios as axiosInstance } from '@common/utils';
import React, { createContext, FC, useContext } from 'react';
import FreightRepository from '@common/repositories/freight.context';

const getRepositories = (axios: AxiosInstance) => ({
  freightRepository: new FreightRepository(axios),
});

const RepositoryContext = createContext(getRepositories(axiosInstance));

interface IRepositoryProvider {
  axios: AxiosInstance;
  children?: React.ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children, axios }) => {
  return (
    <RepositoryContext.Provider value={getRepositories(axios)}>
      {children}
    </RepositoryContext.Provider>
  );
};

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
