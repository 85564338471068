import moment from 'moment';
import React from 'react';
import { IDropdownOption } from '@ui-modules/types';
import { IRequestDetailsConfig } from '@common/interfaces/Common';
import { ILineItem, IRequestor, ISender } from '@common/interfaces';
import { ReactComponent as DestinationAIcon } from '@assets/svg/destination_icon_a.svg';
import { ReactComponent as CloseIcon } from '@assets/svg/close.svg';
import {
  getDateComponent,
  getInputComponent,
  getLocationComponent,
} from '@pages/RequestsDetailsPage/components/RequestsDetails/utils';
import NumberFormatField from '@components/NumberFormat';
import NumberFormat from 'react-number-format';
import { Dropdown } from '@ui-modules/informed';
import { Button } from '@unbooking/ui-modules';
import {
  DEFAULT_DECIMAL_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_WEIGHT_FORMAT,
} from '@common/constants';

interface IColumns {
  title: string | JSX.Element;
  dataIndex: string;
  key: string;
  render?: (
    value: string,
    record: ILineItem,
    index: number,
  ) => string | JSX.Element | null | undefined;
}

const columnsConfig: (
  isEditMode: boolean,
  goodCategoriesOptions: Array<IDropdownOption>,
  loadingUnitsOptions: Array<IDropdownOption>,
  wvUnitsOptions: Array<IDropdownOption>,
  stackableOptions: Array<IDropdownOption>,
  deleteItem: (uuid: string, isDraft: boolean) => void,
) => IColumns[] = (
  isEditMode,
  goodCategoriesOptions,
  loadingUnitsOptions,
  wvUnitsOptions,
  stackableOptions,
  deleteItem,
) => [
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    render: (_value, record: any) => {
      if (!record.id || !isEditMode) return null;
      return (
        <Button
          className="close-button"
          data-testid={`close-button-${record.id}`}
          icon={<CloseIcon />}
          onClick={() => deleteItem(record.uuid, record.isDraft)}
        />
      );
    },
  },
  {
    title: '#',
    dataIndex: 'order',
    key: 'order',
    render: (value) => {
      if (!value) return null;
      return value;
    },
  },
  {
    title: 'Type of good',
    dataIndex: 'good_category',
    key: 'good_category',
    render: (value: string, record: any) => {
      if (!record.id) return null;
      if (!isEditMode) return value;
      const index = record.uuid || record.id;
      return (
        <Dropdown
          defaultValue={goodCategoriesOptions.find((option) => option.value === value)}
          name={`line_items.${index}.good_category`}
          options={goodCategoriesOptions}
        />
      );
    },
  },
  {
    title: 'Short description',
    dataIndex: 'short_description',
    key: 'short_description',
    render: (value: string, record: any) => {
      if (!record.id) return null;
      if (!isEditMode) return value;
      const index = record.uuid || record.id;
      return getInputComponent(value, isEditMode, `line_items.${index}.short_description`);
    },
  },
  {
    title: 'Weight/Volume Unit',
    dataIndex: 'weight_volume_unit',
    key: 'weight_volume_unit',
    render: (value: string, record: any) => {
      if (!record.id) return null;
      if (!isEditMode) return value;
      const index = record.uuid || record.id;
      return (
        <Dropdown
          defaultValue={wvUnitsOptions.find((option) => option.label === value)}
          name={`line_items.${index}.weight_volume_unit`}
          options={wvUnitsOptions}
        />
      );
    },
  },
  {
    title: isEditMode ? 'Weight (MT)' : 'Total weight (MT)',
    key: 'weight',
    dataIndex: 'weight',
    render: (value: string, record: any) => {
      if (!isEditMode)
        return (
          <NumberFormat
            displayType="text"
            value={!record.id ? record.weight : record.total_weight}
            {...DEFAULT_WEIGHT_FORMAT}
          />
        );
      if (record.id) {
        const index = record.uuid || record.id;
        return (
          <NumberFormatField
            field={`line_items.${index}.weight`}
            decimalOptions={DEFAULT_WEIGHT_FORMAT}
            initialValue={value}
          />
        );
      }
      return null;
    },
  },
  {
    title: (
      <div>
        {isEditMode ? 'Volume' : 'Total volume'} (m<span className="pow-sign">3</span>)
      </div>
    ),
    key: 'volume',
    dataIndex: 'volume',
    render: (value: string, record: any) => {
      if (!isEditMode)
        return <NumberFormat displayType="text" value={value} {...DEFAULT_DECIMAL_FORMAT} />;
      if (record.id) {
        const index = record.uuid || record.id;
        return (
          <NumberFormatField
            field={`line_items.${index}.volume`}
            decimalOptions={DEFAULT_DECIMAL_FORMAT}
            initialValue={value}
          />
        );
      }
      return null;
    },
  },
  {
    title: 'Loading units',
    key: 'loading_units',
    dataIndex: 'loading_units',
    render: (value: string, record: any) => {
      if (!record.id) return null;
      if (!isEditMode) return value;
      const index = record.uuid || record.id;
      return (
        <Dropdown
          defaultValue={loadingUnitsOptions.find((option) => option.value === value)}
          name={`line_items.${index}.loading_units`}
          options={loadingUnitsOptions}
        />
      );
    },
  },
  {
    title: 'Number of units',
    key: 'number_of_loading_units',
    dataIndex: 'number_of_loading_units',
    render: (value: string, record: any) => {
      if (!record.id) return null;
      if (!isEditMode)
        return <NumberFormat displayType="text" value={value} {...DEFAULT_DECIMAL_FORMAT} />;
      const index = record.uuid || record.id;
      return (
        <NumberFormatField
          field={`line_items.${index}.number_of_units`}
          decimalOptions={DEFAULT_DECIMAL_FORMAT}
          initialValue={value}
        />
      );
    },
  },
  {
    title: 'Stackable',
    key: 'stackable',
    dataIndex: 'stackable',
    render: (value: any, record: any) => {
      if (!record.id) return null;
      if (!isEditMode) return value ? 'Yes' : 'No';
      const index = record.uuid || record.id;
      return (
        <Dropdown
          defaultValue={
            stackableOptions.find((option) => Boolean(parseFloat(option.value)) === value) ||
            stackableOptions[0]
          }
          name={`line_items.${index}.stackable`}
          options={stackableOptions}
        />
      );
    },
  },
];

// @todo: Refactor edit mode - that's just quick hotfix for hidden datepicker
const detailsConfig = (editMode: boolean, freightRepository: any): IRequestDetailsConfig[] => [
  {
    section_title: 'Requestor detail',
    items: [
      {
        key: 'created_at',
        label: 'CREATED AT',
        content: (record: string | undefined) =>
          record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-',
      },
      {
        key: 'requestor',
        label: 'Name',
        content: (record: IRequestor) => record.first_name,
      },
      {
        key: 'requestor',
        label: 'SURNAME',
        content: (record: IRequestor) => record.last_name,
      },
      {
        key: 'requestor',
        label: 'EMAIL',
        content: (record: IRequestor) => record.email,
      },
      {
        key: 'requestor_phone_number',
        label: 'PHONE NUMBER',
      },
      {
        key: 'requestor',
        label: 'ORGANISATION',
        content: (record: IRequestor) => record.organisation ?? '-',
      },
      {
        key: 'requesting_unit',
        label: 'REQUESTING UNIT',
      },
    ],
  },
  {
    section_title: 'Sender information',
    items: [
      {
        key: 'sender',
        label: 'SENDER FULLNAME',
        content: (record: ISender) => record.full_name,
      },
      {
        key: 'sender',
        label: 'SENDER ORGANISATION',
        content: (record: ISender, isEditMode: boolean) => {
          return getInputComponent(record.organisation, isEditMode, 'sender:organisation') ?? '';
        },
      },
      {
        key: 'sender',
        label: 'SENDER EMAIL',
        content: (record: ISender, isEditMode: boolean) => {
          return getInputComponent(record.email, isEditMode, 'sender:email') ?? '';
        },
      },
      {
        key: 'sender',
        label: 'SENDER PHONE NUMBER',
        content: (record: ISender, isEditMode: boolean) => {
          return getInputComponent(record.phone_number, isEditMode, 'sender:phone_number') ?? '';
        },
      },
    ],
  },
  {
    section_title: 'Transport information',
    items: [
      {
        key: 'pickup_location',
        label: (
          <div className="icon-label-container">
            <DestinationAIcon className="destination-icon" />
            <span>LOADING LOCATION</span>
          </div>
        ),
        content: (record: string, isEditMode: boolean) =>
          getLocationComponent(record, isEditMode, 'pickup_location', freightRepository) ?? '',
      },
      {
        key: 'pickup_date',
        label: (
          <div className="icon-label-container">
            <DestinationAIcon className="destination-icon" />
            <span>START LOADING DATE</span>
          </div>
        ),
        content: (record: string, isEditMode: boolean) => {
          if (!isEditMode) return moment(record).format(DEFAULT_DATE_FORMAT) ?? '-';
          return getDateComponent(record, isEditMode, 'pickup_date') ?? '';
        },
      },
    ],
  },
];

export { detailsConfig, columnsConfig };
