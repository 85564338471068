import React, { FC, useEffect, useState } from 'react';
import { PageTitle, Table, Tabs, Button, Pagination, Dropdown } from '@ui-modules';
import { IStatusProperty } from '@common/interfaces/Common';
import { IDropdownOption } from '@ui-modules/types';
import './styles.scss';
import { useQuery } from 'react-query';
import { useRepository } from '@context';
import { IBatchListResponse, IRequestListParams } from '@common/interfaces';
import EmptyBox from '@components/EmptyBox';
import { ProgressBar } from '@components';

const tabs = ['Open', 'Processed'];

const getBookingStateBtn = (record: string): JSX.Element => {
  const statusProperties: IStatusProperty = {
    'Not Started': {
      className: 'btn not_processed',
      variant: 'submit',
      text: 'Not Started',
    },
    'In Progress': {
      className: 'btn processed',
      variant: 'transparent',
      text: 'In Progress',
    },
    Closed: {
      className: 'btn assigned',
      variant: 'transparent',
      text: 'Closed',
    },
    Cancelled: {
      className: 'btn cancelled',
      variant: 'submit',
      text: 'Cancelled',
    },
    defaultProperty: {
      className: 'btn btn-default',
      variant: 'submit',
    },
  };

  const additionalProperties = statusProperties[record] || statusProperties.defaultProperty;

  /* eslint-disable react/jsx-props-no-spreading */
  return <Button text={record} {...additionalProperties} />;
};

const columns = [
  {
    dataIndex: 'period',
    key: 'start_date',
    title: 'Period',
    sorter: true,
    width: 200,
  },
  {
    dataIndex: 'number_of_freights',
    key: 'no_of_freights',
    title: '# of freights',
    width: 200,
  },
  {
    dataIndex: 'routes_awarded',
    key: 'routes_awarded',
    title: 'Routes awarded',
    width: 200,
    render: (record: number, data: any) => {
      return (
        <ProgressBar
          fraction={record / data.number_of_freights}
          customValue={`${record} / ${data.number_of_freights}`}
        />
      );
    },
  },
  {
    dataIndex: 'status',
    key: 'status',
    title: 'Status',
    width: 150,
    render: (record: string) => {
      return getBookingStateBtn(record);
    },
  },
  {
    width: 50,
    align: 'right',
    render: (record: string, data: any) => {
      return (
        <Button
          onClick={() => {
            window.location.href = `/batch/${data.uuid}`;
          }}
          className="view-button"
          text="View"
        />
      );
    },
  },
];

const BatchListPage: FC = () => {
  const country = localStorage.getItem('country');
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [columnOrder, setColumnOrder] = useState<string>('');
  const [statusFilter, setStatusFilter] = React.useState<IDropdownOption | null>();
  const facilityId = +localStorage.getItem('facility')!;
  const initialTab = sessionStorage.getItem('batchListTab') || tabs[0];
  const [selectedTab, setSelectedTab] = React.useState<string>(initialTab);

  const { freightRepository } = useRepository();

  const tab = selectedTab === 'Open' ? 'open' : 'processed';

  const buildParams = (): IRequestListParams => {
    return {
      show: tab,
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
      status: statusFilter?.value || null,
    };
  };

  const { data: list, isLoading } = useQuery<IBatchListResponse>(
    ['freight-requests-list', selectedTab, selectedPage, pageSize, columnOrder, statusFilter],
    () => freightRepository.getBatchesList(facilityId, buildParams()),
  );

  const resetFilter = () => {
    setStatusFilter(null);
  };

  useEffect(() => {
    setSelectedPage(1);
    setStatusFilter(null);
  }, [selectedTab]);

  return (
    <section className="hbh-container booking-list">
      <PageTitle
        className="section-title"
        title={`Freight dispatch cycles - ${country}`}
        bottomLine
      />
      <Tabs
        className="tabs"
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={(index) => {
          setSelectedTab(index);
          sessionStorage.setItem('batchListTab', index);
        }}
      />
      <section className="filters">
        <div className="status-filter-dropdown">
          <Dropdown
            className="filter status-filter outline"
            label={<p className="hbh-select-label">Filter by statuses</p>}
            value={statusFilter}
            placeholder="All statuses"
            options={list?.options && list?.options[tab]}
            onChange={setStatusFilter}
          />
          <span onClick={resetFilter} className="reset">
            Reset filters
          </span>
        </div>
      </section>
      <div className="table table-scrolling">
        <Table
          columns={columns}
          data={list?.results || []}
          rowKey="uuid"
          loading={isLoading}
          variant="dark"
          isLoading={false}
          onChangeColumnOrder={setColumnOrder}
          locale={{
            emptyText: <EmptyBox />,
          }}
        />
        <Pagination
          className="pagination"
          selectedPage={selectedPage}
          showJumper
          showPageSize
          totalPages={list?.count || 0}
          variant="dark"
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </div>
    </section>
  );
};

export default BatchListPage;
