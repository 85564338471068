import React, { FC } from 'react';
import { PageTitle, Tabs } from '@ui-modules';
import { Spin } from 'antd';
import { useRepository } from '@context';

import './styles.scss';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { IVendorDetails } from '@common/interfaces';
import QuotationsAnalysis from './components/QuotationsAnalysis';
import VendorInformation from './components/VendorInformation';
import Letters from './components/Letters';

// Note: Temporary hide letters tab (before docusign integration)
// const tabs = ['Vendor information', 'Quotation analysis', 'Letters'];
const tabs = ['Vendor information', 'Quotation analysis'];

const VendorDetailsPage: FC = () => {
  const country = localStorage.getItem('country');
  const { freightRepository } = useRepository();
  const facilityId = localStorage.getItem('facility')!;
  const { id: vendorId = '' } = useParams<{ id: string }>();

  const [selectedTab, setSelectedTab] = React.useState<string>(tabs[0]);

  const {
    data,
    isLoading,
    refetch: detailsRefetch,
  } = useQuery<IVendorDetails>(['freight-vendor-details'], () =>
    freightRepository.getVendorDetails(facilityId, vendorId),
  );

  let content = null;

  // eslint-disable-next-line default-case
  switch (selectedTab) {
    case tabs[0]:
      content = <VendorInformation details={data} detailsRefetch={detailsRefetch} />;
      break;
    case tabs[1]:
      content = <QuotationsAnalysis details={data} />;
      break;
    case tabs[2]:
      content = <Letters />;
      break;
  }

  const title = `Vendor details - ${country} - ${data?.legal_name}`;

  return (
    <Spin spinning={isLoading}>
      <div className="hbh-container vendor-details-page">
        <PageTitle className="section-title" title={title} bottomLine />
        <Tabs
          className="tabs"
          tabs={tabs}
          selectedTab={tabs.indexOf(selectedTab)}
          onTabSelect={setSelectedTab}
        />
        <div className="content">{content}</div>
      </div>
    </Spin>
  );
};

export default VendorDetailsPage;
