/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';

import css from './styles.module.scss';

export type ButtonColor = 'allports' | 'amazon' | 'limeade';

export type ButtonVariant =
  | ButtonColor
  | 'default'
  | 'link'
  | 'transparent'
  | 'icon'
  | 'primary'
  | 'text';

export interface IButton {
  className?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  link?: string;
  onClick?: (e: Event) => void;
  routing?: boolean;
  target?: string;
  text?: string;
  title?: string;
  type?: 'button' | 'submit' | 'reset';
  variant?: ButtonVariant;
}

const Button: FC<IButton> = ({
  className = '',
  disabled = false,
  icon,
  link,
  onClick,
  routing = false,
  text = '',
  type = 'button',
  variant = 'default',
  ...props
}) => {
  const classList = `${css.btn} ${disabled ? css['btn-disabled'] : ''} ${
    variant && css[`btn-${variant}`]
  } ${className}`;

  const handleClick = useCallback((e: any) => onClick && onClick(e), [onClick]);

  const content = (
    <>
      {icon ? text.length ? <span className={css.icon}>{icon}</span> : <>{icon}</> : null}
      {text}
    </>
  );

  if (routing && link) {
    return (
      <Link to={link} className={classList} {...props}>
        {content}
      </Link>
    );
  }

  if (link) {
    return (
      <a className={classList} href={link} rel="noreferrer" {...props}>
        {content}
      </a>
    );
  }

  return (
    <button className={classList} onClick={handleClick} type={type} disabled={disabled} {...props}>
      {content}
    </button>
  );
};

export default Button;
