import React, { FC } from 'react';

import css from './styles.module.scss';

interface ISimpleBlock {
  label: string;
  value: string | number | null | JSX.Element;
}

const SimpleBlock: FC<ISimpleBlock> = ({ label, value }) => {
  return (
    <div className={css.wrapper}>
      <div className={css.label}>{label}</div>
      <div className={css.value}>{value ?? 'n.a.'}</div>
    </div>
  );
};

export default SimpleBlock;
