import React, { useState, useEffect, FC } from 'react';
import { Button, Table, PageTitle, Pagination, Tabs } from '@ui-modules';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useRepository } from '@context/repository.context';
import { IRequestListParams, TransportContract } from '@common/interfaces/Api';
import { DEFAULT_DATE_FORMAT } from '@common/constants';
import EmptyBox from '@components/EmptyBox';
import { IStatusProperty } from '@common/interfaces/Common';
import { Input } from 'antd';
import { CroppedText } from '@components';
import { Tooltip } from 'react-tooltip';
import AssignmentReport from './components/AssignmentReport';
import './styles.scss';

const { Search } = Input;

const getBookingStateBtn = (record: string): JSX.Element => {
  const statusProperties: IStatusProperty = {
    not_processed: {
      className: 'btn not_processed',
      variant: 'submit',
      text: 'Not processed',
    },
    quotes_request_sent: {
      className: 'btn quotes_request_sent',
      variant: 'transparent',
      text: 'Quotes request sent',
    },
    bid_closed: {
      className: 'btn bid_closed',
      variant: 'transparent',
      text: 'Bid closed',
    },
    processed: {
      className: 'btn processed',
      variant: 'transparent',
      text: 'Processed',
    },
    assigned: {
      className: 'btn assigned',
      variant: 'transparent',
      text: 'Assigned',
    },
    assignment_notified: {
      className: 'btn assignment_notified',
      variant: 'submit',
      text: 'Assignment notified',
    },
    cancelled: {
      className: 'btn cancelled',
      variant: 'submit',
      text: 'Cancelled',
    },
    defaultProperty: {
      className: 'btn btn-default',
      variant: 'submit',
    },
  };

  const additionalProperties = statusProperties[record] || statusProperties.defaultProperty;

  /* eslint-disable react/jsx-props-no-spreading */
  return <Button text={record} {...additionalProperties} />;
};

const tabsList = ['Not assigned', 'Assigned'];

const AssignmentsListPage: FC = () => {
  const tabs = tabsList;
  const navigate = useNavigate();
  const { id: itemUuid } = useParams<{ id: string }>();

  const initialSearchKey = sessionStorage.getItem('assignmentListSearchKey') || '';
  const initialTab = sessionStorage.getItem('assignmentListTab') || tabs[0];
  const initialSelectedPage = parseFloat(sessionStorage.getItem('assignmentListPage') ?? '1');
  const initialPageSize = parseFloat(sessionStorage.getItem('assignmentListPerPage') ?? '10');

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);
  const [selectedPage, setSelectedPage] = useState<number>(initialSelectedPage);
  const [searchFilter, setSearchFilter] = useState<string>(initialSearchKey);
  const [searchFilterValue, setSearchFilterValue] = useState<string>(initialSearchKey);
  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  const [columnOrder, setColumnOrder] = useState<string>('-created');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage.getItem('country');

  const { freightRepository } = useRepository();

  const columns = [
    {
      dataIndex: 'reference_code',
      key: 'reference_code',
      title: 'ID',
      width: 90,
      sorter: true,
    },
    {
      dataIndex: 'created',
      key: 'created',
      title: 'Request Date',
      sorter: true,
      render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
    },
    {
      dataIndex: 'transport_contract',
      key: 'transport_contract',
      title: 'Transport contract',
      sorter: true,
      render: (record: TransportContract) =>
        record ? <CroppedText text={record.name} maxLength={30} /> : '-',
    },
    {
      dataIndex: 'pickup_date',
      key: 'pickup_date',
      title: 'Start loading date',
      sorter: true,
      render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
    },
    {
      dataIndex: 'route',
      key: 'route',
      title: 'Route',
      sorter: true,
      render: (record: string) => (record ? <CroppedText text={record} maxLength={30} /> : '-'),
    },
    ...(selectedTab === 'Assigned'
      ? [
          {
            dataIndex: 'deadline',
            key: 'deadline',
            title: 'Submission deadline',
            sorter: true,
            render: (record: string) => (record ? moment(record).format(DEFAULT_DATE_FORMAT) : '-'),
          },
        ]
      : []),
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      width: 150,
      render: (record: string) => getBookingStateBtn(record),
    },
    {
      width: 100,
      render: (_record: string, data: any) => (
        <Button
          onClick={() => {
            navigate(`/requests/${data.uuid}`, { state: { batchId: itemUuid } });
          }}
          className="view-button"
          text="View"
        />
      ),
    },
  ];

  const clearPagination = () => {
    setSelectedPage(1);
    setPageSize(10);
    sessionStorage.setItem('assignmentListPage', '1');
    sessionStorage.setItem('assignmentListPerPage', '10');
  };

  const clearSearch = () => {
    setSearchFilterValue('');
    setSearchFilter('');
  };

  const clearParams = () => {
    clearSearch();
    clearPagination();
  };

  const buildParams = (): IRequestListParams => {
    return {
      show: selectedTab === 'Assigned' ? 'assigned' : 'not_assigned',
      offset: (selectedPage - 1) * 10,
      limit: pageSize,
      ordering: columnOrder || null,
      filter_query: searchFilter || null,
    };
  };

  const { data: list, isLoading } = useQuery(
    ['freight-assignment-list', selectedTab, selectedPage, pageSize, columnOrder, searchFilter],
    () => freightRepository.getAssignmentsList(facilityId, buildParams()),
  );

  useEffect(() => {
    if (!searchFilterValue) {
      setSearchFilter('');
    }
    sessionStorage.setItem('assignmentListSearchKey', searchFilterValue);
  }, [searchFilterValue]);

  return (
    <section className="hbh-container booking-list">
      <PageTitle className="section-title" title={`Freight requests - ${country}`} bottomLine />
      <AssignmentReport
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        facilityId={facilityId}
      />
      <Tabs
        className="tabs"
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={(index) => {
          setSelectedTab(index);
          sessionStorage.setItem('assignmentListTab', index);
          clearParams();
        }}
      />
      <div className="table table-scrolling">
        <section className="filter-bar">
          <div className="input-wrapper">
            <p className="input-label">Search Freight</p>
            <Search
              className="search-input"
              placeholder="Insert"
              id="search-input"
              allowClear
              value={searchFilterValue}
              onSearch={() => setSearchFilter(searchFilterValue)}
              onChange={(e) => {
                setSearchFilterValue(e.target.value);
                clearPagination();
              }}
              enterButton
            />
          </div>
          {selectedTab === 'Assigned' && (
            <Button
              onClick={() => setIsModalVisible(true)}
              className="export-button"
              variant="submit"
              text="Export Assignment"
            />
          )}
        </section>
        <div className="table-assignments">
          <Table
            columns={columns}
            data={list?.results}
            variant="dark"
            isLoading={isLoading}
            rowKey="uuid"
            onChangeColumnOrder={setColumnOrder}
            locale={{
              emptyText: <EmptyBox />,
            }}
          />
        </div>
      </div>
      <Pagination
        className="pagination"
        selectedPage={selectedPage}
        showJumper
        showPageSize
        totalPages={list?.count}
        variant="dark"
        onPageChange={(page) => {
          setSelectedPage(page);
          sessionStorage.setItem('assignmentListPage', page.toString());
        }}
        onPageSizeChange={(perPage) => {
          setPageSize(perPage);
          sessionStorage.setItem('assignmentListPerPage', perPage.toString());
        }}
      />
      <Tooltip id="global-tooltip" />
    </section>
  );
};

export default AssignmentsListPage;
