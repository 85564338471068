import React, { FC } from 'react';
import { ReactComponent as PoweredByLogo } from '@assets/svg/powered_by_logo.svg';
import { SUPPORT_EMAIL } from '@common/constants';
import './styles.scss';

const NoAccessPage: FC = () => {
  return (
    <div className="no-access-page">
      <div className="message-container">
        <PoweredByLogo width={75} height={75} />
        <h3 className="text">
          Sorry, you don&apos;t have access to this page. If you are sure you have valid permissions
          please contact support: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </h3>
      </div>
    </div>
  );
};

export default NoAccessPage;
