/* eslint-disable react/no-array-index-key */
import React, { FC, useEffect, useRef, useState } from 'react';
import { stringToColour } from '@common/utils';
import { DataNumberItem, Nullable } from '@common/interfaces/Common';
import { DEFAULT_WEIGHT_FORMAT } from '@common/constants';
import NumberFormat from 'react-number-format';
import css from './styles.module.scss';

interface IBarWidget {
  data: Array<DataNumberItem>;
  title: string;
}

const getFormattedWeight = (sum?: number) => {
  if (!sum) return '-';
  return `${Number(sum.toFixed(4)).toLocaleString('en-US', {
    minimumFractionDigits: 4,
  })} MT`;
};

const BarWidget: FC<IBarWidget> = ({ data, title }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [barHeight, setBarHeight] = useState<Nullable<number>>(null);

  const colorizedData = data.map((item) => ({
    ...item,
    color: stringToColour(item.label),
  }));

  const total = colorizedData.reduce((acc, item) => acc + item.value, 0);

  useEffect(() => {
    if (elementRef.current) setBarHeight(elementRef.current.clientHeight);
  }, [data]);

  return (
    <div className={css.wrapper}>
      <div className={css.title}>{title}</div>
      <div className={css.content}>
        <div className={css.bar} ref={elementRef}>
          {barHeight
            ? colorizedData.map(({ value, color, label }, idx) => {
                const partHeight = (value / total) * barHeight;
                return (
                  <div
                    key={idx}
                    title={`${label} (${getFormattedWeight(value)})`}
                    style={{
                      height: partHeight,
                      background: color,
                    }}
                  />
                );
              })
            : null}
        </div>
        <div className={css.labels}>
          {colorizedData.map(({ label, value, color }) => (
            <div className={css.label} key={label}>
              <div
                className={css.mark}
                style={{
                  background: color,
                }}
              />
              <div className={css.text}>
                {label} (
                <NumberFormat displayType="text" value={value} {...DEFAULT_WEIGHT_FORMAT} /> MT)
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BarWidget;
