/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';

import css from './styles.module.scss';

interface ILineChartBlock {
  data: Array<{ label: string; value: number; backgroundColor: string }>;
  formatting?: 'default' | 'money';
  title: string;
  totalTitle?: string;
}

const LineChartBlock: FC<ILineChartBlock> = ({
  data,
  formatting = 'default',
  title,
  totalTitle,
}) => {
  const total = data.reduce((prev, { value }) => {
    return prev + value;
  }, 0);

  const sum =
    formatting === 'money'
      ? Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(total)
      : total;

  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        {title}
        {totalTitle && (
          <>
            <div className={css.note}>{totalTitle}</div>
            <div className={css.sum}>{sum}</div>
          </>
        )}
      </div>
      <div className={css.labels}>
        {data.map(({ label, value, backgroundColor }, index) => {
          if (value === 0) return null;
          const part = ((value / total) * 100).toFixed(2);

          return (
            <div className={css.label} key={index}>
              <div className={css.dot} style={{ backgroundColor }} />
              <div className={css.text}>{`${label} (${part}%)`}</div>
            </div>
          );
        })}
      </div>
      <div className={css.line}>
        {data.map(({ value, backgroundColor, label }, index) => {
          const part = ((value / total) * 100).toFixed(2);

          const valueString =
            formatting === 'money'
              ? Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(value)
              : value;

          return (
            <div
              data-tooltip-id="global-tooltip"
              data-tooltip-content={`${part}% (${valueString}) - ${label}`}
              key={index}
              className={css.block}
              style={{ width: `${part}%`, backgroundColor }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LineChartBlock;
