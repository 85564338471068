/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Button, Dropdown, Pagination, Table } from '@ui-modules';
import { ReactComponent as Arrow } from '@assets/svg/arrow-right-circle-fill.svg';
import { Modal, Spin, Tooltip, Upload, message } from 'antd';
import { ILineItemCurrency } from '@common/interfaces/Common';
import LineItemModal, { ILineItemInfoModal } from '@components/LineItemModal';
import Switcher from '@components/Switcher';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as EmptyIcon } from '@assets/svg/empty.svg';
import { ReactComponent as SortIcon } from '@assets/svg/sort_line.svg';
import './styles.scss';
import { useParams } from 'react-router';
import { convertFileToBase64 } from '@common/utils';
import { useRepository } from '@context/repository.context';
import {
  IAmendAssignmentsRequest,
  IPostNewAssignment,
  IProvidersQuotationResponse,
  Switches,
} from '@common/interfaces/Api';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
  useQuery,
} from 'react-query';
import EmptyBox from '@components/EmptyBox';
import {
  NormalizedQuotation,
  getFormattedMoney,
  groupByProvider,
} from '@pages/RequestsDetailsPage/components/QuotationManagement/utils';
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg';
import { stateItemUpdate } from '@common/utils/stateItemUpdate';
import { Tip } from '@components';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@common/constants';

interface IAssignmentListProps {
  currencies: ILineItemCurrency;
  isAssignmentFromAdminPossible: boolean;
  isAssignmentPossible: boolean;
  isLumpSum: boolean;
  multipleSelection: boolean;
  quotations: NormalizedQuotation[];
  referenceCode: string;
  resetSelectedItemsToDefault: () => void;
  setIsAssignmentPossible: Dispatch<SetStateAction<boolean>>;
  setIsLumpSum: Dispatch<SetStateAction<boolean>>;
  setQuotations: Dispatch<SetStateAction<NormalizedQuotation[]>>;
  status: string;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<IProvidersQuotationResponse, unknown>>;
}

interface ProviderCellData {
  sum: number;
  sign: string;
  selected: boolean;
  uuid: string;
}

interface ProviderItemData {
  uuid: string;
  line_item_destination: string;
  order: number;
  // providers columns
}

const TABLE_COLUMN_PER_PAGE = 5;
const COLUMN_COUNT_FOR_SCROLLING = 4;

const SORT_VARIANTS = {
  LOWEST_OVERALL: 'Lowest overall',
};

const statusDropdownCustomStyles = {
  control: () => ({
    color: 'white',
    display: 'inline-flex',
    borderColor: 'white',
    borderRadius: '8px !important',
    width: '140px',
  }),
  singleValue: (styles: any) => ({
    ...styles,
    color: 'white',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: '#fff !important',
    },
  }),
};

const sortOptions = [{ label: SORT_VARIANTS.LOWEST_OVERALL, value: SORT_VARIANTS.LOWEST_OVERALL }];

const amendmentHistoryColumns = [
  {
    dataIndex: 'created',
    key: 'created',
    title: 'Date',
    render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
  },
  {
    dataIndex: 'performed_by',
    key: 'performed_by',
    title: 'Performed by',
    render: (record: any) => `${record.first_name} ${record.last_name}`,
  },
  {
    dataIndex: 'reason',
    key: 'reason',
    title: 'Reason',
  },
  {
    dataIndex: 'files',
    key: 'files',
    title: 'File(s)',
    render: (files: any) =>
      files.map(({ file, file_name }: any) => (
        <>
          <a href={file}>{file_name}</a>
          <br />
        </>
      )),
  },
];

// Calculate table data
interface IGetTableData {
  quotations: NormalizedQuotation[];
  currencies: ILineItemCurrency;
  isLumpSum: boolean;
}
const getTableData = ({ quotations, currencies, isLumpSum }: IGetTableData) => {
  const uniqueUuids: any = {};
  const total: any = {};

  quotations.forEach((item) => {
    const {
      uuid,
      line_item_quotation_uuid,
      quote,
      column_order_number,
      quote_mt,
      destination_name,
      provider_legal_name,
      selected,
    } = item;

    const currency = currencies[provider_legal_name];

    if (!uniqueUuids[line_item_quotation_uuid]) {
      uniqueUuids[line_item_quotation_uuid] = {
        line_item_destination: destination_name,
        uuid: line_item_quotation_uuid,
        order: column_order_number,
        [provider_legal_name]: {
          sum: isLumpSum ? quote : quote_mt,
          sign: isLumpSum ? currency : `${currency}/MT`,
          selected,
          uuid,
        },
      };
    }

    if (!uniqueUuids[line_item_quotation_uuid][provider_legal_name]) {
      uniqueUuids[line_item_quotation_uuid][provider_legal_name] = {
        sum: isLumpSum ? quote : quote_mt,
        sign: isLumpSum ? currency : `${currency}/MT`,
        selected,
        uuid,
      };
    }

    // Note: Calculate table total
    if (!total[provider_legal_name]) {
      total[provider_legal_name] = {
        sum: isLumpSum ? quote : quote_mt,
        sign: isLumpSum ? currency : `${currency}/MT`,
        selected: false,
      };
    } else {
      total[provider_legal_name].sum += isLumpSum ? quote : quote_mt;
    }
  });

  interface TableRow {
    order: number;
  }

  // Note: Add sorting by line items
  const result = (Object.values(uniqueUuids) as any).sort((a: TableRow, b: TableRow) => {
    if (a.order < b.order) {
      return -1;
    }
    return 1;
  });

  // Note: for mt/costs calc average values instead totals
  if (!isLumpSum) {
    const keys = Object.keys(total);
    keys.forEach((key) => {
      // eslint-disable-next-line no-prototype-builtins
      if (total[key].hasOwnProperty('sum') && typeof total[key].sum === 'number') {
        total[key].sum /= result.length;
      }
    });
  }

  result.push({
    order: '',
    line_item_destination: '',
    uuid: 'total',
    ...total,
  });

  return result;
};

// Note: Calculate table columns
interface IGetTableColumns {
  isAssignmentPossible: boolean;
  quotations: NormalizedQuotation[];
  setLineItemInfoSelected: any;
  setQuotations: Dispatch<SetStateAction<NormalizedQuotation[]>>;
  sortedLine: null | number;
  sorting: null | string;
  tableData: any;
}

const getTableColumns = ({
  isAssignmentPossible,

  quotations,

  setLineItemInfoSelected,

  setQuotations,
  sortedLine,
  sorting,
  tableData,
}: IGetTableColumns) => {
  if (!quotations.length) return [];

  const providerColumns = groupByProvider(quotations).map(({ label }) => ({
    title: label,
    key: label,
    dataIndex: label,
    render: (record: ProviderCellData, row: ProviderItemData, index: number) => {
      if (!record) return '-'; // Note: when we don't have provider in quotation

      const { selected, sum, sign, uuid } = record;

      const isTotalItem = row.uuid === 'total';

      const sumNumber = getFormattedMoney(sum, '');

      const onClick = () => {
        // Note: disable click for total row and for disabling assignment
        if (isTotalItem || !isAssignmentPossible) return;

        // Note: set just one new selection for one provider
        // setQuotations((prevArray) => {
        //   const nextArray = [...prevArray].map((quotation) => {
        //     if (!selected) {
        //       if (quotation.line_item_quotation_uuid === row.uuid) {
        //         if (quotation.uuid === uuid) {
        //           return { ...quotation, selected: true };
        //         }
        //         return { ...quotation, selected: false };
        //       }
        //       return quotation;
        //     }
        //     if (quotation.line_item_quotation_uuid === row.uuid && quotation.uuid === uuid) {
        //       return { ...quotation, selected: false };
        //     }
        //     return quotation;
        //   });
        //   return nextArray;
        // });

        // Note: for any selections
        setQuotations((prevArray) => stateItemUpdate(prevArray, { uuid, selected: !selected }));
      };

      return (
        <div
          onClick={onClick}
          className={`${isTotalItem ? 'table-element-centered' : 'table-element-wrapper'} ${
            selected && 'selected'
          }`}
        >
          <span>{sumNumber}</span>
          {isTotalItem ? (
            sign
          ) : (
            <span className="table-element-currency">
              {sign}
              <InfoIcon
                onClick={(e) => {
                  e.stopPropagation();
                  const data = quotations.find((item) => item.uuid === uuid);
                  if (data) setLineItemInfoSelected({ data, index });
                }}
                className="tooltip-icon"
                data-testid="button-show-line-items"
              />
            </span>
          )}
        </div>
      );
    },
  }));

  if (sortedLine !== null) {
    providerColumns.sort((a, b) => {
      if (tableData[sortedLine][a.key]?.sum === null || !tableData[sortedLine][a.key]) {
        return 1;
      }
      if (tableData[sortedLine][b.key]?.sum === null || !tableData[sortedLine][b.key]) {
        return -1;
      }
      if (tableData[sortedLine][a.key].sum > tableData[sortedLine][b.key].sum) {
        return 1;
      }
      if (tableData[sortedLine][a.key].sum < tableData[sortedLine][b.key].sum) {
        return -1;
      }
      return 0;
    });
  }

  if (sorting === SORT_VARIANTS.LOWEST_OVERALL) {
    const totalItem = tableData.length - 1;
    providerColumns.sort((a, b) => {
      if (tableData[totalItem][a.key].sum > tableData[totalItem][b.key].sum) {
        return 1;
      }
      if (tableData[totalItem][a.key].sum < tableData[totalItem][b.key].sum) {
        return -1;
      }
      return 0;
    });
  }

  const columns = [
    {
      title: 'DEST.',
      key: 'line_item_destination',
      dataIndex: 'line_item_destination',
      render: (record: string) => {
        return <Tooltip title={record}>{record}</Tooltip>;
      },
    },
    ...providerColumns,
  ];

  return columns;
};

const AssignmentList = ({
  currencies,
  isAssignmentFromAdminPossible,
  isAssignmentPossible,
  isLumpSum,
  multipleSelection,
  quotations,
  referenceCode,
  refetch,
  resetSelectedItemsToDefault,
  setIsAssignmentPossible,
  setIsLumpSum,
  setQuotations,
  status,
}: IAssignmentListProps) => {
  const { freightRepository } = useRepository();
  const { id: uuid } = useParams<{ id: string }>();
  const facilityId = +localStorage.getItem('facility')!;

  const [lineItemInfoSelected, setLineItemInfoSelected] = useState<ILineItemInfoModal | null>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);

  const [focusedItem, secFocusedItem] = useState(0);
  const [sorting, setSorting] = useState<null | string>(null);
  const [sortedLine, setSortedLine] = useState<null | number>(null);

  const [isAmendAssignmentActive, setIsAmendAssignmentActive] = useState(false);
  const [isAmendModalShow, setIsAmendModalShow] = useState(false);
  const [isAmendHistoryModalShow, setIsAmendHistoryModalShow] = useState(false);
  const [amendmentReason, setAmendmentReason] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<Array<File>>([]);
  const [uploading, setUploading] = useState(false);

  const { data: switches } = useQuery<Switches>(['switches'], () =>
    freightRepository.getSwitches(),
  );

  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { data: amendAssignments, isLoading } = useQuery<IAmendAssignmentsRequest>(
    ['amend-assignments', pageSize, selectedPage],
    () =>
      freightRepository.getAmendAssignments(facilityId, uuid || '', {
        limit: pageSize,
        offset: (selectedPage - 1) * 10,
      }),
  );

  const backButtonClick = (newIndex: number) => {
    if (containerRef.current !== null) {
      const childRef = containerRef.current.childNodes[newIndex] as HTMLDivElement;
      if (childRef) {
        childRef.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
        secFocusedItem(newIndex);
      }
    }
  };

  const tableData: any = getTableData({ quotations, isLumpSum, currencies });

  const tableColumns = getTableColumns({
    isAssignmentPossible,
    quotations,
    setLineItemInfoSelected,
    setQuotations,
    sortedLine,
    sorting,
    tableData,
  });

  const isOverflow = tableColumns.length > COLUMN_COUNT_FOR_SCROLLING;
  const isLastItemDisabled = focusedItem === tableColumns.length - TABLE_COLUMN_PER_PAGE;

  const clearNewAssignmentData = () => {
    setSelectedFiles([]);
    setAmendmentReason('');
  };

  const sendNewAssignment = useMutation(
    (payload: IPostNewAssignment) =>
      freightRepository.postNewAssignment(facilityId, uuid || '', payload),
    {
      onSuccess: () => {
        setIsAmendModalShow(false);
        clearNewAssignmentData();
        setUploading(false);
        message.success('Assignment sent successfully');
        refetch();
      },
      onError: (error) => {
        setUploading(false);
        message.error(String(error));
      },
    },
  );

  const confirmNewAssignment = () => {
    setUploading(true);

    let isValidFiles = true;
    selectedFiles.forEach(({ size }: any) => {
      if (!isValidFiles) {
        return;
      }
      if (!(size / 1024 / 1024 < 10)) {
        isValidFiles = false;
        message.error('File size must be less than 10MB');
        setUploading(false);
      }
    });

    const selectedQuotationUuids = quotations
      .filter(({ selected }) => selected)
      .map(({ uuid }) => uuid);

    if (isValidFiles) {
      const filePromises = selectedFiles.map((file: any) => {
        return convertFileToBase64(file.originFileObj);
      });

      Promise.all(filePromises).then((files) => {
        sendNewAssignment.mutate({
          reason: amendmentReason,
          files: files.map((file) => {
            return { file };
          }),
          line_item_quotation_uuids: selectedQuotationUuids,
        });
      });
    }
  };

  const isReasonEmpty = amendmentReason.length === 0;

  return (
    <div className="assignment-list-scroll-wrapper">
      <div className="assignment-list-wrapper last-row-bolded">
        <div className="qm-title">Assign Line items to Provider - Click to select/unselect</div>
        <div className="assignment-table-top">
          <div className="dropdown-sort">
            <Dropdown
              isSearchable={false}
              onChange={(option) => {
                setSortedLine(null);
                setSorting(option.value);
              }}
              options={sortOptions}
              placeholder="Sort quotation by"
              styles={statusDropdownCustomStyles}
              value={
                sorting
                  ? {
                      label: sorting,
                      value: sorting,
                    }
                  : null
              }
            />
            {sorting && (
              <Button
                className="reset-sorting-btn"
                onClick={() => setSorting(null)}
                text="Reset sorting"
              />
            )}
          </div>
          <Tip
            className="switcher-cost"
            isVisible={multipleSelection}
            text="Only cost/MT available as multiple suppliers are assigned to same line item"
          >
            <Switcher
              checked={isLumpSum}
              label="Show value as:"
              leftText="cost/MT"
              onChange={() => setIsLumpSum(!isLumpSum)}
              rightText="Lump sum"
              disabled={multipleSelection}
            />
          </Tip>
          {switches?.amend_assignment && (
            <div className="buttons">
              {!isAmendAssignmentActive && Boolean(amendAssignments?.count) && (
                <Button
                  className="button-history"
                  onClick={() => setIsAmendHistoryModalShow(true)}
                  text="See amendments history"
                />
              )}
              {status !== 'quotes_request_sent' && !isAssignmentFromAdminPossible && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {isAmendAssignmentActive ? (
                    <>
                      <Button
                        className="button-cancel"
                        variant="warning"
                        onClick={() => {
                          setIsAmendAssignmentActive(false);
                          setIsAssignmentPossible(false);
                          resetSelectedItemsToDefault();
                        }}
                        text="Cancel"
                      />
                      <Button
                        variant="submit"
                        onClick={() => {
                          setIsAmendModalShow(true);
                        }}
                        text="Confirm new assignment"
                      />
                    </>
                  ) : (
                    <Button
                      className="button-amend"
                      onClick={() => {
                        setIsAmendAssignmentActive(true);
                        setIsAssignmentPossible(true);
                      }}
                      text="Amend assignment"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className={`arrow-buttons-container ${!isOverflow && 'hidden'}`}>
          <Arrow
            onClick={() => backButtonClick(focusedItem + 1)}
            className={`scroll-button right ${isLastItemDisabled && 'disabled'}`}
          />
          <Arrow
            onClick={() => backButtonClick(focusedItem - 1)}
            className={`scroll-button left ${focusedItem === 0 && 'disabled'}`}
          />
        </div>

        {tableData.length > 0 ? (
          <div className="table-wrapper">
            <div className="line-counter">
              <div className="line-counter-header">LINE ITEM</div>
              {tableData.map(({ uuid }: any, index: number) => {
                const isActive = index === sortedLine;

                return (
                  <div className="line-counter-cell" key={uuid}>
                    {index < tableData.length - 1 ? (
                      <>
                        {tableData[index].order}
                        <Tooltip title={isActive ? 'Reset sorting' : 'Sort by lowest'}>
                          <Button
                            className={`sorting-button ${isActive ? 'active' : ''}`}
                            icon={<SortIcon />}
                            onClick={() => {
                              setSorting(null);
                              const value = isActive ? null : index;
                              setSortedLine(value);
                            }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <Table
              variant="dark"
              columns={tableColumns}
              data={tableData}
              rowKey="uuid"
              scroll={
                isOverflow
                  ? {
                      x:
                        tableColumns.length *
                        ((window.innerWidth * 0.75 - 180) / TABLE_COLUMN_PER_PAGE),
                    }
                  : undefined
              }
              onRow={(_, index) => {
                if (index === 0) {
                  return {
                    ref: containerRef,
                    onClick: () => null,
                  };
                }
                return {};
              }}
              locale={{
                emptyText: <EmptyBox />,
              }}
            />
          </div>
        ) : (
          <div className="empty-box">
            <EmptyIcon />
            No data
          </div>
        )}
      </div>

      <Modal
        footer={null}
        title={
          <div className="modal-title">
            <p>Confirm new assignment</p>
          </div>
        }
        width="33%"
        centered
        visible={Boolean(isAmendModalShow)}
        onCancel={() => setIsAmendModalShow(false)}
      >
        <Spin spinning={uploading}>
          <div className="modal-amendment-content">
            <p>
              An email notification will be sent to the newly selected providers and to the
              unselected too.
            </p>
            <p>
              For internal reference, insert the reason of amendment for the assignment of{' '}
              {referenceCode}
            </p>
            <div>
              <textarea
                value={amendmentReason}
                placeholder="Insert here..."
                className="textarea-amendment-reason"
                onChange={(e) => setAmendmentReason(e.target.value)}
              />
            </div>
            <span className="modal-note">Max 5 files / 10 mb</span>
            <Upload
              beforeUpload={() => false}
              showUploadList
              onChange={({ fileList }) => setSelectedFiles(fileList as any)}
              multiple
              fileList={selectedFiles as any}
            >
              <Button
                className="button-amendment-upload"
                icon={<UploadOutlined />}
                text="Upload any file (email, image, etc) justifying the amendment"
              />
            </Upload>
          </div>
          <div className="modal-buttons">
            <Button
              className="button-cancel"
              variant="warning"
              onClick={() => setIsAmendModalShow(false)}
              text="Cancel"
            />
            <Tooltip title={isReasonEmpty ? 'Reason is required' : ''}>
              <div>
                <Button
                  variant="submit"
                  onClick={() => {
                    setIsAssignmentPossible(false);
                    setIsAmendAssignmentActive(false);
                    confirmNewAssignment();
                  }}
                  text="Confirm and notify"
                  disabled={isReasonEmpty}
                />
              </div>
            </Tooltip>
          </div>
        </Spin>
      </Modal>

      <Modal
        footer={null}
        title={
          <div className="modal-title">
            <p>Amendment history</p>
          </div>
        }
        width="50%"
        centered
        visible={Boolean(isAmendHistoryModalShow)}
        onCancel={() => setIsAmendHistoryModalShow(false)}
      >
        <Spin spinning={isLoading}>
          <div className="modal-amendment-content">
            <p>See the history of all assignments amendments of FR12345</p>
          </div>
          <Table
            variant="dark"
            data={amendAssignments?.results || []}
            columns={amendmentHistoryColumns}
          />
          <Pagination
            className="pagination"
            selectedPage={selectedPage}
            showJumper
            showPageSize
            totalPages={amendAssignments?.count || 0}
            variant="dark"
            onPageChange={setSelectedPage}
            onPageSizeChange={setPageSize}
          />
        </Spin>
      </Modal>

      {lineItemInfoSelected && (
        <LineItemModal
          lineItemInfoSelected={lineItemInfoSelected}
          setLineItemInfoSelected={setLineItemInfoSelected}
          currencies={currencies}
        />
      )}
    </div>
  );
};

export default AssignmentList;
