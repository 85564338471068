import React, { useEffect, useState } from 'react';
import { Button, Date as DatePicker, Dropdown } from '@ui-modules';
import { message, Modal, Radio, Spin } from 'antd';
import { UseMutationResult, useQuery } from 'react-query';
import { includes, without } from 'lodash';
import moment from 'moment';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { IProviders, IProvidersResponse, IQuotationPayload } from '@common/interfaces';
import './styles.scss';
import { useRepository } from '@context';
import { IMultipleQuotationPayload } from '@pages/BatchDetailsPage';
import { CloseOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { IDropdownOption } from '@ui-modules/types';
import { Tip } from '@components';
import { Tooltip } from 'react-tooltip';

interface IQuotationModalProps {
  cityName?: string;
  deadline?: string | null;
  disabled?: boolean;
  type: 'batch' | 'booking';
  isModalOpen: boolean;
  mutation:
    | UseMutationResult<any, any, IQuotationPayload>
    | UseMutationResult<any, any, IMultipleQuotationPayload>;
  onButtonClick: (data: {
    deadline: Date;
    providers: IProviders[];
    transport_contract: string;
  }) => void;
  setIsModalOpen: (value: boolean) => void;
  title: string;
  resetOnClose?: boolean;
  persistedContract?: IDropdownOption;
}

const PROVIDERS_LIMIT = 100;

const QuotationModal = ({
  cityName,
  deadline,
  disabled = false,
  isModalOpen,
  mutation,
  type,
  onButtonClick,
  setIsModalOpen,
  title,
  resetOnClose,
  persistedContract,
}: IQuotationModalProps) => {
  const deadlineDate = moment(deadline);
  const [selectedContract, setSelectedContract] = useState<{
    label: string;
    value: string;
    bookings_count: number;
  } | null>(null);
  const [selectedProviders, setSelectedProviders] = useState<IProviders[]>([]);
  const [quotationDate, setQuotationDate] = useState<Date | undefined>(
    deadline ? deadlineDate.toDate() : undefined,
  );
  const { freightRepository } = useRepository();
  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage.getItem('country');
  const { id: uuid } = useParams<{ id: string }>();

  useEffect(() => {
    if (persistedContract) {
      setSelectedContract({ ...persistedContract, bookings_count: 0 });
    }
  }, [persistedContract]);

  useEffect(() => {
    if (!isModalOpen && resetOnClose) {
      setSelectedProviders([]);
      setSelectedContract(null);
      setQuotationDate(undefined);
    }
  }, [isModalOpen, resetOnClose]);

  const { data } = useQuery<IProvidersResponse>(
    `contracts-${facilityId}`,
    () =>
      freightRepository.getProvidersList({
        offset: 0,
        limit: PROVIDERS_LIMIT,
        facilities__country_name: country ?? '',
        transport_contracts__uuid: selectedContract?.value ?? undefined,
        batch_uuid: type === 'batch' ? uuid || '' : undefined,
        booking_uuid: type === 'booking' ? uuid || '' : undefined,
        facility_id_on_hbh: facilityId.toString() || '',
      }),
    {
      retry: 2,
      enabled: isModalOpen,
      onSuccess: (providersResponseData: IProvidersResponse) => {
        if (
          providersResponseData?.options?.transport_contracts.length === 1 &&
          !persistedContract
        ) {
          setSelectedContract(providersResponseData?.options?.transport_contracts[0]);
        }
      },
      onError: () => {
        setIsModalOpen(false);
        return message.error('Fetching list of contracts has been failed');
      },
    },
  );

  const onProviderClick = (name: IProviders) => {
    if (!includes(selectedProviders, name)) {
      setSelectedProviders([...selectedProviders, name]);
    } else {
      setSelectedProviders(without(selectedProviders, name));
    }
  };

  const providersList = data?.results;

  const { isLoading } = mutation;

  const isSameDate = moment(quotationDate).isSame(deadlineDate, 'day');
  const validateDate = (date: Date) => {
    return moment(date).isAfter(deadlineDate);
  };
  const [dateError, setDateError] = useState<null | string>(null);

  const isContractDropdownDisabled =
    Boolean(data?.options?.transport_contracts.length === 1) || Boolean(persistedContract);

  // @ts-ignore
  return (
    <Modal
      footer={null}
      destroyOnClose
      title={
        <div className="modal-title">
          <p>{title}</p>
        </div>
      }
      closeIcon={
        <Tip text="Please wait. Emails are being sent." isVisible={isLoading}>
          <CloseOutlined />
        </Tip>
      }
      width="60%"
      visible={isModalOpen}
      onCancel={() => !isLoading && !disabled && setIsModalOpen(false)}
    >
      <p className="contract-select-label">Contract</p>
      <Tip
        isVisible={isContractDropdownDisabled}
        text="To change contract, please edit the Freight request"
      >
        <Dropdown
          value={selectedContract}
          placeholder="Select..."
          isDisabled={isContractDropdownDisabled}
          options={data?.options?.transport_contracts}
          // @ts-ignore
          onChange={(value: { label: string; value: string; bookings_count: number }) => {
            setSelectedProviders([]);
            setSelectedContract(value);
          }}
        />
      </Tip>
      {selectedContract ? (
        <>
          {type === 'batch' && selectedContract.bookings_count ? (
            <p className="batch-contract-label">
              Freights related to selected contract: {selectedContract.bookings_count}
            </p>
          ) : null}
          <div className="quotation-select-label">
            <p>{providersList?.length ?? 0} Available providers - clicks to select/unselect</p>
            <p>{selectedProviders?.length} selected</p>
          </div>
          <Radio
            onClick={() =>
              setSelectedProviders(
                providersList?.length !== selectedProviders?.length ? providersList ?? [] : [],
              )
            }
            checked={providersList?.length === selectedProviders?.length}
            className="select-all-radio"
            disabled={disabled}
          >
            {providersList?.length !== selectedProviders?.length ? 'Select all' : 'Deselect all'}
          </Radio>
          <Spin spinning={isLoading || disabled}>
            <div className="providers-container">
              {data?.results?.map((item) => {
                return (
                  <button
                    type="button"
                    key={item.legal_name}
                    onClick={() => onProviderClick(item)}
                    className={`provider-item ${includes(selectedProviders, item) && 'selected'}`}
                  >
                    <span>{item.legal_name}</span>
                  </button>
                );
              })}
            </div>
          </Spin>
        </>
      ) : (
        <p className="quotation-modal-note">Select a contract to view the related providers</p>
      )}
      <div className="quotation-submit-section">
        <div className="datepicker-container">
          <span className="quotation-datepicker-label">Quotation submission deadline</span>
          <DatePicker
            placeholderText="DD/MM/YYYY HH:mm"
            dateValue={quotationDate}
            dateFormat="dd/MM/yyyy HH:mm"
            disabled={disabled}
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            minDate={deadlineDate.toDate()}
            minTime={
              isSameDate
                ? setHours(
                    setMinutes(deadlineDate.toDate(), deadlineDate.minutes()),
                    deadlineDate.hours(),
                  )
                : undefined
            }
            maxTime={isSameDate ? setHours(setMinutes(deadlineDate.toDate(), 59), 23) : undefined}
            timeInputLabel="Time:"
            onChange={(value: Date) => {
              setQuotationDate(value);
              setDateError(null);
            }}
          />
          The time refers to {cityName} local time
        </div>
        {dateError && <div className="quotation-date-error">{dateError}</div>}
        <Tip text="Please wait. Emails are being sent." isVisible={isLoading}>
          <div>
            <Button
              text={isLoading ? 'Processing' : 'Confirm'}
              className="quotation-button send"
              disabled={isLoading || selectedProviders.length <= 0}
              type="submit"
              onClick={() => {
                const isValid = validateDate(quotationDate as Date);
                if (isValid) {
                  onButtonClick({
                    deadline: quotationDate as Date,
                    providers: selectedProviders,
                    transport_contract: selectedContract?.value || '',
                  });
                } else {
                  setDateError('Select date and time in the future');
                }
              }}
            />
          </div>
        </Tip>
      </div>
      <Tooltip id="global-tooltip" />
    </Modal>
  );
};

export default QuotationModal;
