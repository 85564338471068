/* eslint-disable @typescript-eslint/naming-convention */
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { message, Modal, Spin, Upload } from 'antd';
import moment from 'moment';
import { Table, Button, DetailsContainer } from '@ui-modules';
import { IDropdownOption } from '@unbooking/ui-modules/lib/types';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { UseMutationResult } from 'react-query';
import { UploadOutlined } from '@ant-design/icons';
import EmptyBox from '@components/EmptyBox';
import { DEFAULT_DATE_FORMAT } from '@common/constants';

import { useRepository } from '@context';
import { IDestination, ILineItem, IRecipient } from '@common/interfaces';
import { ReactComponent as FileNewIcon } from '@assets/svg/file-new.svg';
import { CloseRoundIcon } from '@ui-modules/assets/svg';
import { convertFileToBase64 } from '@common/utils';
import { DocIcon } from '@assets/svg';
import { columnsConfig } from '../../detailsConfig';
import { getDateComponent, getInputComponent, getLocationComponent } from '../../utils';

import './styles.scss';

interface CurrentLineItem extends Partial<ILineItem> {
  isDraft?: boolean;
  order?: number;
}

export interface Point {
  destination: IDestination;
  id: string;
  lineItems: Array<CurrentLineItem>;
  recipient: IRecipient;
}

interface IMappedLineItems {
  items: ILineItem[];
  volumeAggregator: number;
  weightAggregator: number;
}

interface IMappedLineItemsList extends Partial<Omit<ILineItem, 'weight' | 'volume'>> {
  id?: number;
  volume: string | number;
  weight: string | number;
}

interface IPointBlock {
  goodCategoriesOptions: Array<IDropdownOption>;
  isEditMode: boolean;
  loadingUnitsOptions: Array<IDropdownOption>;
  order: number;
  point: Point;
  setDeletingItems: Dispatch<SetStateAction<string[]>>;
  setPoints: Dispatch<SetStateAction<Array<Point>>>;
  stackableOptions: Array<IDropdownOption>;
  wvUnitsOptions: Array<IDropdownOption>;
  updateDestinationMutation: UseMutationResult<
    any,
    any,
    { uuid: string; payload: Partial<IDestination> }
  >;
}

const MAX_LINE_ITEMS_COUNT = 10;

const mapTableItemsToComponent = (items: ILineItem[]): Array<CurrentLineItem> => {
  if (!items.length) return [];

  let result: IMappedLineItemsList[] = [];

  items.reduce(
    (accumulator, currentValue: ILineItem, index: number) => {
      const currentVolume = currentValue.total_volume ? currentValue.total_volume : 0;
      const currentWeight = currentValue.total_weight ? currentValue.total_weight : 0;
      const volume: any = accumulator.volumeAggregator + currentVolume;
      const weight = accumulator.weightAggregator + currentWeight;

      if (index === items.length - 1) {
        result = [
          ...accumulator.items,
          { ...currentValue, id: index + 1 },
          {
            id: 0,
            uuid: '',
            volume: Number(volume.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 }),
            weight: Number(weight.toFixed(4)).toLocaleString('en-US', { minimumFractionDigits: 2 }),
          },
        ];
      }

      return {
        items: [...accumulator.items, { ...currentValue, id: index + 1 }],
        weightAggregator: weight,
        volumeAggregator: volume,
      };
    },
    { volumeAggregator: 0, weightAggregator: 0, items: [] } as IMappedLineItems,
  );

  return result as ILineItem[];
};

const DestinationIcon = ({ order }: { order: number }) => {
  const code = String.fromCharCode(order + 97);
  return <div className={`destination-icon dest-${code}`}>{code.toUpperCase()}</div>;
};

const PointBlock: FC<IPointBlock> = ({
  goodCategoriesOptions,
  isEditMode,
  loadingUnitsOptions,
  order,
  point,
  setDeletingItems,
  setPoints,
  stackableOptions,
  wvUnitsOptions,
  updateDestinationMutation,
}) => {
  const { freightRepository } = useRepository();

  const { lineItems, destination, recipient } = point;

  const [deletingItemApprove, setDeletingItemApprove] = useState<string | null>(null);
  const [destinationFileToDelete, setDestinationFileToDelete] = useState<IDestination | null>(null);
  const [isUpdatingDestinationFile, setIsUpdatingDestinationFile] = useState<boolean>(false);

  const deleteItem = (uuid: string, isDraft: boolean) => {
    if (isDraft) {
      setPoints((prevPoints) => {
        const newArray = [...prevPoints];
        const idx = newArray.findIndex(({ destination: dest }) => dest.uuid === destination.uuid);
        if (idx >= 0) {
          newArray[idx].lineItems = newArray[idx].lineItems.filter((item) => item.uuid !== uuid);
        }
        return newArray;
      });
    } else {
      setDeletingItemApprove(uuid);
    }
  };

  const updateDestinationFile = (uuid: string, payload: Partial<IDestination>) => {
    setIsUpdatingDestinationFile(true);
    updateDestinationMutation.mutate(
      {
        uuid,
        payload,
      },
      {
        onSettled: () => {
          setIsUpdatingDestinationFile(false);
        },
        onSuccess: () => {
          window.location.reload();
          setDestinationFileToDelete(null);
        },
        onError: () => {
          message.error('File update failed');
        },
      },
    );
  };

  const uploadDestinationFile = (file: UploadChangeParam<UploadFile<any>>) => {
    const fileSize = file.file.size as number;
    const fileName = file.file.name as string;
    const allowedExtension = ['xlsx', 'xls', 'xml', 'doc', 'docx', 'odt', 'rtf', 'pdf'];

    if (!new RegExp(`(${allowedExtension.join('|').replace(/\./g, '\\.')})$`).test(fileName)) {
      return message.error(
        `${fileName}: extension incorrect, accepted extensions: ${allowedExtension.join(', ')}`,
      );
    }

    const isLt10M = fileSize / 1024 / 1024 < 10;
    if (!isLt10M) {
      return message.error('File size must be less than 10MB');
    }

    convertFileToBase64(file.file as unknown as File).then((data) =>
      updateDestinationFile(destination.uuid, { transport_breakdown_file: data }),
    );

    return true;
  };

  const onDeleteClickHandler = () => {
    updateDestinationFile(destination.uuid, { transport_breakdown_file: null });
  };

  return (
    <div className="information-container">
      <Modal
        footer={null}
        title={
          <div className="modal-title">
            <p>Confirm file deletion</p>
          </div>
        }
        width="33%"
        centered
        visible={Boolean(destinationFileToDelete)}
        onCancel={() => setDestinationFileToDelete(null)}
      >
        <div className="deletion-modal">
          <div className="labels-wrapper">
            <p className="title">Do you want to remove the file related to:</p>
            <p className="destination-details">{destinationFileToDelete?.dropoff_location}</p>
            <p className="warning">It will not be possible to recover it</p>
          </div>
          {!isUpdatingDestinationFile ? (
            <div className="modal-buttons">
              <Button
                onClick={() => setDestinationFileToDelete(null)}
                variant="danger"
                text="Cancel"
              />
              <Button onClick={onDeleteClickHandler} variant="submit" text="Confirm" />
            </div>
          ) : (
            <Spin className="loader" spinning />
          )}
        </div>
      </Modal>
      {destination && (
        <div className="information-block">
          <div className="information-header">
            <h3>
              <DestinationIcon order={order} />
              Destination {order}
            </h3>
            {destination.transport_breakdown_file ? (
              <div className="transport-breakdown-wrapper">
                <CloseRoundIcon
                  onClick={() => setDestinationFileToDelete(destination)}
                  className="delete-icon"
                  style={{ display: isEditMode ? 'block' : 'none' }}
                />
                <Button
                  className={`breakdown_button dest-${String.fromCharCode(order + 97)}`}
                  icon={<DocIcon />}
                  onClick={() =>
                    window.open(destination.transport_breakdown_file as string, '_blank')
                  }
                  text="View Transport Breakdown"
                />
              </div>
            ) : (
              <Upload
                beforeUpload={() => false}
                showUploadList={false}
                disabled={isUpdatingDestinationFile}
                onChange={(file) => uploadDestinationFile(file as any)}
                multiple={false}
              >
                <div style={{ display: isEditMode ? 'block' : 'none' }}>
                  <Button
                    className="upload-breakdown_button"
                    icon={<UploadOutlined />}
                    disabled={isUpdatingDestinationFile}
                    text="Upload Destination Breakdown"
                  />
                </div>
              </Upload>
            )}
          </div>
          <DetailsContainer
            key="destination"
            items={[
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    DESTINATION LOCATION
                  </>
                ),
                content:
                  getLocationComponent(
                    destination.dropoff_location,
                    isEditMode,
                    `destination:${destination.uuid}:dropoff_location`,
                    freightRepository,
                  ) ?? '',
              },
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    DELIVERY COMPLETION DUE DATE
                  </>
                ),
                content: !isEditMode
                  ? moment(new Date(destination.dropoff_date)).format(DEFAULT_DATE_FORMAT) ?? '-'
                  : getDateComponent(
                      destination.dropoff_date,
                      true,
                      `destination:${destination.uuid}:dropoff_date`,
                    ) ?? '',
              },
            ]}
          />
        </div>
      )}
      {recipient && (
        <div className="information-block">
          <h3>
            <DestinationIcon order={order} />
            Destination {order} - Recipient information
          </h3>
          <DetailsContainer
            key="recipient"
            items={[
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    RECIPIENT FULLNAME
                  </>
                ),
                content: recipient.full_name,
              },
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    RECIPIENT ORGANISATION
                  </>
                ),
                content:
                  getInputComponent(
                    recipient.organisation,
                    isEditMode,
                    `recipient:${recipient.uuid}:organisation`,
                  ) ?? '',
              },
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    RECIPIENT EMAIL
                  </>
                ),
                content:
                  getInputComponent(
                    recipient.email,
                    isEditMode,
                    `recipient:${recipient.uuid}:email`,
                  ) ?? '',
              },
              {
                label: (
                  <>
                    <DestinationIcon order={order} />
                    RECIPIENT PHONE NUMBER
                  </>
                ),
                content:
                  getInputComponent(
                    recipient.phone_number,
                    isEditMode,
                    `recipient:${recipient.uuid}:phone_number`,
                  ) ?? '',
              },
            ]}
          />
        </div>
      )}
      <div className="information-block">
        <h3>
          <DestinationIcon order={order} />
          Destination {order} - Load information
        </h3>
        <div className="information-table">
          <Table
            variant="dark"
            rowKey="uuid"
            className="information-table"
            columns={
              columnsConfig(
                isEditMode,
                goodCategoriesOptions,
                loadingUnitsOptions,
                wvUnitsOptions,
                stackableOptions,
                deleteItem,
              ) as any
            }
            locale={{
              emptyText: <EmptyBox />,
            }}
            data={mapTableItemsToComponent(lineItems as any)}
          />
          {isEditMode && lineItems.length < MAX_LINE_ITEMS_COUNT ? (
            <Button
              text="Add line item"
              icon={<FileNewIcon />}
              className="add-button"
              onClick={() =>
                setPoints((prevPoints) => {
                  const newArray = [...prevPoints];
                  const idx = newArray.findIndex(
                    ({ destination: dest }) => dest.uuid === destination.uuid,
                  );
                  if (idx >= 0) {
                    newArray[idx].lineItems.push({
                      isDraft: true,
                      uuid: uuidv4(),
                      destination: destination.uuid as any,
                      recipient: recipient.uuid as any,
                    });
                  }
                  return newArray;
                })
              }
            />
          ) : null}
        </div>
        <Modal
          footer={null}
          title={<div className="modal-title">Delete line item</div>}
          width="33%"
          centered
          visible={Boolean(deletingItemApprove)}
          onCancel={() => setDeletingItemApprove(null)}
        >
          <div className="modal-content-text">
            <p className="deleting-modal-info">
              Are you sure you want to delete this line item?
              <br />
              It will not be possible to restore the data
            </p>
          </div>
          <div className="modal-buttons">
            <Button onClick={() => setDeletingItemApprove(null)} variant="danger" text="Cancel" />
            <Button
              onClick={() => {
                if (deletingItemApprove) {
                  setPoints((prevPoints) => {
                    const newArray = [...prevPoints];
                    const idx = newArray.findIndex(
                      ({ destination: dest }) => dest.uuid === destination.uuid,
                    );
                    if (idx >= 0) {
                      newArray[idx].lineItems = newArray[idx].lineItems.filter(
                        (item) => item.uuid !== deletingItemApprove,
                      );
                    }
                    return newArray;
                  });
                  setDeletingItems((prev) => [...prev, deletingItemApprove]);
                  setDeletingItemApprove(null);
                }
              }}
              variant="submit"
              text="Confirm"
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PointBlock;
